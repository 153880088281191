import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// json
import jsonData from "Assets/contents/contents.json";

// components
import { SectionNav, ColumnTable, Pager, Alert } from "Components";

// styles
import { Container } from "Styles/styles";

// logics
import CommentListLogic from "./CommentList.logic";

const CommentList = ({
  loading,
  setLoading,
  current,
  location,
  type,
  status,
  id,
}) => {
  const navigate = useNavigate();
  const { state, data, func } = CommentListLogic({
    loading,
    setLoading,
    navigate,
    url: { current, location, type, status, id },
  });

  return (
    <>
      <Container>
        <h2>댓글관리</h2>
        <SectionNav
          list={jsonData.nav[0].contents}
          status={location}
          isSearch={{
            state: true,
            active: (value) => func.setStateValue("search_keyword", value),
            onkeyPress: () => func.searchList(),
            placeholder: "작성자, 내용",
          }}
        />
        <section>
          <h4>총 {state.total}개</h4>
          <ColumnTable
            thead={
              jsonData.nav[0].contents.filter((con) => con.type === location)[0]
                .table_head
            }
            tbody={data.dataList.comment_list}
          />
          <Pager
            page={data.currentPage}
            count={10}
            total={state.total}
            paging={func.paging}
          />
        </section>
        {state.alert ? (
          <Alert
            title={state.alert.title}
            sub={state.alert.sub}
            isOk={state.alert.isOk}
            isCancel={state.alert.isCancel}
          />
        ) : null}
      </Container>
    </>
  );
};

export default CommentList;

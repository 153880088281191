import React from "react";
import styled from "styled-components";

const ColumnTable = ({
  data,
  data_type,
  active,
  check,
  check_all,
  active_data,
}) => {
  return (
    <>
      <List className={data_type === "edit" ? "edit" : ""}>
        <thead>
          <tr>
            {data.column.map((item, index) => {
              if (item.code === "checkbox") {
                return (
                  <td key={`head_${index}`} className={item.code}>
                    <button
                      className={check_all ? "checked checkbox" : "checkbox"}
                      onClick={check}
                    >
                      <i className={check_all ? "xi-check-min" : "hidden"} />
                    </button>
                  </td>
                );
              } else {
                return (
                  <td key={`head_${index}`} className={item.code}>
                    {item.title}
                  </td>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {data.list && data.list.length > 0 ? (
            <>
              {data.list.map((item, index) => {
                const itemKeys = Object.keys(item);
                return (
                  <tr
                    key={`list_${item.id}`}
                    onClick={() => active(item.id)}
                    className={active_data === item.id ? "selected" : ""}
                  >
                    {itemKeys.map((item_key, index) => {
                      if (item_key !== "id") {
                        return (
                          <td
                            item_key={`key_${index}`}
                            className={
                              item_key === "order_no"
                                ? "order_no"
                                : item_key === "button"
                                ? "padding_no"
                                : ""
                            }
                          >
                            {item[item_key]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </>
          ) : (
            <tr>
              <td colSpan={data.column.length}>{data.no_item}</td>
            </tr>
          )}
        </tbody>
      </List>
    </>
  );
};
const List = styled.table`
  font-size: 14px;
  line-height: 1.29;
  text-align: center;
  width: 100%;
  height: 100%;
  vertical-align: top;
  table-layout: fixed;
  td {
    border-bottom: solid 1px #ebebeb;
    padding: 10px 15px;
    white-space: normal;
    &.checkbox {
      width: 50px;
      .checkbox {
        border: solid 1px #ebebeb;
        border-radius: 3px;
        width: 17px;
        height: 17px;
        padding: 0;
        background-color: #fff;
        color: var(--main);
        &.checked {
          border: solid 1px var(--main);
        }
        i {
          font-size: 16px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  thead {
    color: #7f7f7f;
    background-color: #f7f7f7;
    tr {
      width: 100%;

      td {
        border-bottom: solid 2px #d1d1d1;
        vertical-align: middle;
        &.order_no {
          width: 125px;
        }
        &.min {
          width: 50px;
          padding: 10px 0;
        }
        &.mid {
          width: 200px;
          padding: 10px 0;
        }
        &.max {
          width: 300px;
          padding: 10px 0;
        }
        &.delete {
          width: 60px;
        }
        &.padding_no {
          padding: 0;
        }
      }
    }
  }
  tbody {
    width: 100%;
    tr {
      cursor: pointer;
      width: 100%;
      color: #000;
      vertical-align: middle;
      &.selected {
        border: solid 1px #dae7ff;
        background-color: #f4f8ff;
      }
    }
    td {
      color: #000;
      padding: 10px 12px;
      vertical-align: middle;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.order_no {
        width: 125px;
        padding: 10px 0 10px 5px;
      }
      &.padding_no {
        padding: 0;
      }
      .red {
        color: #e70000;
      }
      .gray {
        color: #9b9b9b;
      }
      .checkbox {
        border: solid 1px #ebebeb;
        border-radius: 3px;
        width: 17px;
        height: 17px;
        padding: 0;
        &.checked {
          border: solid 1px var(--main);
        }
        i {
          font-size: 16px;
          width: 100%;
          height: 100%;
        }
      }
      span {
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
      }
      p {
        display: inline-block;

        margin: 5px;
      }
      i {
        color: var(--main);
        font-size: 19px;
      }
      div.min_input {
        width: 80px;
        position: relative;
        display: inline-block;
        &::after {
          content: "km";
          display: block;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
`;

export default ColumnTable;

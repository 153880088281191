// 공통으로 사용되는 스타일 컴포넌트 모음 파일입니다.
import styled from "styled-components";
import Select, { components } from "react-select";
import SelectCheck from "Assets/images/icons/check_select.svg";
import ArrowSelect from "Assets/images/icons/arrow_select.svg";
// select styles
export const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "#2E2E2E",
    backgroundColor: state.isSelected ? "#F6F6F6" : "white",
    backgroundImage: state.isSelected ? `url(${SelectCheck})` : "",
    backgroundSize: "16px 16px",
    backgroundPosition: "95% 50%",
    backgroundRepeat: "no-repeat",
    height: "100%",
    // width: state.selectProps.width,
    fontSize: "14px",
    width: "100%",
    height: "44px",
    lineHeight: "27px",
    boxSizing: "border-box",
    overflowY: "auto",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#7f7f7f",
      opacity: "0.6",
      borderRadius: "10px",
      backgroundClip: "padding-box",
      border: "2px solid transparent",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f3f3f3",
      borderRadius: "10px",
      boxShadow: "inset 0px 0px 5px white",
    },
    "&:hover": {
      backgroundColor: state.isSelected ? "#F2F4F6" : "#F2F4F6",
      borderRadius: "6px",
    },
    "&:active": {
      backgroundColor: "#F6F6F6",
    },
    "&:nth-child(1)": {
      "&:hover": {
        backgroundColor: state.isSelected ? "#ffffff" : "#ffffff",
      },
    },
    cursor: "pointer",
  }),
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    padding: "8px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: 180,
  }),
  control: (provided, state) => ({
    ...provided,
    // none of react-select's styles are passed to <Control />
    // width: 117,
    border: state.isSelected ? `1px solid #E4E4E4` : `1px solid #E4E4E4`,
    borderColor: "#E4E4E4",
    boxShadow: "0 0 0 0px ",
    backgroundColor: "#ffffff",
    borderColor: state.isFocused ? "#F2F4F6" : "#F2F4F6",
    width: state.selectProps.width,
    // height: 34,
    height: state.selectProps.height,
    marginRight: 10,
    minHeight: 34,
    position: "relative",
    cursor: "pointer",
    borderRadius: "8px",
    "&:hover": {
      border: state.isSelected ? `1px solid #E4E4E4` : `1px solid #E4E4E4`,
    },
    "&:focus": {
      border: state.isSelected ? `1px solid #E4E4E4` : `1px solid #E4E4E4`,
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    // width:"5px",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    width: "30px",
    svg: {
      display: "none",
    },
  }),
  container: (provided, state) => ({
    ...provided,
    height: 34,
    display: "inline-block",
    cursor: "pointer",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 34,
    padding: state.isFocused ? "0 8px" : "0 8px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#BABABA",
  }),
  input: (provided, state) => ({
    ...provided,
    verticalAlign: "top",
    margin: 0,
    height: 34,
    padding: 0,
    "&:focus": {
      border: state.isFocused ? `1px solid #E4E4E4` : `1px solid #E4E4E4`,
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.data.color,
  }),
};
// select Dropdown
export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={ArrowSelect} />
    </components.DropdownIndicator>
  );
};

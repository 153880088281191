import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// components
import { Modal } from "Components";
// lib
import { setFormData } from "Utils/lib/helper";
//styles
import { Top } from "./SubNavStyle";
import { RowTableContainer } from "Components/Table/TableStyle";
// api
import { managerApi } from "Utils/api/api";
// json
import contents from "Assets/contents/contents.json";

//img
import Logo from "Assets/images/icons/logo_white.svg";

const SubNav = () => {
  const [state, setState] = useState({
    current: "",
    menu_open: false,
    modal: false,
    old_password: "",
    new_password: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setState({
      ...state,
      current: location.pathname,
      user_roll: localStorage.getItem("user_roll"),
    });
  }, [location.pathname]);
  useEffect(() => {
    if (state.modal) {
      setState({
        ...state,
        menu_open: false,
      });
    }
  }, [state.modal]);
  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };
  // 비밀번호 변경 모달 데이터
  const modalData = (
    <RowTableContainer>
      <tbody>
        <tr>
          <th>현재 비밀번호</th>
          <td>
            <input
              type="password"
              onChange={({ target: { value } }) =>
                setStateValue("old_password", value)
              }
              placeholder="현재비밀번호를 입력하세요."
            />
          </td>
        </tr>
        <tr>
          <th>새로운 비밀번호</th>
          <td>
            <input
              type="password"
              onChange={({ target: { value } }) =>
                setStateValue("new_password", value)
              }
              placeholder="새로운 비밀번호를 입력하세요."
            />
          </td>
        </tr>
      </tbody>
    </RowTableContainer>
  );
  // 비밀번호 변경
  const changPw = async () => {
    if (state.old_password === "") {
      alert("현재 비밀번호를 입력하세요.");
    }
    if (state.new_password === "") {
      alert("새로운 비밀번호를 입력하세요.");
    }
    try {
      const form = setFormData({
        old_password: state.old_password,
        new_password: state.new_password,
      });
      const {
        data: { message },
      } = await managerApi.changPw(form);
      alert(message + "\n새로운 비밀 번호로 다시 로그인 바랍니다.");
      localStorage.removeItem("user_token");
      window.location.href = "/";
    } catch ({ response: res }) {
      alert(res.data.message);
    } finally {
    }
  };
  return (
    <>
      <Top>
        <ul>
          <li>
            <img
              src={Logo}
              alt=""
              onClick={() => {
                navigate(
                  state.user_roll === "vehicle_manager" ||
                    state.user_roll === "consultant"
                    ? "/vehicle/car/list/lease/0"
                    : state.user_roll === "deliveryman"
                    ? "/subscribed/list/0"
                    : "/"
                );
              }}
            />
          </li>
        </ul>
        <div>
          <span
            className="people"
            onClick={() => setState({ ...state, menu_open: !state.menu_open })}
          ></span>
          <span
            className="bar"
            onClick={() => setState({ ...state, menu_open: !state.menu_open })}
          ></span>
          <div className={state.menu_open ? "" : "hidden"}>
            <ul>
              {/* <li onClick={()=>setStateValue("modal", !state.modal)}>비밀번호 변경</li> */}
              <li
                onClick={() => {
                  if (window.confirm("로그아웃하시겠습니까?")) {
                    localStorage.removeItem("user_token");
                    window.location.href = "/";
                  }
                }}
              >
                로그아웃
              </li>
            </ul>
          </div>
        </div>
      </Top>
      {state.modal ? (
        <Modal
          title={"비밀번호 변경"}
          setmodal={() => setStateValue("modal", !state.modal)}
          data={modalData}
          buttonTxt={"변경"}
          active={() => changPw()}
        />
      ) : null}
    </>
  );
};

export default SubNav;

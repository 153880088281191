import React, { useState, useEffect, useRef } from "react";

// utils
import { utilApi, contentApi } from "Utils/api/api";

import { scrollTop, setFormData } from "Utils/lib/helper";


// style

const ContentAddLogic = ({ loading, setLoading, navigate, url }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    loading: true,
    detail_info: [],
    grade_type: "1", // 1,2,3,5  : 1학년, 2학년, 3~4학년, 5~6학년
    layout_type: "", // full_layout, left_layout, right_layout
    modal: { open: false, type: "", id: "" },
    search_keyword: "",
    pixabay_search_keyword:"",
    related_search_keyword: "",
    related_first_category: null,
    related_second_category: null,
  });
  const tagRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  // 데이터 저장 state 관리
  const [inputData, setInputData] = useState({
    title: "",
    first_category: null,
    second_category: null,
    is_main: false,
    related_news: [],
    lead_comment: "",
    content_01: [], // 1학년 내용
    content_02: [], // 2학년 내용
    content_03: [], // 3,4학년 내용
    content_05: [], // 5학년 내용
  });
  const [filterList, setFilterList] = useState({
    first_category: [],
    second_category: [],
    image_list: [],
    pixabay_image_list: [],
    image_total: 0,
    news_list: [],
    news_total: 0,
  });

  useEffect(() => {
    getCategoryList();
  }, []);
  useEffect(() => {
    if (state.modal.open) {
      if (state.modal.type === "picture_select") {
        getImages();
      } else if (state.modal.type === "add_news") {
        getRelatedNews();
      }
    }
  }, [
    state.modal,
    state.search_keyword,
    state.related_search_keyword,
    state.related_first_category,
    state.related_second_category,
    currentPage,
  ]);

  ////////////////  API  ////////////////
  // 카테고리 조회
  const getCategoryList = async () => {
    try {
      const {
        data: { data: first },
      } = await utilApi.getNewsSupportList({
        type: "first",
      });
      const {
        data: { data: second },
      } = await utilApi.getNewsSupportList({
        type: "second",
      });
      const firstArr = first.first_category.map((i) => {
        return {
          value: i.id,
          label: i.category_name,
        };
      });
      const secondArr = second.second_category.map((i) => {
        return {
          value: i.id,
          label: i.category_name,
        };
      });
      setFilterList({
        ...filterList,
        first_category: firstArr,
        second_category: secondArr,
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
    } finally {
      setLoading(false);
    }
  };
  // 이미지풀 조회
  const getImages = async () => {
    try {
      const { data } = await contentApi.getImageList({
        search: state.search_keyword,
        num: 10,
        page: currentPage - 1,
      });
      setFilterList({
        ...filterList,
        image_list: data.data.image_list,
        image_total: data.data.total,
        alert: null,
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
    } finally {
      setLoading(false);
    }
  };
  // 연관 뉴스 조회
  const getRelatedNews = async () => {
    try {
      const { data } = await contentApi.getSupportsNewsList({
        first: state.related_first_category,
        second: state.related_second_category,
        search: state.related_search_keyword,
        page: currentPage - 1,
      });
      setFilterList({
        ...filterList,
        news_list: data.data.news,
        news_total: data.data.total,
      });

      console.log("data : ", data);

    } catch (error) {
      console.log(error);
      // alert(res.data.message);
    } finally {
      setLoading(false);
    }
  };
  // 관리자 뉴스등록
  const setData = async () => {
    let contentsArr = [];
    inputData.content_01.map((item) => contentsArr.push(item));
    inputData.content_02.map((item) => contentsArr.push(item));
    inputData.content_03.map((item) => contentsArr.push(item));
    inputData.content_05.map((item) => contentsArr.push(item));
    let relatedNewsArr = [];
    inputData.related_news.map((item) => relatedNewsArr.push(item.id));
    const json = {
      title: inputData.title,
      first_category_id: inputData.first_category,
      second_category_id: inputData.second_category,
      is_main: inputData.is_main,
      related_news: relatedNewsArr.toString(),
      lead_comment: inputData.lead_comment,
      contents: contentsArr,
    };
    if (inputData.title === "") {
      setStateValue("alert", {
        title: "제목을 입력해주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    if (!inputData.first_category) {
      setStateValue("alert", {
        title: "첫번째 카테고리를 선택해주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    if (!inputData.second_category) {
      setStateValue("alert", {
        title: "두번째 카테고리를 선택해주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    if (inputData.lead_comment === "") {
      setStateValue("alert", {
        title: "리드 댓글을 입력해주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    if (inputData.contentsArr === []) {
      setStateValue("alert", {
        title: "한 학년 이상의 본문을 등록하세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }

    try {
      const { data } = await contentApi.addAdminNews(json);
      setStateValue("alert", {
        title: "관리자 뉴스가 등록되었습니다.",
        sub: null,
        isOk: () => (window.location.href = "/content/news/list/admin/null"),
        isCancel: null,
      });
    } catch (error) {
      console.log(error);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };

  // 연관 뉴스 추가, 삭제
  const setRelatedNews = (type, value) => {
    if (type === "add") {
      if (
        inputData.related_news.filter((item) => item.id === value.id).length > 0
      ) {
        setState({
          ...state,
          modal: { open: false, type: "", id: "" },
          alert: {
            title: "이미 추가된 뉴스 입니다.",
            sub: null,
            isOk: () =>
              setState({
                ...state,
                alert: null,
                modal: { open: false, type: "", id: "" },
              }),
            isCancel: null,
          },
        });
        return;
      } else {
        inputData.related_news.push(value);
        setState({ ...state, modal: { open: false, type: "", id: "" } });
      }
    } else {
      setInputData({
        ...inputData,
        related_news: inputData.related_news.filter(
          (item) => item.id !== value.id
        ),
      });
    }
  };

  // 컨텐츠 이미지 추가, 삭제


  const setContentsDetail = async (type, idx, value, url) => {
    if (type === "image_add") {
      inputData[`content_0${state.grade_type}`][idx].image_id = value;
      inputData[`content_0${state.grade_type}`][idx].image_url = url;
      setState({ ...state, modal: { open: false, type: "", id: "" } });
    } else if (type === "image_delete") {
      inputData[`content_0${state.grade_type}`][idx].image_id = null;
      inputData[`content_0${state.grade_type}`][idx].image_url = null;
    } else {
      inputData[`content_0${state.grade_type}`][idx][type] = value;
    }
    
  };


  const setContentsDetailPixabay = async (type, idx, value, url) => {

    const form = setFormData({
      image: url,
      url : url,
      tags: "pixabay",
      type: "admin",
    });
  
    try {
      
      const { data } = await contentApi.addImagePixabay(form);

      inputData[`content_0${state.grade_type}`][idx].image_id = data.id;
      inputData[`content_0${state.grade_type}`][idx].image_url = url;
      setState({ ...state, modal: { open: false, type: "", id: "" } });
   
  
    } catch (error) {
      console.log(error);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }



    


    
  };

  // 컨텐츠 추가, 삭제
  const setContents = (type, value) => {
    if (type === "add") {
      inputData[`content_0${state.grade_type}`].push({
        grade: state.grade_type,
        content: "",
        image_id: null,
        image_url: null,
        image_size: "large",
        image_location: value,
        layout_number: inputData[`content_0${state.grade_type}`].length + 1,
      });
      setState({ ...state, modal: { open: false, type: "", id: "" } });
    } else {
      setInputData({
        ...inputData,
        [`content_0${state.grade_type}`]: inputData[
          `content_0${state.grade_type}`
        ].filter((item, idx) => idx !== value),
      });
    }
  };

  // keyword로 검색 결과 셋팅
  const searchList = () => {
    if (state.search_keyword === "" || state.search_keyword.length < 2) {
      alert("키워드는 두글자 이상으로 검색 바랍니다.");
      return;
    } else {
      setCurrentPage(1);
      if (state.modal.type === "picture_select") {
        getImages();
      } else {
        getRelatedNews();
      }
    }
  };


  // keyword로 검색 결과 셋팅
  const searchPixabay = () => {


    // console.log("state.pixabay_search_keyword", state.pixabay_search_keyword);

  const apiKey = '48200735-0c5c403a116ad7e554bccc8ab';
  const baseUrl = 'https://pixabay.com/api/';
  const query = state.pixabay_search_keyword; // 검색어
  const imageType = 'photo';
  const perPage = 200;

  const url = `${baseUrl}?key=${apiKey}&q=${encodeURIComponent(query)}&image_type=${imageType}&per_page=${perPage}`;

  console.log("Request URL:", url);

  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('API 요청에 실패했습니다.');
      }
      return response.json();
    })
    .then((data) => {

      // previewURL과 id를 추출하여 상태에 저장
      const processedData = data.hits.map((item) => ({
        id: item.id,
        previewURL: item.previewURL,
        imageURL: item.largeImageURL
      }));

      setFilterList({
        ...filterList,
        pixabay_image_list: processedData,
      });
      console.log('Processed Data:', processedData);


    })
    .catch((error) => {
      console.error('오류 발생:', error);
    });


    // image_list: data.data.image_list,
  };


  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };
  // state input 셋팅
  const setInputStateValue = (key, value) => {
    setInputData({ ...inputData, [key]: value });
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({
      ...state,
      modal: { open: open, type: type, id: id },
      search_keyword: "",
      related_search_keyword: "",
      related_first_category: null,
      related_second_category: null,
    });
    // type : info /cancel
  };
  // 페이징처리
  const paging = ({ target: { value } }) => {
    setCurrentPage(value);
  };
  return {
    state,
    data: { inputData, filterList, currentPage },
    func: {
      setStateValue,
      setInputStateValue,
      setModalData,
      setRelatedNews,
      setContents,
      searchList,
      searchPixabay,
      paging,
      setContentsDetail,
      setContentsDetailPixabay,
      setData,
    },
    ref: {},
  };
};

export default ContentAddLogic;

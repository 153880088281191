import React from "react";

// style
import { ColumTableContainer } from "./TableStyle";

const ColumnTable = ({ thead, tbody }) => {
  return (
    <ColumTableContainer>
      <thead>
        <tr>
          {thead.map((item, index) => (
            <td key={`thead_${index}`} className={item.class}>
              {item.title}
              {item.filter ? (
                <button>
                  <div>
                    <ul>
                      <li>오름차순</li>
                      <li>내림차순</li>
                    </ul>
                  </div>
                </button>
              ) : null}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {tbody === [] || tbody === null ? (
          <tr className="cursor">
            <td colSpan={thead.length}> 내용이 없습니다.</td>
          </tr>
        ) : (
          tbody
        )}
      </tbody>
    </ColumTableContainer>
  );
};

export default ColumnTable;

import React, { useState, useEffect } from "react";
import moment from "moment/moment";

// api
import { contentApi } from "Utils/api/api";

//lib
import { scrollTop } from "Utils/lib/helper";

const CommentListLogic = ({ loading, setLoading, modal, navigate, url }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    data_list: [],
    total: 0,
    search_keyword: "",
    modal: { open: false, type: "", id: "" },
    is_active: null,
    alert: null,
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getList();
  }, [
    setLoading,
    modal,
    url.location,
    url.status,
    state.is_active,
    currentPage,
  ]);

  useEffect(() => {
    if (state.search_keyword === "") {
      getList();
      scrollTop();
    }
  }, [state.search_keyword, currentPage]);
  // 데이터 list 관리
  const dataList = {
    comment_list:
      state.data_list.length > 0 ? (
        state.data_list.map((item, idx) => {
          return (
            <tr className="cursor" key={`${url.location}_${item.id}`}>
              <td>
                <p className="text_all">{item.comment}</p>
              </td>
              <td>{item.users ? item.users.name : "-"}</td>
              <td>
                <p>{moment(item.created_at).format("YYYY.MM.DD")} </p>
                <div className="btns">
                  {url.location === "request" || url.location === "success" ? (
                    <button
                      className="reject_button"
                      onClick={() => setActive("fail", item.id)}
                    >
                      반려
                    </button>
                  ) : null}
                  {url.location === "request" || url.location === "fail" ? (
                    <button
                      className="approve_button"
                      onClick={() => setActive("success", item.id)}
                    >
                      승인
                    </button>
                  ) : null}
                </div>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={3}>댓글이 없습니다.</td>
        </tr>
      ),
  };

  // 목록 조회
  const getList = async () => {
    try {
      const {
        data: { data },
      } = await contentApi.getCommentList({
        status: url.location,
        search: state.search_keyword,
        page: currentPage - 1,
      });
      setState({ ...state, data_list: data.comments, total: data.total });
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  // 상태 변경
  const setActive = async (value, id) => {
    try {
      const { data } = await contentApi.setCommentStatus({
        status: value,
        id: id,
      });
      getList();
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // keyword로 검색 결과 셋팅
  const searchList = () => {
    if (state.search_keyword === "" || state.search_keyword.length < 2) {
      alert("키워드는 두글자 이상으로 검색 바랍니다.");
      return;
    } else {
      getList();
    }
  };

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({ ...state, modal: { open: open, type: type, id: id } });
    // type : info /cancel
  };

  // 페이징처리
  const paging = ({ target: { value } }) => {
    setCurrentPage(value);
  };
  return {
    state,
    data: { dataList, currentPage },
    func: { setStateValue, setModalData, paging, searchList },
  };
};

export default CommentListLogic;

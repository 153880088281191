import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { Loading, SubNav, MainNav } from "./Components";

import { Login, Comment, Content } from "Views";

const MainApp = () => {
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;
  const [fakeLoading, setFakeLoading] = useState(false);
  const user = localStorage.getItem("user_token");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user) {
      if (
        window.location.pathname.includes("login") ||
        window.location.pathname.includes("signup")
      ) {
        localStorage.clear();
        window.location.href = window.location.pathname;
      }
    } else {
      if (
        window.location.pathname.includes("login") ||
        window.location.pathname.includes("signup")
      ) {
      } else {
        window.location.href = "/login";
      }
    }
  }, []);
  useEffect(() => {
    if (window.location.pathname == "/") {
      window.location.href = "/comment/request/list/0";
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (loading) {
      // 로딩이 0.5초 이상 걸릴때만 로딩화면 띄움
      setTimeout(() => {
        if (loadingRef.current) setFakeLoading(true);
      }, 500);
    } else setFakeLoading(false);
  }, [loading]);

  return (
    <BrowserRouter>
      <div className="view">
        {fakeLoading && <Loading loading={fakeLoading} />}
        {/* 로그인 시 */}
        {user ? (
          <div>
            <MainNav />
            <SubNav />
            <Routes>
              {/* comment : 댓글관리 
                location :  request (승인전) / success(승인완료) /fail(반려) 
                type : list 
                id : 상세 아이디 / 0 
                    type add일때 상세 아이디가 붙으면 편집
              */}
              <Route
                path="/comment/:location/:status/:id"
                element={<Comment loading={loading} setLoading={setLoading} />}
              />

              {/* Content : 콘텐츠 관리 
                location :  news (뉴스활동관리) / builder(뉴스빌더관리) / images(이미지관리) 
                type : list / detail 
                status : 
                  news (뉴스활동관리) : admin(관리자) / studentnews (학생뉴스) / studentletter 학생 뉴스레터(학생 뉴스레터)\
                  없으면 null
                id : 아이디 없으면  0 /상세 아이디
              */}
              <Route
                path="/content/:location/:type/:status/:id"
                element={<Content loading={loading} setLoading={setLoading} />}
              />
            </Routes>
          </div>
        ) : (
          <Routes>
            {/* login */}
            <Route
              path="/login"
              element={<Login loading={loading} setLoading={setLoading} />}
            />
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
};

export default MainApp;

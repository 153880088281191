import React, { useState, useEffect, useRef } from "react";

// api
import { contentApi } from "Utils/api/api";

const ContentDetailLogic = ({
  loading,
  setLoading,
  url,
  scrollRef,
  navigate,
}) => {
  // 기본 state 관리
  const [state, setState] = useState({
    loading: true,
    detail_info: [],
    modal: { open: false, type: "" },
    grade_type: null,
  });
  // 수정시 데이터 저장 state 관리
  const [inputData, setInputData] = useState({
    name: null,
    price: null,
  });
  const memoRef = useRef(null);

  useEffect(() => {
    getDetail();
  }, []);

  ////////////////  API  ////////////////
  // 상세 조회
  const getDetail = async () => {
    try {
      let urlName;
      let json;
      if (url.status === "letter") {
        urlName = contentApi.getNewsLetterDetail({
          id: url.id,
        });
      } else if (url.status === "news") {
        urlName = contentApi.getNewsDetail({
          id: url.id,
        });
      } else {
        urlName = contentApi.getAdminNewsDetail({
          id: url.id,
        });
      }
      const {
        data: { data },
      } = await urlName;
      console.log(url.status, data);
      if (url.status === "admin") {
        setState({
          ...state,
          detail_info: data,
          loading: false,
          grade_type: data.news.contents ? data.news.contents[0].grade : null,
        });
      } else {
        setState({ ...state, detail_info: data, loading: false });
      }
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
    }
  };

  // 뉴스 삭제
  const deleteNews = async () => {
    try {
      const { data } = await contentApi.deleteAdminNews({
        id: url.id,
      });
      setStateValue("alert", {
        title: "뉴스가 삭제되었습니다.",
        sub: null,
        isOk: () => navigate(-1),
        isCancel: null,
      });
    } catch (error) {
      console.log(error.response.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
    }
  };

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };
  // state input 셋팅
  const setInputStateValue = (key, value) => {
    setInputData({ ...inputData, [key]: value });
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({ ...state, modal: { open: open, type: type, id: id } });
  };

  return {
    state,
    data: {},
    ref: { memoRef, scrollRef },
    func: { setStateValue, setModalData, setInputStateValue, deleteNews },
  };
};

export default ContentDetailLogic;

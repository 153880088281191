import styled from "styled-components";

export default styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 800px;
  position: relative;
  background-color: var(--grey5);
  /* &::after {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 250px;
  } */
  .form {
    position: relative;
    top: -50px;
    width: 350px;
    padding: 24px;
    text-align: left;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    h1 {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 37px;
      line-height: 140%;
      letter-spacing: -0.04em;
      span {
        display: block;
        margin-bottom: 10px;
        font-size: 17px;
        font-weight: 300;
      }
    }
    img {
      width: 120px;
      height: 20px;
      object-fit: contain;
      display: block;
      margin-bottom: 20px;
      /* position: absolute;
         top:0;
         left:-10px; */
    }
    h3 {
      margin-bottom: 6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      letter-spacing: -0.04em;
    }
    h6 {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: var(--grey2);
      margin-top: 6px;
    }
    input[type="text"] {
      background-color: var(--grey5);
      border: 1px solid var(--grey4);
    }
    .error_tag {
      color: var(--red);
      font-size: 12px;
      line-height: 140%;
      margin-top: 6px;
    }
    .safe_tag {
      color: var(--text);
      font-size: 12px;
      line-height: 140%;
      margin-top: 6px;
    }
    input {
      padding-top: 3px;
      width: 100%;
      height: 36px;
      font-size: 14px;
      border: 2px solid #f5f5f5;
      transition: border 0.1s ease-in-out, background-color 0.1s ease-in-out;
      margin-bottom: 14px;
      padding: 7px 12px;
      border-radius: 4px;
      border: 1px solid var(--grey4);
      margin-bottom: 22px;
      &::placeholder {
        font-size: 14px;
        color: var(--grey3);
      }
      &:last-of-type {
        margin-bottom: 0px;
      }
      &:focus {
        border: 2px solid var(--text);
        background-color: #fff;
      }
    }
    .password {
      margin-bottom: 0;
    }
    div {
      text-align: left;
      margin-bottom: 22px;
    }
    button {
      margin-top: 37px;
      border-radius: 4px;
      width: 100%;
      height: 40px;
      color: #ffffff;
      font-size: 15px;
      font-weight: 500;
      background-color: var(--main);
      transition: background-color 0.1s ease-in-out,
        border-radius 0.1s ease-in-out;
    }
  }
`;

// 공통으로 사용되는 스타일 컴포넌트 모음 파일입니다.
import styled from "styled-components";
import { Select } from "react-select";

//img
import Rightarrow from "Assets/images/icons/rightarrow.svg";
import Calendar from "Assets/images/icons/calendar.svg";
import Calendar2 from "Assets/images/icons/calendar2.svg";
import Rightbar from "Assets/images/icons/rightbar.svg";

// Container
export const Container = styled.div`
  position: relative;
  margin-left: 220px;
  top: 38px;
  background-color: #f7f7f7;
  min-height: calc(100vh - 38px);
  /* height: 100vh; */
  /* min-width: 1205px; */
  width: 100%;
  max-width: calc(100% - 220px);
  min-width: 1000px;
  padding: 40px 50px;

  /* overflow-x: scroll; */
  .selectimg {
    width: 15px !important;
    height: 15px !important;
  }
  // 버튼 가운데 정렬
  .center {
    display: block;
    margin: 0 auto;
  }

  // 파일 선택 input
  .file_select {
    position: relative;
    img {
      width: 114px;
      height: 114px;
      border-radius: 5px;
      background-color: #dfdfdf;
      display: block;
      overflow: hidden;
    }
    button {
      background-color: var(--grey1);
      text-align: center;
      color: var(--white);
      font-size: 14px;
      width: 90px;
      vertical-align: middle;
      margin-top: 6px;
      border-radius: 4px;
      height: 28px;
      position: absolute;
      right: 4px;
      top: 6px;
      display: block;
      line-height: 28px;
      box-sizing: border-box;
    }
    label {
      margin-right: 0;
      -webkit-letter-spacing: -0.05em;
      -moz-letter-spacing: -0.05em;
      -ms-letter-spacing: -0.05em;
      letter-spacing: -0.05em;
      display: block;
      width: 100%;
      height: 100%;
      line-height: 28px;
    }
    .hidden {
      display: none;
    }
    .file_select_box {
      display: inline-block;
      width: 100%;
      border: 1px solid var(--grey4);
      border-radius: 0px 5px 5px 0px;
      vertical-align: middle;
      color: var(--grey);
      font-size: 12px;
      padding-left: 10px;
      letter-spacing: -0.05em;
      line-height: 36px;
      border-radius: 4px;
      height: 36px;
    }
  }

  input {
    height: 36px;
    width: 100%;
    border: 1px solid var(--grey4);
    padding-left: 12px;
    border-radius: 4px;
    font-size: 14px;
    ::placeholder {
      color: var(--grey3);
    }
  }
  // 체크박스
  input[type="checkbox"] {
    display: inline-block;
    width: 100px;
    opacity: 1;
    margin-right: 8px;
    accent-color: var(--grey1);
    position: static;
    vertical-align: middle;
  }
  input[type="checkbox" i] {
    width: 20px;
    height: 20px;
    margin-top: 0;
  }
  input[type="checkbox"]:checked {
    background-color: var(--grey1);
    color: var(--white);
  }
  label {
    margin-right: 20px;
    cursor: pointer;
    vertical-align: middle;
  }
  h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
    line-height: 140%;
  }
  h3 {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 16px;
    text-align: left;
  }
  &.selected {
    height: 100%;
    min-height: calc(100vh - 52px);
  }
  ul.section {
    li {
      width: 100%;
      display: inline-block;
      margin-bottom: 50px;
      h3 {
        padding: 10px 0;
        i {
          margin-left: 20px;
          cursor: pointer;
        }
      }
      &.half {
        width: calc(50% - 20px);
        margin-right: 10px;
      }
    }
  }
  .buttons {
    position: absolute;
    top: 40px;
    right: 50px;
    button {
      padding: 5.5px 10px !important;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .nav_box {
    position: relative;
  }
  section {
    padding: 20px;
    background-color: var(--white);
    border: 1px solid var(--grey4);
    text-align: center;
    border-radius: 4px;
    margin-bottom: 43px;
    h4 {
      display: block;
      text-align: left;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 12px;
    }

    // ContentList 컨텐츠 관리
    ul {
      display: grid;
      gap: 30px 10px;
      min-height: 176px;
      grid-template-columns: repeat(auto-fill, calc(25% - 7.5px));
      margin-bottom: 4px;
      li {
        width: 100%;
        height: 176px;
        background-color: var(--grey);
        text-align: left;
        margin-bottom: 30px;
        cursor: pointer;
        position: relative;
        img {
          width: 100%;
          height: 120px;
          margin-bottom: 10px;
          object-fit: cover;
        }
        span {
          &.number {
            background: rgba(0, 0, 0, 0.7);
            border-radius: 0px;
            padding: 1.5px 6px;
            color: var(--white);
            font-size: 12px;
            line-height: 140%;
            position: absolute;
            top: 0;
            left: 0;
          }
          &.admin_tag {
            background: rgba(0, 0, 0, 0.7);
            border-radius: 0px;
            padding: 2px 8px;
            color: var(--white);
            font-size: 12px;
            line-height: 140%;
            position: absolute;
            top: 0;
            right: 1px;
          }
        }
        .tagbox {
          span {
            padding: 2px 8px;
            background: var(--grey5);
            border-radius: 4px;
            display: inline-block;
            margin-right: 4px;
            margin-bottom: 4px;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: var(--grey1);
          }
        }
      }
    }
  }
  // table 안에 select
  table {
    tr {
      td {
        .inline_select {
          display: inline-block;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  // 캘린더
  input[type="date"] {
    width: 100%;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    ::-webkit-datetime-edit-fields-wrapper {
      padding-left: 28px;
      position: relative;
    }
    // placeholder
    ::before {
      /* content: attr(data-placeholder); */
      content: attr(data-placeholder);
      width: 100%;
      color: var(--grey2);
      padding-left: 30px;
    }
    // rightbar
    ::after {
      background: url(${Rightbar});
      content: "";
      display: block;
      z-index: 9;
      position: absolute;
      right: 12px;
      width: 18px;
      height: 18px;
    }
    ::-webkit-calendar-picker-indicator {
      background: url(${Calendar2});
      background-repeat: no-repeat;
      position: absolute;
      left: 12px;
      content: "";
      width: 100%;
      height: 20px;
      margin-top: 4px;
      display: block;
      cursor: pointer;
    }
  }
  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    display: none;
  }

  .section_wrap {
    width: 100%;

    background-color: #f7f7f7;
    .section_left {
      width: 70%;
      display: inline-block;
    }
    .memo {
      margin-left: 1%;
      width: 29%;
      display: inline-block;
      min-width: 0;
      vertical-align: top;
      font-size: 14px;
      height: 766px;
      position: relative;
      margin-top: 0;
      h4 {
        text-align: left;
        display: block;
        margin-bottom: 10px;
        color: var(--text);
        font-weight: 700;
        font-weight: 14px;
      }
      ul {
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-y: auto;
        position: relative;
        height: 600px;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #7f7f7f;
          background-color: rgba(0, 0, 0, 0.3);
          opacity: 0.6;
          border-radius: 10px;
          background-clip: padding-box;
          border: 2px solid transparent;
        }
        &::-webkit-scrollbar-track {
          background-color: #f3f3f3;
          border-radius: 10px;
          box-shadow: inset 0px 0px 5px white;
        }
        li {
          display: block;
          background-color: var(--grey5);
          margin-bottom: 10px;
          text-align: left;
          padding: 14px;
          border-radius: 10px;
          width: 97%;
          h5 {
            line-height: 140%;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            color: var(--text);
          }
          p {
            color: var(--grey2);
          }
        }
      }
      > div {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        border-top: 1px solid var(--grey4);
        input[type="text"] {
          display: inline-block;
          vertical-align: middle;
          width: 80%;
          height: 36px;
          padding: 7px 12px;
          text-align: left;
          margin-right: 1%;
          border-radius: 4px;
        }
        button {
          margin-right: 0;
        }
      }
    }
  }

  // select box
  .css-1ozv3a7-indicatorSeparator {
    display: none;
  }

  .css-1u9uq39-container {
    margin-bottom: 0;
    position: relative !important;
    vertical-align: middle;
    span {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
    }
    div {
      margin-bottom: 0;
    }
  }
  .vehicle_select {
    margin-top: 10px;
  }
  // select placeholder
  .css-1xu18px-singleValue {
    color: #bababa;
  }
  /* .css-crdve1-singleValue {
    color: #bababa;
  } */

  .btns {
    /* margin-top: 30px; */
    text-align: center;
    button {
      width: auto;
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
    &.right_btns {
      text-align: right;
    }
  }
  // 오른쪽 버튼
  .btn {
    text-align: right;

    button {
    }
  }
`;

export const SearchBoxContainer = styled.div`
  width: ${({ width }) => (width ? width : "302px")};
  height: 36px;
  margin-bottom: 16px;
  box-sizing: border-box;
  font-size: 14px;
  color: var(--grey3);
  border-radius: 4px;
  position: relative;
  background-color: var(--white);
  display: inline-block;
  margin-right: 10px;
  input {
    width: 100%;
    height: 36px;
    border-radius: 4px;
    padding: 7px 12px;
    font-size: 14px;
    border: 1px solid var(--grey4);
    &::placeholder {
      color: var(--grey3) !important;
    }
    &:focus {
      border: 1px solid var(--text);
      color: var(--text);
      &::placeholder {
        color: var(--text) !important;
        font-size: 14px;
      }
      + i {
        color: var(--text) !important;
      }
    }
  }
  i {
    color: var(--grey3) !important;
    position: absolute;
    right: 12px;
    top: 9px;
    font-size: 17px;
    cursor: pointer;
  }
  &.top {
    top: 10px;
  }
`;

export const Section = styled.section`
  width: 100%;
`;
export const SearchBox = styled.div`
  width: 302px;
  font-size: 14px;
  color: #7f7f7f;
  display: inline-block;
  position: relative;
  background-color: var(--white);
  input {
    width: 100%;
    border-radius: 4px;
    padding-left: 12px;
    &:focus {
      border: 1px solid var(--text);
      color: var(--text);
      &::placeholder {
        color: var(--text) !important;
      }
      + i {
        color: var(--text) !important;
      }
    }
  }
  i {
    color: var(--grey3) !important;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 12px;
    top: 9px;
    cursor: pointer;
  }
  &.top {
    top: 10px;
  }
`;

export const CalendarBox = styled.div`
  display: inline-block;
  margin-right: 10px;
  input[type="date"] {
    width: 212px;
    position: relative;
    background-color: var(--white);
    ::-webkit-datetime-edit-fields-wrapper {
      padding-left: 28px;
      position: relative;
    }
    // placeholder
    ::before {
      content: attr(data-placeholder);
      padding-left: 30px;
      width: 100%;
      color: var(--grey3);
    }

    p {
      display: inline-block;
    }

    // Rightarrow
    ::after {
      background: url(${Rightarrow});
      content: "";
      display: block;
      z-index: 9;
      position: absolute;
      right: 12px;
      width: 20px;
      height: 20px;
      transform: rotate(90deg);
    }
    ::-webkit-calendar-picker-indicator {
      background: url(${Calendar});
      background-repeat: no-repeat;
      position: absolute;
      left: 12px;
      content: "";
      width: 100%;
      height: 20px;
      margin-top: 4px;
      display: block;
      cursor: pointer;
    }
  }
  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    display: none;
  }
`;

export const SelectBox = styled.div`
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  select {
    width: 212px;
    height: 36px;
    border-radius: 4px;
    background-color: var(--white);
    padding: 7px 12px;
    font-size: 14px;
    color: var(--grey3);
    cursor: pointer;
  }
`;

export const MinSection = styled.section`
  width: 100%;
  h3 {
    font-size: 19px;
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;
  }
  > div {
    padding: 30px;
    width: 100%;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
  }
`;

export const ColorBg = styled.div`
  margin-bottom: 40px;
  padding: 40px 60px 30px;
  background: #ecf0f9;
`;

export const TableTitle = styled.h3`
  display: flex;
  align-items: center;
  padding-top: 3px;
  padding-left: 19px;
  height: 55px;
  color: #ffffff;
  font-size: 21px;
  background-color: #4972c0;
`;

export const SearchButton = styled.button`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  padding: 9px 17px;
  border-radius: 4px;
  background-color: #32373c;
  transition: background-color 0.1s ease-in-out;
  &:hover {
    /* background-color: #27509e; */
  }
  &:active {
    background-color: #052e7c;
  }
  &.add {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    margin: 0 auto;
    width: 300px;
  }
`;

export const BasicButton = styled.button`
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  padding: 7px 17px 8px;
  color: var(--text);
  padding: 8px 10px;
  border-radius: 4px;
  transition: background-color 0.1s ease-in-out;
  line-height: 140%;
  box-sizing: border-box;
  letter-spacing: -0.04em;
  &.main {
    padding: 8px 33.5px;
    background-color: var(--main);
    color: var(--white);
  }
  &.main2 {
    padding: 8px 14px;
    background-color: var(--main);
    color: var(--white);
  }
  &.main3 {
    padding: 6px 12px;
    background-color: var(--main);
    color: var(--white);
  }
  &.main_right {
    padding: 8px 14px;
    background-color: var(--main);
    float: right;
    color: var(--white);
  }
  &.add_main {
    padding: 8px 14px;
    background-color: var(--main);
    color: var(--white);
    position: absolute;
    bottom: 16px;
    right: 0;
  }
  &.grey1 {
    padding: 8px 14px;
    background-color: var(--grey1);
    color: var(--white);
  }
  &.grey3 {
    padding: 6px 12px;
    background-color: var(--grey1);
    color: var(--white);
  }
  &.grey4 {
    width: 81px !important;
    height: 36px;
    background: var(--grey1);
    border-radius: 4px;
    color: var(--white);
  }
  &.white {
    padding: 8px 14px;
    border: 1px solid var(--grey1);
    color: var(--text);
    box-sizing: border-box;
  }
  &.white5 {
    padding: 4px 10px;
    border: 1px solid var(--grey1);
    color: var(--text);
    float: right;
  }
  &.black {
    font-size: 14px;
    padding: 7px 12px;
    background-color: var(--grey1);
    color: var(--white);
    vertical-align: middle;
  }
`;
export const WhiteButton = styled.button`
  padding: 7px 17px 8px;
  color: #7f7f7f;
  font-size: 12px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  transition: 0.1s ease-in-out;
  &:hover {
    color: #ffffff;
    background-color: #7f7f7f;
  }
  &:active {
    border-color: #32373c;
    background-color: #4a4a4a;
  }
  &.add {
    background-color: var(--main);
    color: #fff;
    &:hover {
      color: #ffffff;
      background-color: var(--main);
    }
    &:active {
      color: #ffffff;
      background-color: var(--main);
    }
  }
  &.block {
    background-color: #32373c;
    color: #fff;
    border: solid 1px #32373c;
    &:hover {
      color: #ffffff;
      background-color: #4a4a4a;
    }
    &:active {
      color: #ffffff;
      background-color: #32373c;
    }
  }
  &.gray {
    background-color: #f0f0f0;
    color: #4a4a4a;
    border: solid 1px #f0f0f0;

    &:hover {
      color: #ffffff;
      background-color: #4a4a4a;
    }
    &:active {
      color: #ffffff;
      background-color: #f0f0f0;
    }
  }
  &.red {
    background-color: #fdeded;
    color: #e70000;
    border: solid 1px #e70000;
    &:hover {
      color: #ffffff;
      background-color: #ff6763;
    }
    &:active {
      color: #ffffff;
      background-color: #e70000;
    }
  }
  &.blue {
    background-color: #e8f0ff;
    color: #1367ff;
    border: solid 1px #e8f0ff;
    &:hover {
      color: #e8f0ff;
      background-color: #1367ff;
    }
    &:active {
      color: #e8f0ff;
      background-color: #1367ff;
    }
  }
`;

export const RadioLabelBox = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  input {
    margin-right: 10px;
    position: relative;
    top: -2px;
  }
  label {
    margin-right: 40px;
  }
  &:last-child label {
    margin-right: 0;
  }
`;

export const DivInTd = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  strong {
    padding-top: 2px;
    max-width: 80%;
  }
`;

export const CenterSpan = styled.span`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ width }) => (width ? 20 : 0) + "px"};
  padding-top: ${({ width }) => (width ? 0 : 3) + "px"};
  width: ${({ width }) => (width ? width + "px" : "auto")};
  height: ${({ width }) => (width ? "45px" : "auto")};
  overflow: hidden;
  img {
    width: auto;
    height: 100%;
  }
`;

export const BasicInput = styled.input`
  padding: 0 10px;
  height: 35px;
  border: 1px solid #afafaf;
  background-color: #ffffff;
`;

/* modal */
export const ModalOkButton = styled.button`
  padding-top: 2px;
  width: 100px;
  height: 100%;
  color: #ffffff;
  border: 1px solid #9cb8ec;
  background-color: #4972c0;
  /* opacity: 0.85; */
  transition: opacity 0.1s ease-in-out;
  &:hover {
    /* opacity: 0.95; */
  }
  &:active {
    border-color: #ffffff;
    opacity: 1;
  }
`;

export const ModalCancelButton = styled.button`
  padding-top: 2px;
  margin-left: 10px;
  width: 100px;
  height: 100%;
  color: #ffffff;
  border: 1px solid #999999;
  background-color: #555555;
  /* opacity: 0.85; */
  transition: opacity 0.1s ease-in-out;
  &:hover {
    opacity: 0.95;
  }
  &:active {
    border-color: #ffffff;
    opacity: 1;
  }
`;
export const Detailbox = styled.div`
  width: 100%;
  text-align: left;
  table {
    text-align: left;
    font-size: 14px;
    border-spacing: 0;
    border-collapse: collapse;
    tr {
      td {
        padding: 12px 20px;
        &:nth-child(1) {
          width: 140px;
          vertical-align: top;
        }
        i {
          display: inline-block;
          vertical-align: text-top;
          margin-left: 15px;
        }
        .maintag {
          background-color: var(--main);
          color: var(--white);
          padding: 3px 7px;
          border-radius: 4px;
          i {
            color: var(--white);
            display: inline-block;
            vertical-align: middle;
            padding-left: 4px;
            margin-left: 0;
            &::before {
              vertical-align: text-bottom;
            }
          }
        }
        input[type="date"] {
          border: none;
          vertical-align: top;
          height: auto;
          border-bottom: 1px solid var(--grey3);
          padding-bottom: 3px;
          ::-webkit-datetime-edit-fields-wrapper {
            padding-left: 28px;
            position: relative;
          }
          // placeholder
          ::before {
            content: attr(data-placeholder);
            padding-left: 30px;
            width: 100%;
            color: var(--grey3);
          }
        }
      }
    }
  }
  .upload {
    width: 100% !important;
    tr {
      td {
        input[type="text"] {
          height: 36px;
          border: 1px solid var(--grey4);
          width: 100%;
          border-radius: 4px;
          padding: 7px 12px;
        }
      }
      &:nth-child(2) {
        height: 74px;
        textarea {
          height: auto;
          border: 1px solid var(--grey4);
          border-radius: 4px;
          width: 100%;
          padding: 7px 12px;
          overflow: visible;
          resize: both;
          ::placeholder {
            color: var(--grey3);
          }
        }
      }
    }
  }

  .btns {
    margin: 0 auto;
    display: block;
    text-align: center;
    &.right_btns {
      text-align: right;
    }
  }
  > div {
    display: block;
    text-align: left;
    margin-bottom: 16px;
    img {
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
    }
    p {
      display: inline-block;
      margin-right: 10px;
      color: var(--grey2);
      vertical-align: middle;
      font-size: 16px;
      font-weight: 700;
    }
    h5 {
      font-size: 16px;
      font-weight: 700;
      color: var(--main);
      display: inline-block;
      vertical-align: middle;
    }
    .text {
      margin-bottom: 14px;
    }
  }
  select {
    width: 176px;
    height: 36px;
    font-size: 14px;
    margin: 14px 0;
    display: block;
    padding-left: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  .box {
    width: 255px;
    height: 94px;
    border: 1px solid var(--grey4);
    padding: 20px;
    border-radius: 6px;
    display: block;
    margin-right: 20px;
    background-color: var(--grey6);
    display: inline-block;
    h6 {
      margin-bottom: 10px;
      text-align: left;
      font-size: 14px;
      line-height: 140%;
      font-weight: 400;
    }
    p {
      display: block;
      text-align: left;
      font-size: 17px;
      line-height: 140%;
      span {
        color: var(--main);
        font-weight: 700;
      }
    }
  }
  ul {
    display: block;
    li {
      h4 {
        width: 140px;
        padding-top: 20px;
        padding-left: 20px;
        display: inline-block;
        vertical-align: top;
      }
    }
    .right {
      display: inline-block;
    }
  }
  .dashboard_date {
    display: block;
    input {
      display: inline-block;
    }
  }
`;

export const Box = styled.div`
  width: 840px;
  height: ${({ open }) => (open ? "100%" : "88px")};
  overflow: hidden;
  padding: 30px;
  margin: 0 auto;
  background-color: var(--white);
  border: 1px solid var(--grey4);
  color: var(--text);
  box-shadow: 0px 10px 40px rgba(186, 186, 186, 0.25);
  border-radius: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  position: relative;
  .title {
    margin-bottom: 20px;
    h3 {
      font-weight: 700;
      line-height: 22.4px;
      display: inline-block;
      vertical-align: middle;
    }
    img {
      width: 28px;
      height: 28px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    .down {
      width: 24px;
      height: 24px;
      float: right;
      transform: ${({ open }) => (open ? "rotate(0deg)" : " rotate(-180deg)")};
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  > ul {
    margin-bottom: 10px;
    width: 100%;
    > li {
      display: inline-block;
      margin-right: 30px;
      vertical-align: middle;
      font-size: 14px;
      width: 100%;
      h4 {
        width: 140px;
        height: 40px;
        display: inline-block;
        line-height: 40px;
        font-size: 14px;
        font-weight: 400;
        margin-right: 0 !important;
      }
      .right {
        display: inline-block;
        width: calc(100% - 140px);
        vertical-align: top;
        margin-bottom: 10px;
        padding: 10px 0;
        padding-left: 20px;
        position: relative;
        > ul {
          li {
            display: inline-block;
            &:nth-child(1) {
              margin-bottom: 5px;
            }
            span {
              /* width: 50px;
              height: 34px; */
              border: 1px solid var(--grey4);
              padding: 7px 12px;
              border-radius: 4px;
              /* margin: 0 10px; */
            }
          }
        }
        .input_in {
          width: 37px;
          height: 36px;
          position: absolute;
          right: 12px;
          line-height: 36px;
          color: var(--grey3);
        }
        .text {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: var(--text);
          margin: 8px 0;
        }
        table {
          background-color: var(--grey5);
          margin-top: 10px;
          width: 600px;
          border-spacing: 0;
          border-collapse: collapse;
          thead {
            background-color: var(--grey4);
            padding: 0;
            width: 100%;
            display: table-header-group;
          }
          tr {
            width: 100%;
            height: 29px;
            border-bottom: 1px solid var(--grey4);
            box-sizing: border-box;
            td {
              width: 290px;
              padding: 6px 10px;
            }
          }
        }
        .time {
          display: inline-block;
          margin-right: 20px;
          p {
            color: var(--text);
            margin-bottom: 8px;
          }
          select {
            width: 126px;
          }
        }
        .se {
          option {
            width: 100px;
            height: 30px;
            padding: 5px;
          }
        }
      }
    }

    select {
      width: 158px;
      padding: 7px 12px;
      font-size: 14px;
      cursor: pointer;
      margin-right: 8px;
      height: 36px;
      margin-bottom: 10px;
      margin-right: 10px;
      :required:invalid {
        color: var(--grey3);
      }
      option {
        width: 100px;
        height: 30px;
        :disabled {
          display: none;
        }
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #7f7f7f;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0.6;
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: #f3f3f3;
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
      }
    }

    //input
    textarea {
      width: 600px;
      height: 74px;
      border: 1px solid var(--grey4);
      padding-top: 7px;
      padding-left: 12px;

      &::placeholder {
        color: var(--grey3);
        font-size: 13px;
      }
    }
    input {
      display: inline-block;
      margin-right: 10px;
      position: relative;
    }
    input[type="radio"],
    input[type="radio"]:checked {
      appearance: none;
      cursor: pointer;
    }
    input[type="radio"] {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      background-color: var(--white);
      border: 1px solid var(--grey4);
      color: var(--grey4);
      margin-right: 8px;
      cursor: pointer;
      border-radius: 100%;
      &:checked {
        background-color: var(--white);
        border: 6px solid var(--grey1);
        color: var(--grey4);
      }
    }
    label {
      margin-right: 20px;
      cursor: pointer;
    }
    input[type="text"] {
      width: 302px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid var(--grey4);
      padding: 7px 12px;
      margin-bottom: 10px;
      ::placeholder {
        font-size: 13px;
        color: var(--grey3);
      }
      &.red {
        border: 1px solid var(--red);
      }
    }
    .long_input {
      width: 600px !important;
    }
    .short_input {
      width: 172px !important;
    }
    input[type="number"] {
      width: 217px;
      border: 1px solid var(--grey4);
      padding: 7px 12px;
      border-radius: 4px;
      margin-bottom: 10px;
      ::placeholder {
        color: var(--grey3);
        font-size: 14px;
      }
    }
    .longnumber {
      width: 302px !important;
    }
    p {
      font-size: 12px;
      color: var(--grey2);
      line-height: 16.8px;
      span {
        color: var(--red);
      }
    }
    .mt_text {
      margin-top: 10px;
    }
  }
  input[type="file"] {
    margin-bottom: 10px;
  }
  .photo {
    p {
      display: inline-block;
      margin-left: 0;
      padding-left: 0;
      margin-bottom: 10px !important;
    }
    .right {
      ul {
        li {
          vertical-align: top;
          display: inline-block;
          margin-right: 10px;
          position: relative;
          width: 125px;
          height: 100px;
          background-color: var(--grey5);
          border: 1px solid var(--grey4);
          cursor: pointer;
          img {
            width: 24px;
            height: 24px;
            display: block;
            margin: 0 auto;
            margin-top: 26.5px;
            margin-bottom: 6px;
          }
          p {
            text-align: center;
            display: block;
          }
          span {
            width: 100% !important;
            height: 100% !important;
            display: none;
            background: var(--black);
            opacity: 0.6;
            position: absolute;
            margin: 0 !important;
            top: 0;
            left: 0;
            padding: 0;
            border-radius: 0;
            border: none;
            cursor: pointer;
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              width: 44px;
              height: 44px;
              margin: 0;
            }
          }
          &:hover span {
            display: block;
          }
          &.add_image {
            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              margin: 0;
            }
          }
        }
      }
    }
    .manyrd {
      .right {
        padding: 0;
        padding-left: 20px;
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 140px);
        label {
          margin-right: 20px;
        }
        ul {
          li {
            height: 40px;
            line-height: 40px;
            margin-bottom: 0;
            display: inline-block;
          }
        }
      }
    }
  }
  .tag_err {
    color: var(--red) !important;
  }
  .option_more {
    background-color: var(--grey5);
  }
`;

// ContentDetail
export const Box2 = styled.div`
  width: 744px;
  margin: 0 auto;
  // 포함,불포함
  .include {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 700;
  }
  .include_text {
    font-size: 15px;
    margin-bottom: 30px;
    word-break: break-all;
  }
  /* margin-top: 98px; */
  padding-bottom: 30px;
  border-bottom: 1px solid var(--grey4);
  margin-bottom: 30px;
  &:nth-child(5) {
    border-bottom: none;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  &:last-child {
    border-bottom: none;
    margin-top: 0;
  }
  color: var(--text);
  > div {
    width: 744px;
  }
  .photo {
    display: inline-block;
    width: 50%;
    .mySwiper2 {
      width: 374px;
      height: 374px;
      margin-bottom: 12px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .mySwiper {
      width: 374px;
      .swiper-slide {
        width: 38px !important;
        height: 38px;
        opacity: 0.5;
        cursor: pointer;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .swiper-slide-thumb-active {
        opacity: 1;
      }
    }
    .swiper-button-prev {
      background-color: rgba(0, 0, 0, 0.5);
      width: 28px !important;
      height: 28px;
      border-radius: 100%;
      margin-top: 0;
      ::after {
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        position: absolute;
        left: 10px;
      }
    }
    .swiper-button-next {
      background-color: rgba(0, 0, 0, 0.5);
      width: 28px !important;
      height: 28px;
      border-radius: 100%;
      margin-top: 0;
      ::after {
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        position: absolute;
        right: 10px;
      }
    }
  }
  .price {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding-left: 20px;
    h3 {
      font-size: 22px;
      line-height: 140%;
    }
    p {
      font-size: 14px;
      color: var(--grey2);
      margin: 8px 0;
      line-height: 140%;
      img {
        width: 12.54px;
        height: 15px;
        vertical-align: middle;
        margin-right: 7.75px;
      }
    }
    .text2 {
      font-size: 12px;
      color: var(--grey3);
      margin: 0;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .profile {
      margin-bottom: 18px;
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        margin: 0;
      }
    }
    .reservaion {
      font-size: 12px !important;
      color: var(--grey3);
      margin-bottom: 19.5px;
      display: inline-block;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .text3 {
      font-weight: 700;
      margin-top: 18px;
    }
    .tag {
      margin-bottom: 0;
      padding: 0;
      background: none;
      margin-bottom: 11.5px;
      span {
        background-color: rgba(94, 139, 255, 0.1);
        border-radius: 4px;
        color: var(--main);
        margin-right: 6px;
        font-size: 12px;
        padding: 4px 10px;
        display: inline-block;
        line-height: 140%;
        margin-bottom: 7px;
      }
    }
    ul {
      margin-top: 18px;
      li {
        border-radius: 10px;
        background-color: var(--grey5);
        padding: 15.5px 20px;
        margin-bottom: 10px;
        word-break: break-all;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          width: 60%;
          vertical-align: middle;
          display: inline-block;
          font-size: 16px;
          color: var(--text);
          margin: 0;
          line-height: 140%;
        }
        span {
          font-weight: 700;
          width: 40%;
          display: inline-block;
          vertical-align: middle;
          text-align: right;
          line-height: 140%;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }
  .title_product {
    display: inline-block;
    ul {
      color: var(--grey3);
      font-size: 12px;
      margin-left: 20px;
      margin-top: 0;
      li {
        display: inline-block;
        margin-right: 6px;
        background-color: transparent;
        padding: 0;
        margin-right: 20px;
        position: relative;
        &:last-child {
          &::before {
            display: none;
          }
          &::after {
            display: none;
          }
        }
        &::before {
          content: "";
          width: 3px;
          height: 3px;
          border-radius: 100%;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: var(--grey3);
          left: -10px;
        }
        &::after {
          content: "";
          width: 3px;
          height: 3px;
          border-radius: 100%;
          position: absolute;
          right: -12px;
          top: 50%;
          transform: translateY(-50%);
          background-color: var(--grey3);
        }
        img {
          width: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px;
        }
      }
    }
  }
  .title {
    text-align: center;
    position: relative;
    p {
      color: var(--grey3);
      margin-bottom: 4px;
      line-height: 140%;
      span {
        color: var(--grey3);
        font-weight: 500;
        margin-right: 8px;
      }
      &:nth-child(1) {
        color: var(--grey2);
        line-height: 140%;
        margin-bottom: 8px;
      }
    }
    ul {
      color: var(--grey3);
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      li {
        display: inline-block;
        margin-right: 6px;
        margin-right: 20px;
        position: relative;
        &:last-child {
          &::after {
            display: none;
          }
        }
        &::before {
          display: none;
        }
        &::after {
          content: "";
          width: 3px;
          height: 3px;
          border-radius: 100%;
          position: absolute;
          right: -12px;
          top: 50%;
          transform: translateY(-50%);
          background-color: var(--grey3);
        }
        img {
          width: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px;
        }
      }
    }
    .tag {
      background-color: transparent;
      line-height: 140%;
      margin: 8px 0;
      span {
        display: inline-block;
        padding: 4px 10px;
        background: rgba(94, 139, 255, 0.1);
        color: var(--main);
        border-radius: 4px;
        font-size: 12px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    div {
      vertical-align: middle;
      font-size: 14px;
      margin-top: 8x;
      img {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
  }
  .locate {
    width: 100%;
    /* height: 82px; */
    margin: 0 auto;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid var(--grey4);
    margin: 14px 0;
    word-break: break-all;
    img {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      vertical-align: top;
    }
    span {
      display: inline-block;
      width: 90%;

      h6 {
        line-height: 140%;
        font-size: 14px;
        font-weight: 700;
      }
      p {
        line-height: 140%;
        font-size: 14px;
        color: var(--grey2);
      }
    }
    .trash {
      display: none;
      width: 42px;
      height: 42px;
      float: right;
      margin-right: 0;
      margin-top: 0px;
      cursor: pointer;
    }
    &:hover {
      outline: 4px solid var(--main);
      .trash {
        display: block;
      }
    }
  }
  .locate_none {
    &:hover {
      outline: none;
    }
  }
  .product {
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    padding: 14px 20px;
    border: 1px solid var(--grey4);
    margin-bottom: 14px;
    img {
      display: inline-block;
      width: 60px;
      height: 60px;
      vertical-align: middle;
      border-radius: 6px;
      margin-right: 14px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      h6 {
        line-height: 140%;
        font-size: 14px;
        font-weight: 700;
      }
      p {
        line-height: 140%;
        font-size: 14px;
        color: var(--grey2);
      }
    }
    .trash {
      display: none;
      width: 44px;
      height: 44px;
      float: right;
      margin-right: 0;
      margin-top: 8px;
      cursor: pointer;
    }
    &:hover {
      outline: 4px solid var(--main);
      .trash {
        display: block;
      }
    }
  }
  .product1 {
    &:hover {
      outline: none;
    }
  }
  .tablewrap {
    width: 100%;
    background: var(--grey5);
    padding: 14px 20px;
    border-radius: 10px;
    margin-bottom: 155px;
    h4 {
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 10px;
    }
    p {
      line-height: 140%;
      word-break: break-all;
      position: relative;
      padding-left: 10px;
      &::after {
        content: "";
        width: 3px;
        height: 3px;
        border-radius: 100%;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--text);
      }
    }
    table {
      margin: 0 auto;
      margin-top: 10px;
      width: 704px;
      border-spacing: 0;
      border-collapse: collapse;
      font-size: 12px;
      thead {
        background-color: var(--grey4);
        padding: 0;
        width: 100%;
        display: table-header-group;
        padding: 6px 10px;
      }
      tr {
        width: 100%;
        height: 20px;
        border-bottom: 1px solid var(--grey4);
        box-sizing: border-box;
        &:last-child {
          border-bottom: none;
        }
        td {
          width: 290px;
          padding: 8px 10px;
        }
      }
    }
  }
`;
export const List = styled.div`
  width: 744px;
  margin: 0 auto;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--grey4);
  margin-bottom: 30px;
  color: var(--text);
  table {
    width: 50%;
    display: inline-block;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
    tr {
      height: 40px;
      td {
        padding: 10px 20px;
        line-height: 140%;
        &:nth-child(1) {
          width: 160px;
          background-color: var(--grey5);
        }
      }
    }
  }
`;

export const Text = styled.div`
  width: 744px;
  margin: 0 auto;
  color: var(--text);
  h3 {
    font-size: 22px;
    font-weight: 700;
  }
  h4 {
    margin: 14px 0;
    font-size: 20px;
    font-weight: 700;
  }
  > div {
    margin-bottom: 14px;
    p {
      line-height: 160%;
      font-size: 15px;
    }
  }
  .picture {
    width: 744px;
    height: 354px;
    background-color: var(--grey5);
    margin-bottom: 14px;
  }
`;

// ContentList Table
export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  tr {
    border-bottom: 1px solid #e4e4e4;
  }
  thead {
    width: 100%;
    background: #f7f7f7;
    color: #7d7d7d;
    font-size: 14px;
    line-height: 140%;
    tr {
      width: 100%;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      td {
        padding: 10px 20px;
        &.min {
          width: 5%;
        }
        &.mid1 {
          width: 15.3%;
        }
        &.mid2 {
          width: 22%;
        }
        &.mid3 {
          width: 35%;
        }
        &.wide {
          width: 36%;
        }
      }
    }
  }

  tbody {
    text-align: left;
    width: 100%;
    tr {
      cursor: pointer;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      &.cursor_none {
        cursor: inherit;
      }
      td {
        padding: 10px 20px;
        span {
          display: inline-block;
          vertical-align: center;
          border-radius: 100px;
          font-size: 12px;
          line-height: 140%;
          letter-spacing: -0.04em;
          padding: 4px 10px;
          float: left;
          margin-right: 10px;
          &.maintag {
            color: var(--main);
            background: #f0ebff;
          }
          &.greytag {
            background: #f7f7f7;
            color: #2e2e2e;
          }
        }
        p {
          height: 20px;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: keep-all;
          margin-top: 3px;
          line-height: 140%;
          &.text_all {
            text-overflow: inherit;
            overflow: auto;
            display: block;
            height: auto;
          }
        }
        img {
          width: 14px;
          height: 14px;
          margin-top: 4px;
          transform: rotate(-90deg);
          float: right;
        }

        button {
          display: inline-block;
          padding: 5.5px 10px;
          border-radius: 4px;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          &.reject_button {
            border: 1px solid #6c38ff;
            border-radius: 4px;
            margin-right: 10px;
            color: var(--main);
          }
          &.approve_button {
            background-color: var(--main);
            color: var(--white);
          }
        }
      }
    }
  }
`;

// ContentAdd 뉴스레터 작성 컴포넌트
export const AdminNews = styled.div`
  padding-top: 88px;
  > div {
    width: 744px;
    background-color: var(--white);
    border: 1px solid #e4e4e4;
    box-shadow: 0px 14px 20px rgba(131, 142, 151, 0.15);
    border-radius: 12px;
    margin: 0 auto;
    padding: 27px;
    margin-bottom: 10px;
    ul {
      > li {
        display: inline-block;
        width: 34px;
        margin-bottom: 20px;
        vertical-align: top;
        &:nth-child(1) {
          width: 156px;
        }
        &:nth-child(2) {
          width: 532px;
        }
        input[type="text"] {
          background: #ffffff;
          border: 1px solid #e4e4e4;
          border-radius: 4px;
          width: 100%;
          padding: 7px 12px;
          box-sizing: border-box;
        }
        .select {
          width: 216px;
        }
      }
    }
    &:nth-child(2) {
      ul {
        li {
          .grade {
            span {
              border-radius: 100px;
              padding: 8px 12px;
              display: inline-block;
              background: #ffffff;
              border: 1px solid #e4e4e4;
              border-radius: 100px;
              margin-right: 6px;
              cursor: pointer;
              &.on {
                background: #6c38ff;
                color: var(--white);
              }
              p {
                display: inline-block;
                vertical-align: middle;
              }
              img {
                display: inline-block;
                vertical-align: middle;
                width: 20px;
                height: 20px;
              }
            }
          }
          button {
            background: #ffffff;
            box-shadow: 0px 8px 30px rgba(82, 92, 102, 0.2);
            border-radius: 100px;
            padding: 8px 10px 8px 12px;
            cursor: pointer;
            text-align: center;
            display: block;
            margin: 0 auto;
            &:hover {
              background: #f7f7f7;
              outline: 1px solid #e4e4e4;
              box-shadow: 0px 8px 30px rgba(82, 92, 102, 0.2);
              border-radius: 100px;
            }
            img {
              width: 16px;
              height: 16px;
              display: inline-block;
              vertical-align: middle;
              margin-right: 8px;
            }
            p {
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }

      // 레이아웃
      .full_layout {
        margin-left: 156px;
        .full_layout_add {
          width: 536px;
          height: 214px;
          background: #f7f7f7;
          display: block;
          cursor: pointer;
          position: relative;
          margin-bottom: 10px;
          > img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
          }
          > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 100%;
            height: 100%;
            &:hover {
              .picture_reselect_box {
                position: absolute;
                display: block;
              }
            }
            .newsbuilder_picture_icon {
              width: 20px;
              height: 20px;
              display: block;
              margin: 0 auto;
              margin-bottom: 6px;
            }
            .picture_select_box {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              .picture_icon {
                width: 20px;
                height: 20px;
                display: block;
                margin: 0 auto;
                margin-bottom: 6px;
              }
              p {
                text-align: center;
                color: #7d7d7d;
              }
            }
            .picture_reselect_box {
              position: absolute;
              top: 0;
              left: 0;
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              );
              display: none;
              width: 100%;
              height: 100%;
              div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                .newsbuilder_picture_icon {
                  width: 20px;
                  height: 20px;
                  display: block;
                  margin: 0 auto;
                  margin-bottom: 6px;
                }
                p {
                  color: var(--white);
                }
              }
            }
          }
          .imgbox_upload {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        textarea {
          border: 1px solid var(--grey4);
          padding: 7px 12px;
          display: block;
          width: 100%;
          border-radius: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          display: block;
          margin-bottom: 10px;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #7f7f7f;
            background-color: rgba(0, 0, 0, 0.3);
            opacity: 0.6;
            border-radius: 10px;
            background-clip: padding-box;
            border: 2px solid transparent;
          }
          &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
            border-radius: 10px;
            box-shadow: inset 0px 0px 5px white;
          }
        }
        button {
          background: #ffffff;
          box-shadow: 0px 8px 30px rgba(82, 92, 102, 0.2);
          border-radius: 100px;
          padding: 8px 10px 8px 12px;
          cursor: pointer;
          margin-right: 10px;
          text-align: center;
          margin: 0 auto;
          display: block;
          margin-bottom: 30px;
          display: inline-block;
          img {
            width: 16px;
            height: 16px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
          }
          p {
            display: inline-block;
            vertical-align: middle;
          }
          &:hover {
            background: #f7f7f7;
            outline: 1px solid #e4e4e4;
            box-shadow: 0px 8px 30px rgba(82, 92, 102, 0.2);
            border-radius: 100px;
          }
        }
      }
      .left_layout {
        margin-left: 156px;
        width: 536px;
        margin-bottom: 30px;
        .left_layout_add {
          width: 263px;
          height: 517px;
          background: #f7f7f7;
          cursor: pointer;
          position: relative;
          margin-bottom: 10px;
          margin-right: 10px;
          display: inline-block;
          vertical-align: top;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 100%;
            height: 100%;
            &:hover {
              .picture_reselect_box {
                position: absolute;
                display: block;
              }
            }
            .newsbuilder_picture_icon {
              width: 20px;
              height: 20px;
              display: block;
              margin: 0 auto;
              margin-bottom: 6px;
            }
            .picture_select_box {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              .picture_icon {
                width: 20px;
                height: 20px;
                display: block;
                margin: 0 auto;
                margin-bottom: 6px;
              }
              p {
                text-align: center;
                color: #7d7d7d;
              }
            }
            .picture_reselect_box {
              position: absolute;
              top: 0;
              left: 0;
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              );
              display: none;
              width: 100%;
              height: 100%;
              div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                .newsbuilder_picture_icon {
                  width: 20px;
                  height: 20px;
                  display: block;
                  margin: 0 auto;
                  margin-bottom: 6px;
                }
                p {
                  color: var(--white);
                }
              }
            }
            .imgbox_upload {
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
        textarea {
          width: 263px !important;
          height: 517px;
          display: inline-block;
          vertical-align: top;
          border: 1px solid var(--grey4);
          padding: 7px 12px;
          width: 100%;
          border-radius: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          margin-bottom: 10px;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #7f7f7f;
            background-color: rgba(0, 0, 0, 0.3);
            opacity: 0.6;
            border-radius: 10px;
            background-clip: padding-box;
            border: 2px solid transparent;
          }
          &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
            border-radius: 10px;
            box-shadow: inset 0px 0px 5px white;
          }
        }
      }

      .right_layout {
        margin-left: 156px;
        width: 536px;
        textarea {
          width: 263px !important;
          height: 517px;
          display: inline-block;
          vertical-align: top;
          border: 1px solid var(--grey4);
          padding: 7px 12px;
          width: 100%;
          border-radius: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          margin-bottom: 10px;
          margin-right: 10px;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #7f7f7f;
            background-color: rgba(0, 0, 0, 0.3);
            opacity: 0.6;
            border-radius: 10px;
            background-clip: padding-box;
            border: 2px solid transparent;
          }
          &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
            border-radius: 10px;
            box-shadow: inset 0px 0px 5px white;
          }
        }
        .imgbox_upload {
          width: 263px;
          height: 517px;
          display: inline-block;
          position: relative;
          cursor: pointer;
          &:hover {
            span {
              display: block;
            }
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .picture_reselect_box {
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5)
            );
            display: none;
            width: 100%;
            height: 100%;
            div {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              .newsbuilder_picture_icon {
                width: 20px;
                height: 20px;
                display: block;
                margin: 0 auto;
                margin-bottom: 6px;
              }
              p {
                color: var(--white);
              }
            }
          }
        }
        .right_layout_add {
          width: 263px;
          height: 517px;
          background: #f7f7f7;
          cursor: pointer;
          position: relative;
          margin-bottom: 10px;
          display: inline-block;
          vertical-align: top;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            img {
              width: 20px;
              height: 20px;
              display: block;
              margin: 0 auto;
              margin-bottom: 6px;
            }
            p {
              text-align: center;
              color: #7d7d7d;
            }
          }
        }
      }
      .save_button {
        text-align: right;
        button {
          background: var(--main);
          border-radius: 4px;
          padding: 7px 12px;
          color: var(--white);
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.04em;
        }
      }
      .btns {
        margin-left: 156px;
        text-align: center;
        button {
          background: #ffffff;
          box-shadow: 0px 8px 30px rgba(82, 92, 102, 0.2);
          border-radius: 100px;
          padding: 8px 10px 8px 12px;
          cursor: pointer;
          margin-right: 10px;
          img {
            width: 16px;
            height: 16px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
          }
          p {
            display: inline-block;
            vertical-align: middle;
          }
          &:hover {
            background: #f7f7f7;
            outline: 1px solid #e4e4e4;
            box-shadow: 0px 8px 30px rgba(82, 92, 102, 0.2);
            border-radius: 100px;
          }
        }
      }
    }
    &:nth-child(3) {
      ul {
        &:nth-child(1) {
          li {
            button {
              border: 1px solid #6c38ff;
              border-radius: 4px;
              padding: 5.5px 10px;
              color: var(--main);
            }
          }
        }
        &:nth-child(2) {
          border-radius: 10px;
          width: 536px;
          margin-left: 156px;
          li {
            padding: 10px 14px;
            width: 100%;
            margin-bottom: 6px;
            border: 1px solid #e4e4e4;
            border-radius: 10px;
            > img {
              width: 38px;
              height: 38px;
              margin-right: 10px;
              display: inline-block;
              vertical-align: middle;
            }
            > div {
              width: 410px;
              display: inline-block;
              vertical-align: middle;
              margin-right: 10px;
              h5 {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: -0.04em;
                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: keep-all;
                height: 20px;
              }
              p {
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.04em;
                color: #7d7d7d;
              }
            }
            .close_icon {
              width: 28px;
              height: 28px;
              display: inline-block;
              vertical-align: middle;
              cursor: pointer;
            }
          }
        }
      }
    }
    &:nth-child(4) {
      margin-bottom: 200px;
      ul {
        li {
          button {
            border: 1px solid #6c38ff;
            border-radius: 4px;
            padding: 5.5px 10px;
            color: var(--main);
          }
          textarea {
            border: 1px solid var(--grey4);
            padding: 7px 12px;
            display: block;
            width: 100%;
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            &::-webkit-scrollbar {
              width: 10px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #7f7f7f;
              background-color: rgba(0, 0, 0, 0.3);
              opacity: 0.6;
              border-radius: 10px;
              background-clip: padding-box;
              border: 2px solid transparent;
            }
            &::-webkit-scrollbar-track {
              background-color: #f3f3f3;
              border-radius: 10px;
              box-shadow: inset 0px 0px 5px white;
            }
          }
        }
      }
    }
  }
`;

// 뉴스레터 작성 AddHeader
export const AddHeader = styled.div`
  width: 100%;
  background: #ffffff;
  height: 58px;
  border-bottom: 1px solid #e4e4e4;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  > div {
    width: 90%;
    margin: 0 auto;
    position: relative;
    margin-top: 12px;
    > button {
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      padding: 7px 12px;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      letter-spacing: -0.04em;
      color: #2e2e2e;
      display: inline-block;
      vertical-align: middle;
      margin-right: 30px;
    }
    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      text-align: center;
      letter-spacing: -0.04em;
      color: #2e2e2e;
      vertical-align: middle;
      display: inline-block;
      margin-bottom: 3px;
    }
    > div {
      position: absolute;
      right: 0;
      top: 0;
      input {
        vertical-align: middle;
        display: inline-block;
      }
      input[type="checkbox"] {
        display: inline-block;
        width: 100px;
        opacity: 1;
        margin-right: 8px;
        accent-color: var(--grey1);
        border: 1px solid var(--grey4);
        border-radius: 4px;
        position: static;
        vertical-align: middle;
        cursor: pointer;
      }
      input[type="checkbox" i] {
        width: 20px;
        height: 20px;
        border: 1px solid var(--grey4);
      }
      input[type="checkbox"]:checked {
        background-color: var(--grey1);
        color: var(--white);
      }
      label {
        font-size: 14px;
        margin-right: 30px !important;
        margin-right: 0;
        cursor: pointer;
        vertical-align: middle;
      }
      button {
        vertical-align: middle;
        display: inline-block;
        background: #3b3b3b;
        border-radius: 4px;
        padding: 7px 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.04em;
        color: var(--white);
      }
    }
  }
`;

// 발간된 뉴스빌더
export const NewsBuilderSave = styled.div`
  width: 744px;
  margin: 0 auto;
  padding-top: 100px;
  margin-bottom: 200px;
  @media screen and (max-width: 1199px) and (min-width: 810px) {
    width: 730px;
  }
  @media screen and (max-width: 809px) {
    width: 90%;
  }
  #news {
    // 다운로드 부분 때문에 필요
    background-color: var(--white);

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 190%;
      letter-spacing: -0.04em;
      color: #374553;
    }
  }
  .tag_list {
    li {
      display: inline-block;
      margin-bottom: 20px;
      color: var(--main);
      font-size: 18px;
      font-weight: 500;
      &:nth-child(1) {
        border-right: 1px solid var(--grey4);
        padding-right: 5px;
        margin-right: 5px;
      }
    }
  }
  h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    letter-spacing: -0.04em;
    color: #2e2e2e;
    margin-bottom: 20px;
    @media screen and (max-width: 809px) {
      font-size: 26px;
    }
  }
  .info {
    margin-bottom: 50px;
    li {
      display: inline-block;
      margin-right: 20px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        display: inline-block;
        vertical-align: middle;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #646f7c;
        margin-bottom: 0;
        @media screen and (max-width: 809px) {
          font-size: 16px;
        }
      }
    }
  }
  .tab {
    width: 744px;
    margin: 0 auto;
    text-align: right;
    @media screen and (max-width: 1199px) and (min-width: 810px) {
      width: 720px;
    }
    @media screen and (max-width: 809px) {
      width: 100%;
      text-align: left;
    }
    span {
      display: inline-block;
      background: #f2f4f6;
      border-radius: 6px;
      padding: 11px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #a2a7b1;
      margin: 50px 0;
      margin-right: 8px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.on {
        background-color: var(--main);
        color: var(--white);
      }
    }
  }
  > img {
    width: 744px;
    display: block;
    object-fit: cover;
    margin-bottom: 30px;
    @media screen and (max-width: 1199px) and (min-width: 810px) {
      width: 730px;
    }
    @media screen and (max-width: 809px) {
      width: 100%;
      height: 209px;
      margin-bottom: 30px;
    }
  }
  > p {
    font-weight: 400;
    font-size: 18px;
    line-height: 190%;
    letter-spacing: -0.04em;
    color: #374553;
    margin-bottom: 47px;
    @media screen and (max-width: 809px) {
      font-size: 16px;
    }
  }
  .side_imgbox {
    width: 100%;
    .side_template {
      > div {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 30px;
        @media screen and (max-width: 809px) {
          width: 100%;
        }
        &:nth-child(1) {
          padding-right: 30px;
          @media screen and (max-width: 809px) {
            padding-right: 0;
          }
        }
        > p {
          font-weight: 400;
          font-size: 18px;
          line-height: 190%;
          letter-spacing: -0.04em;
          color: #374553;
          display: inline-block;
          vertical-align: top;
          @media screen and (max-width: 809px) {
            font-size: 16px;
          }
        }
      }
    }

    > p {
      width: 100%;
      font-weight: 400;
      font-size: 18px;
      line-height: 190%;
      letter-spacing: -0.04em;
      color: #374553;
      margin-bottom: 30px;
      @media screen and (max-width: 809px) {
        font-size: 16px;
      }
    }
  }

  .btns {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 90px;
    button {
      padding: 14px 16px;
      border: 1px solid var(--main);
      border-radius: 6px;
      box-sizing: border-box;
      height: 52px;
      &:nth-child(1) {
        margin-right: 20px;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        display: inline-block;
        vertical-align: middle;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        color: var(--main);
        margin-bottom: 0;
        font-size: 16px;
        line-height: 150%;
        font-weight: 500;
      }
    }
  }
  .newsbtns {
    margin-top: 30px;
    button {
      background: #f7f8f9;
      border-radius: 6px;
      padding: 10px 16px;
      cursor: pointer;
      &.liked {
        background: #f0ebff;
      }
      &:nth-child(1) {
        margin-right: 8px;
      }
      > img {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
      > p {
        display: inline-block;
        vertical-align: middle;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: var(--grey5);
        width: fit-content;
        &.liked {
          color: var(--main);
        }
      }
    }
  }
  .center {
    width: 100%;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 475px;
      object-fit: cover;
      display: block;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 190%;
      letter-spacing: -0.04em;
      margin-top: 30px;
    }
    @media screen and (max-width: 809px) {
      width: 100%;
      img {
        height: 209px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .left {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    img {
      height: 510px;
      object-fit: cover;
      width: 50%;
      vertical-align: top;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 190%;
      letter-spacing: -0.04em;
      width: 50%;
      vertical-align: top;
      padding-left: 30px;
    }
    @media screen and (max-width: 809px) {
      width: 100%;
      flex-direction: column;
      img {
        width: 100%;
        display: block;
        height: 468px;
      }
      p {
        font-size: 16px;
        width: 100%;
        display: block;
        margin-top: 30px;
        padding-left: 0;
      }
    }
  }
  .right {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 190%;
      letter-spacing: -0.04em;
      width: 50%;
      vertical-align: top;
      padding-right: 30px;
    }
    img {
      height: 510px;
      object-fit: cover;
      width: 50%;
      vertical-align: top;
    }
    @media screen and (max-width: 809px) {
      width: 100%;
      flex-direction: column-reverse;
      p {
        font-size: 16px;
        width: 100%;
        margin-top: 30px;
        padding-right: 0;
        order: 1;
      }
      img {
        width: 100%;
        height: 468px;
        order: 2;
      }
    }
  }
`;
// 발간된 뉴스레터 빌더
export const NewsLetterBuilderSave = styled.div`
  width: 595px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 200px;
  #news {
    // 다운로드 부분 때문에 필요
    background-color: var(--white);
  }
  .btns {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 90px;
    margin-top: 40px;
    button {
      padding: 14px 16px;
      border: 1px solid var(--main);
      border-radius: 6px;
      box-sizing: border-box;
      height: 52px;
      &:nth-child(1) {
        margin-right: 20px;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        display: inline-block;
        vertical-align: middle;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        color: var(--main);
        margin-bottom: 0;
        font-size: 16px;
        line-height: 150%;
        font-weight: 500;
      }
    }
  }
`;
// 뉴스레터 빌더 탬플릿
export const NewsLetterTemplate = styled.div`
  border: 10px solid var(--main);
  width: 595px;
  margin: 0 auto;
  background-color: var(--white);
  position: relative;
  .newsletter_builder_symbol {
    position: absolute;
    left: 10px;
    bottom: 87px;
    width: 137px;
    height: 132.99px;
  }
  .newsletter_builder_wrap {
    padding: 44px 54px;
    .header {
      width: 100%;
      border-bottom: 1px solid var(--grey3);
      padding-bottom: 28px;
      > img {
        width: 104px;
        height: 30px;
        vertical-align: middle;
        display: inline-block;
        margin-top: 8px;
      }
      > div {
        float: right;
        height: 46px;

        > img {
          width: 73px;
          height: 46px;
          vertical-align: middle;
          display: inline-block;
        }
        span {
          padding: 6px 10px;
          height: 36px;
          background: #e2d7ff;
          border-radius: 6px;
          background-color: #e2d7ff;
          color: var(--main);
          font-weight: 700;
          vertical-align: middle;
          display: inline-block;
          line-height: 24px;
        }
      }
    }
    .title {
      > img {
        width: 111px;
        height: 116px;
        display: block;
        margin: 40px auto;
      }
      input[type="text"] {
        font-weight: 700;
        font-size: 26px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #28323c;
        border: none;
        padding-left: 0;
        margin-bottom: 10px;
        text-align: center;
        &::placeholder {
          color: #374553;
        }
      }
      h1 {
        font-weight: 700;
        font-size: 26px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #28323c;
        text-align: center;
        margin-bottom: 10px;
      }

      div {
        text-align: center;
        margin-bottom: 40px;
        img {
          display: inline-block;
          vertical-align: middle;
          width: 60px;
          height: 22px;
          margin-right: 14px;
        }
        p {
          display: inline-block;
          vertical-align: middle;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          text-align: center;
          letter-spacing: -0.04em;
          color: #646f7c;
        }
      }
    }
    //  뉴스레터 빌터 컴포넌트1
    .newsletter_component {
      margin-bottom: 90px;
      .imgbox {
        width: 467px;
        height: 248px;
        background-color: var(--grey1);
        display: block;
        cursor: pointer;
        > div {
          padding-top: 117px;
          .newsbuilder_picture_icon {
            width: 20px;
            height: 20px;
            display: block;
            margin: 0 auto;
            margin-bottom: 6px;
          }
          p {
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: #646f7c;
          }
        }
      }

      //  뉴스레터 빌터 컴포넌트1 - 이미지 업로드
      .imgbox_upload {
        width: 467px;
        height: 248px;
        cursor: pointer;
        position: relative;
        .newsbuilder_picture_icon {
          width: 20px;
          height: 20px;
          display: block;
          margin: 0 auto;
          margin-bottom: 6px;
        }
        img {
          width: 100% !important;
          height: 100% !important;
          display: block;
          object-fit: cover;
        }
        span {
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          );
          display: none;
          width: 100%;
          height: 100%;
          > div {
            padding-top: 117px;
            .newsbuilder_picture_icon {
              width: 20px;
              height: 20px;
              display: block;
              margin: 0 auto;
              margin-bottom: 6px;
            }
            p {
              text-align: center;
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              letter-spacing: -0.04em;
              color: var(--white);
            }
          }
        }
        &:hover {
          span {
            display: block;
          }
        }
      }
      .news_bring {
        margin-top: 40px;
        input[type="text"] {
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: #374553;
          border: none;
          padding-left: 0;
          margin-bottom: 20px;
          &::placeholder {
            color: #374553;
          }
        }
        h2 {
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: #374553;
          border: none;
          padding-left: 0;
          margin-bottom: 20px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 170%;
          letter-spacing: -0.04em;
          color: #374553;
          margin-bottom: 10px;
        }
        button {
          padding: 11px 16px;
          height: 42px;
          background: #f2f4f6;
          border-radius: 6px;
          color: var(--grey6);
        }
      }
    }

    /* 뉴스레터 빌터 컴포넌트2  */
    .newsletter_special_component {
      margin-top: 90px;
      position: relative;
      > .picture_component {
        margin-bottom: 90px;
        > div {
          position: relative;
          .special_tag {
            background: rgba(255, 80, 122, 0.2);
            border-radius: 6px;
            transform: rotate(-10deg);
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.04em;
            color: #ff507a;
            position: absolute;
            transform: rotate(-10deg);
            padding: 4px 10px;
            top: -30px;
            left: -30px;
          }
          input[type="text"] {
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: #374553;
            border: none;
            padding-left: 0;
            margin-bottom: 20px;
            &::placeholder {
              color: #374553;
            }
          }
          h2 {
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: #374553;
            border: none;
            padding-left: 0;
            margin-bottom: 20px;
          }
          .text_box {
          }
        }
        .narrow_component {
          div {
            &:nth-child(1) {
              margin-right: 30px;
            }
            &.imgbox_narrow {
              width: 218.5px;
              height: 248px;
              background-color: var(--grey1);
              display: inline-block;
              cursor: pointer;
              > div {
                width: 218.5px;
                height: 248px;
                padding-top: 117px;
                .newsbuilder_picture_icon {
                  width: 20px;
                  height: 20px;
                  display: block;
                  margin: 0 auto;
                  margin-bottom: 6px;
                }

                p {
                  text-align: center;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 140%;
                  letter-spacing: -0.04em;
                  color: #646f7c;
                }
              }
            }
            &.textarea {
              width: 218.5px;
              height: 248px;
              display: inline-block;
              vertical-align: top;
              textarea {
                width: 218.5px;
                height: 248px;
                font-size: 14px;
                line-height: 170%;
                &::placeholder {
                  color: var(--grey7);
                  text-align: left;
                  white-space: normal;
                }
              }
            }
          }

          /* 뉴스레터 빌터 컴포넌트2 - 이미지 업로드 */
          .imgbox_upload {
            width: 218.5px;
            height: 248px;
            padding-top: 0 !important;
            cursor: pointer;
            position: relative;
            img {
              width: 218.5px;
              height: 248px;
              display: block;
              object-fit: cover;
            }
            span {
              position: absolute;
              top: 0;
              left: 0;
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              );
              display: none;
              width: 100%;
              height: 100%;
              > div {
                padding-top: 117px;
                margin-right: 0;
                img {
                  width: 20px;
                  height: 20px;
                  display: block;
                  margin: 0 auto;
                  margin-bottom: 6px;
                }
                p {
                  text-align: center;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 140%;
                  letter-spacing: -0.04em;
                  color: var(--white);
                }
              }
            }
            &:hover {
              span {
                display: block;
              }
            }
          }
        }
      }
    }
    .imgbox_upload_done {
      cursor: auto;
      padding-top: 0 !important;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text_box {
      width: 218.5px;
      height: 248px;
      display: inline-block;
      vertical-align: top;
      p {
        line-height: 170%;
      }
    }
  }

  .newsletter_builder_footer {
    width: 100%;
    height: 110px;
    background: #f2f4f6;
    z-index: 20;
    position: relative;
    margin-top: 110px;
    padding: 30px;
    > div {
      > img {
        width: 104px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-top: 10px;
      }
      ul {
        display: inline-block;
        vertical-align: middle;
        float: right;
        li {
          margin-bottom: 10px;
          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
          }
          p {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }
`;

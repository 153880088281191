import styled from "styled-components";

//img
import Calendar from "Assets/images/icons/calendar.svg";
import Stroke1 from "Assets/images/icons/Stroke1.svg";
import Rightbar from "Assets/images/icons/rightbar.png";

export const View = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  z-index: 999;
`;

export const Main = styled.div`
  padding: 20px;
  width: ${({ modalWidth }) => (modalWidth ? modalWidth : 300) + "px"};
  /* height: ${({ modalHeight }) =>
    (modalHeight ? modalHeight : 300) + "px"}; */
  border-radius: ${({ modalBorderRadius }) =>
    (modalBorderRadius ? modalBorderRadius : 0) + "px"};
  /* max-height: 720px; */
  min-height: ${({ modalMinheight }) =>
    (modalMinheight ? modalMinheight : 100) + "px"};
  max-height: ${({ modalMaxheight }) =>
    (modalMaxheight ? modalMaxheight : 100) + "px"};
  /* height: ${({ modalHeight }) =>
    (modalHeight ? modalHeight : 100) + "px"}; */
  background: #fff;
  position: relative;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7f7f7f;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0.6;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: #f3f3f3;
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
  .line {
    width: 100%;
    height: 1px;
    border: 1px solid var(--grey4);
    display: block;
    margin: 14px 0;
  }
  p {
    text-align: left;
    color: var(--text);
    font-size: 16px;
  }
  .agree_text {
    text-align: left;
    p {
      margin-bottom: 30px;
      line-height: 140%;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .scrollBox {
    overflow-y: auto;
    height: 495px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #7f7f7f;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0.6;
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: #f3f3f3;
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
    li {
      width: 100%;
      height: 46px;
      display: inline-block;
      position: relative;
      text-align: left;
      margin-bottom: 10px;
      img {
        width: 46px;
        height: 46px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
        object-fit: cover;
      }
      span {
        display: inline-block;
        width: 514px;
        text-align: left;
        vertical-align: middle;
        h6 {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: var(--text);
        }
        p {
          font-size: 12px;
          color: var(--grey2);
          line-height: 140%;
        }
      }
      .pick {
        padding: 5.5px 10px;
        background-color: var(--main);
        color: var(--white);
        font-size: 12px;
        border: none;
        width: 45px;
        height: 28px;
        line-height: 140%;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        right: 0;
        margin-right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .btns {
    width: 100%;
    text-align: right;
    button {
      margin-top: 30px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    &.right {
      text-align: right;
    }
    &.left {
      text-align: left;
    }
  }
  h3 {
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: 600;
    color: var(--text);
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.04em;
  }
  h4 {
    font-size: 20px;
    font-weight: 600;
    color: var(--text);
    text-align: left;
    display: block;
    line-height: 140%;
    width: calc(100% - 40px);
    word-break: keep-all;
  }
  h5 {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    color: var(--text);
    text-align: left;
    display: inline;
    line-height: 140%;
  }
  .closebtn {
    position: absolute;
    right: 15px;
    top: 20px;
    border: none;
  }
  i {
    color: #000;
    font-size: 16px;
  }
  > div {
    width: 100%;
    input {
      width: 100%;
    }
    &.btn_section {
      text-align: right;
      position: relative;
      min-height: 32px;
      button {
        margin-left: 10px;
        &.red {
          position: absolute;
          left: 0;
          margin-left: 0;
        }
        &.cancel_btn {
          position: absolute;
          right: 0;
        }
        &.gray {
          position: absolute;
          left: 57px;
        }
      }
    }
  }
  .modal_table {
    td {
      width: 495px;
      padding: 8px 20px;
      vertical-align: middle;
      &:nth-child(1),
      &:nth-child(3) {
        background-color: var(--grey5);
        width: 140px;
        padding: 12px 20px;
      }
      label {
        margin-right: 40px;
      }
      .shortbox {
        width: 275px !important;
      }
      > div {
        padding-bottom: 18px;
        display: inline-block;
        vertical-align: middle;
        z-index: 9;
        &:nth-child(2) {
          margin-right: 0;
          div {
            margin-right: 0;
          }
        }
        input {
          display: inline-block;
          margin-right: 8px;
          border-radius: 4px;
          padding-left: 12px;
        }
        label {
          margin-right: 14px;
        }
        select {
          width: 154px;
          display: inline-block;
          height: 36px;
          border-radius: 4px;
        }
      }
      // 달력
      input[type="date"] {
        width: 300px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        ::-webkit-datetime-edit-fields-wrapper {
          padding-left: 28px;
          position: relative;
        }
        // placeholder
        ::before {
          /* content: attr(data-placeholder); */
          content: attr(data-placeholder);
          width: 100%;
          color: var(--grey3);
          padding-left: 30px;
        }
        // rightbar
        ::after {
          background: url(${Rightbar});
          content: "";
          display: block;
          z-index: 9;
          position: absolute;
          right: 12px;
          width: 20px;
          height: 20px;
          transform: rotate(90deg);
        }
        ::-webkit-calendar-picker-indicator {
          background: url(${Calendar});
          background-repeat: no-repeat;
          position: absolute;
          left: 12px;
          content: "";
          width: 100%;
          height: 20px;
          margin-top: 4px;
          display: block;
          cursor: pointer;
        }
      }
      input[type="date"]:focus:before,
      input[type="date"]:valid:before {
        display: none;
      }
    }
  }

  //주소 선택 모달
  button {
    padding: 5.5px 10px;
    color: var(--white);
    font-size: 14px;
    line-height: 140%;
    display: inline-block;
    vertical-align: middle;
    /* border: 1px solid var(--grey1); */
    border-radius: 4px;
    color: var(--text);
  }
  .gm-style-iw.gm-style-iw-c {
    border: 1px solid #e4e4e4;
    filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.15));
    box-shadow: none;
  }
  .gm-style .gm-style-iw-tc {
    top: -2px;
  }
  #menu_wrap {
    height: 400px;
    overflow: hidden;
    margin-top: 30px;
    .map {
      /* width: 560px; */
      width: 100%;
      height: 400px;
      display: inline-block;
      background-color: var(--grey5);
      #pac-input-container {
        position: relative;
        width: 250px;
        height: 36px;
        background-color: var(--white) !important;
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        margin: 10px;
      }
      #pac-input.pac-target-input {
        position: absolute;
        width: 250px;
        height: 36px;
        padding: 7px 22px 7px 12px;
        font-size: 14px;
        border-radius: 4px;

        &:focus {
          border: 1px solid var(--text);
          color: var(--text);
          &::placeholder {
            color: var(--text) !important;
            font-size: 14px;
          }
        }
      }
      i {
        color: var(--grey3) !important;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 5px;
        /* transform: translateX(-100%); */
        top: 9px;
        cursor: pointer;
      }
      #map_text {
        display: inline-block;
        text-align: left;
        font-size: 12px;
        margin-right: 10px;
        vertical-align: middle;
        #info_title {
          line-height: 140%;
        }
        #info_sub {
          color: var(--grey2);
          line-height: 140%;
        }
      }
      .map_select_btn {
        border: 1px solid var(--grey1);
      }
    }
  }
  .locate_text {
    h4 {
      font-size: 14px;
      margin: 0;
      line-height: 140%;
    }
    p {
      font-size: 14px;
      color: var(--grey2);
      line-height: 140%;
    }
    #map_text {
    }
  }

  /* 달력 */
  .datePickerContainer {
    width: 100%;
    p {
      display: block;
      background-color: var(--grey5);
      color: var(--red);
      width: 100%;
      padding: 10px;
      font-size: 14px;
      line-height: 140%;
      margin-top: 20px;
    }
    ul {
      text-align: left;
      margin-top: 20px;
      padding-left: 2px;
      margin-bottom: 20px;
      li {
        display: inline-block;
        width: 110px;
        font-size: 14px;
        margin-bottom: 14px;
        &:nth-child(5),
        :nth-child(6),
        :nth-child(7) {
          margin-bottom: 0;
        }
        input {
          height: 36px;
          width: 100%;
          border: 1px solid var(--grey4);
          padding-left: 12px;
          border-radius: 4px;
          font-size: 14px;
          ::placeholder {
            color: var(--grey3);
          }
        }
        input[type="checkbox"] {
          display: inline-block;
          width: 100px;
          opacity: 1;
          margin-right: 8px;
          accent-color: var(--grey1);
          border: 1px solid var(--grey4);
          border-radius: 4px;
          position: static;
          vertical-align: middle;
          cursor: pointer;
        }
        input[type="checkbox" i] {
          width: 20px;
          height: 20px;
          border: 1px solid var(--grey4);
        }
        input[type="checkbox"]:checked {
          background-color: var(--grey1);
          color: var(--white);
        }
        label {
          font-size: 13px;
          margin-right: 16px;
          margin-right: 0;
          cursor: pointer;
          vertical-align: middle;
        }
      }
    }
    .datePicker {
      .DayPicker_transitionContainer {
        width: 100%;
        overflow-y: auto;
        height: 450px !important;
        padding-bottom: 80px;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #7f7f7f;
          background-color: rgba(0, 0, 0, 0.3);
          opacity: 0.6;
          border-radius: 10px;
          background-clip: padding-box;
          border: 2px solid transparent;
        }
        &::-webkit-scrollbar-track {
          background-color: #f3f3f3;
          border-radius: 10px;
          box-shadow: inset 0px 0px 5px white;
          margin-bottom: 80px;
        }
      }
      .DayPickerNavigation {
        display: none;
      }
      .CalendarMonthGrid.CalendarMonthGrid__vertical.CalendarMonthGrid_1.CalendarMonthGrid__vertical_2 {
        width: 100% !important;
      }
      .CalendarMonth_table.CalendarMonth_table_1 {
        width: 100% !important;
      }
      .CalendarMonthGrid_month__hidden.CalendarMonthGrid_month__hidden_1 {
        /* display: none; */
        position: absolute;
        bottom: 0;
        transform: translateX(100%);
      }

      .CalendarMonth_caption {
        padding: 20px 0 5px;
        text-align: left;
        &:nth-child(1) {
          padding: 0;
        }
        .week {
          padding: 10px 5px 0;
          li {
            display: inline-block;
            font-size: 12px;
            width: calc(100% / 7);
            text-align: center;
            color: var(--grey3);
            &:nth-child(1) {
              color: #f9665c;
            }
          }
        }
      }
      .CalendarDay__selected,
      .CalendarDay__selected:active,
      .CalendarDay__selected:hover {
        border-radius: 100%;
        border: none;
        background-color: var(--main);
        width: 60px !important;
        height: 60px !important;
        /* margin: 0 15px !important; */
      }

      .CalendarDay.CalendarDay_1.CalendarDay__default.CalendarDay__default_2 {
      }
      .CalendarMonth {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 !important;
      }
      .CalendarDay:hover {
        border-radius: 100%;
        width: 60px !important;
        height: 60px !important;
      }
      .CalendarDay {
        /* border: 15px solid var(--white) !important;
        border-style: outset; */
        border: none;
        width: 60px !important;
        height: 63px !important;
        margin: 0 15px !important;
      }
      .DayPicker_weekHeaders {
        display: none;
      }
      .CalendarDay__blocked_calendar,
      .CalendarDay__blocked_calendar:active,
      .CalendarDay__blocked_calendar:hover {
        color: #cacccd;
        background: #fff;
      }
    }
  }
  .buttonbox {
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--white);
    /* button {
      :nth-child(1) {
        float: left;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        left: 20px;
      }
    } */
  }

  /* 메인 -> 상품추가 모달 */
  .search {
    width: 615px;
    font-size: 14px;
    color: #7f7f7f;
    display: inline-block;
    position: relative;
    background-color: var(--white);
    margin-bottom: 10px;
    margin-top: 10px;
    input {
      width: 100%;
      border-radius: 4px;
      padding-left: 12px;
      &:focus {
        border: 1px solid var(--text);
        color: var(--text);
        + i {
          color: var(--text) !important;
        }
      }
    }
    i {
      color: var(--grey3) !important;
      position: absolute;
      right: 12px;
      top: 9px;
      cursor: pointer;
      font-size: 17px;
    }
  }
  .select {
    width: 100%;
    height: 36px;
    select {
      width: 200px;
      text-align: left;
      border-radius: 4px;
      float: left;
      margin-right: 10px;
      height: 36px;
      padding-left: 12px;
      color: var(--grey3);
      cursor: pointer;
      font-size: 14px;
    }
  }

  .scrollbox {
    position: relative;
    margin-top: 16px;
    overflow-y: auto;
    height: 623px;
    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: var(--grey4);
      position: absolute;
      top: 0;
      left: 0;
    }
    li {
      padding-top: 16px;
      text-align: left;
      input[type="checkbox"] {
        display: inline-block;
        width: 100px;
        opacity: 1;
        margin-right: 8px;
        accent-color: var(--grey1);
        border: 1px solid var(--grey4);
        position: static;
        vertical-align: middle;
        appearance: none;
        background: #fff;
        border-radius: 4px;
        cursor: pointer;
        height: 16px;
        outline: 0;
        width: 16px;
      }
      input[type="checkbox" i] {
        width: 20px;
        height: 20px;
      }
      input[type="checkbox"]:checked {
        background-color: var(--grey1);
        color: var(--white);
      }
      input[type="checkbox"]:checked::after {
        display: block;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        position: relative;
        top: 3px;
        left: -5px;
        transform: rotate(45deg);
        width: 3px;
        height: 7px;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        width: 38px;
        height: 38px;
        margin-right: 10px;
        vertical-align: top;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        h6 {
          line-height: 140%;
          font-size: 14px;
          font-weight: 400;
        }
        p {
          line-height: 140%;
          font-size: 12px;
          color: var(--grey2);
        }
      }
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #7f7f7f;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0.6;
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: #f3f3f3;
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
  }
  .buttonbox {
    width: 100%;
    height: 88px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--white);
    button {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }

  // 이미지 관리 => 사진추가 , 사진상세 모달
  .picture_modal {
    ul {
      width: 100%;
      text-align: left;
      li {
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 140%;
        cursor: pointer;
        &.id_none {
          img {
            height: 150px;
            width: 100%;
            object-fit: cover;
            position: relative;
          }
          &:hover {
            span {
              display: block;
            }
          }
        }
        .add_img {
          position: relative;
          span {
            display: none;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5)
            );
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              width: 20px;
              height: 20px;
            }
          }
        }
        img {
          width: 100%;
          height: 150px;
          object-fit: cover;
          position: relative;
        }
        &:nth-child(1) {
          width: 140px;
          padding-top: 12px;
        }
        &:nth-child(2) {
          width: 77%;
          padding: 12px 20px;
        }
        .hidden {
          display: none;
        }
        > .image_add {
          width: 435px;
          height: 150px;
          background: #f7f7f7;
          border: 1px solid #e4e4e4;
          display: block;
          cursor: pointer;
          img {
            width: 24px;
            height: 24px;
            display: block;
            margin: 0 auto;
            margin-top: 50px;
            margin-bottom: 4px;
          }
          p {
            text-align: center;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: -0.04em;
            color: var(--grey2);
          }
        }
        input[type="text"] {
          width: 302px;
          height: 34px;
          border: 1px solid #e4e4e4;
          border-radius: 4px;
          display: inline-block;
          padding: 7px 12px;
          font-size: 14px;
        }
        .tag_err {
          color: var(--red);
        }
        button {
          color: var(--grey1);
          border: 1px solid var(--grey1);
          border-radius: 4px;
          margin-left: 10px;
          height: 34px;
          width: 50px;
          vertical-align: top;
        }
        p {
          font-size: 12px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: var(--grey2);
          margin-top: 8px;
        }
        input[type="checkbox"] {
          display: inline-block;
          width: 100px;
          opacity: 1;
          margin-right: 8px;
          accent-color: var(--grey1);
          border: 1px solid var(--grey4);
          border-radius: 4px;
          position: static;
          vertical-align: middle;
          cursor: pointer;
        }
        input[type="checkbox" i] {
          width: 20px;
          height: 20px;
          border: 1px solid var(--grey4);
        }
        input[type="checkbox"]:checked {
          background-color: var(--grey1);
          color: var(--white);
        }
        label {
          cursor: pointer;
          &.admin_check {
            vertical-align: middle;
          }
        }
      }
    }
    .tagbox {
      margin-top: 10px;
      span {
        padding: 2px 8px;
        background: var(--grey5);
        border-radius: 4px;
        display: inline-block;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: var(--grey1);
        word-break: break-all;
        .xi-close {
          font-size: 10px;
          margin-left: 6px;
          cursor: pointer;
        }
      }
    }
    // 사진 삭제모달
    .btn {
      text-align: right;
      button {
        margin-left: 10px;
      }
    }
  }
  // 뉴스작성 -> 본문 레이아웃 선택 모달
  .modal_style {
    ul {
      margin-top: 16px;
      li {
        width: 118px;
        height: 105px;
        display: inline-block;
        background-color: var(--white);
        margin-right: 10px;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 14px;
        border: 1px solid #e4e4e4;
        vertical-align: top;
        &:nth-child(3) {
          margin-right: 0;
        }
        &:hover {
          background: #f7f7f7;
          cursor: pointer;
        }
        &:nth-child(1) {
          > div {
            width: 90px;
            height: 50px;
            border-radius: 4px;
            background: #d9d9d9;
            margin: 0 auto;
            margin-bottom: 5px;
          }
          span {
            width: 90px;
            height: 4px;
            border-radius: 4px;
            background: #d9d9d9;
            display: block;
            margin: 0 auto;
            margin-bottom: 5px;
            text-align: left;
            &:last-child {
              width: 30px;
              margin: 0;
            }
          }
        }
        &:nth-child(2) {
          > div {
            &:nth-child(1) {
              display: inline-block;
              vertical-align: top;
              width: 42px;
              height: 77px;
              border-radius: 4px;
              background: #d9d9d9;
              margin-bottom: 5px;
              margin-right: 6px;
            }
            &:nth-child(2) {
              display: inline-block;
              vertical-align: top;
              width: 40px;
              span {
                width: 40px;
                height: 4px;
                border-radius: 4px;
                background: #d9d9d9;
                margin-bottom: 5px;
                display: block;
                &:last-child {
                  width: 10px;
                  margin: 0;
                }
              }
            }
          }
        }
        &:nth-child(3) {
          > div {
            &:nth-child(1) {
              display: inline-block;
              vertical-align: top;
              width: 40px;
              margin-right: 6px;
              span {
                width: 40px;
                height: 4px;
                border-radius: 4px;
                background: #d9d9d9;
                margin-bottom: 5px;
                display: block;
                &:last-child {
                  width: 10px;
                  margin: 0;
                }
              }
            }
            &:nth-child(2) {
              display: inline-block;
              vertical-align: top;
              width: 42px;
              height: 77px;
              border-radius: 4px;
              background: #d9d9d9;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  // 연관뉴스 추가 모달
  .add_news {
    .search_none {
      margin-top: 50px;
    }
    .inputbox {
      margin-bottom: 10px;
      width: 615px;
      height: 34px;
      line-height: 140%;
      background: #ffffff;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      position: relative;
      padding: 7px 12px;
      margin-top: 30px;
      font-size: 14px;
      input {
        width: 615px;
      }
      .xi-search {
        font-size: 20px;
        position: absolute;
        right: 0;
        color: var(--grey3);
        top: 6px;
        right: 14px;
      }
    }
    ul {
      border-radius: 10px;
      width: 615px;
      text-align: left;
      margin-top: 14px;
      padding-top: 14px;
      border-top: 1px solid var(--grey4);
      height: 0px;
      overflow-y: auto;
      height: 390px;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #7f7f7f;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0.6;
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: #f3f3f3;
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
      }
    }
    li {
      display:flex;
      width: 100%;
      margin-bottom: 16px;
      input[type="checkbox"] {
        display: inline-block;
        width: 100px;
        opacity: 1;
        margin-right: 8px;
        accent-color: var(--grey1);
        position: static;
        vertical-align: middle;
      }
      button{
        width:80px;
      }
      input[type="checkbox" i] {
        width: 20px;
        height: 20px;
        margin-top: 0;
      }
      input[type="checkbox"]:checked {
        background-color: var(--grey1);
        color: var(--white);
      }
      > img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 4px;
        overflow: hidden;
        background-color: var(--grey5);
      }
      > div {
        width: 510px;
        display: inline-block;
        vertical-align: middle;
        h5 {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.04em;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: keep-all;
          height: 20px;
          margin-bottom: 0;
        }
        p {
          font-size: 12px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: #7d7d7d;
        }
      }
      span {
        display: inline-block;
        vertical-align: center;
        border-radius: 100px;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: -0.04em;
        padding: 4px 10px;
        &.maintag {
          color: var(--main);
          background: #f0ebff;
        }
        &.greytag {
          background: #f7f7f7;
          color: #2e2e2e;
        }
      }
      button {
      }
    }
  }
  .selectbox {
    text-align: left;
    font-size: 14px;
    color: #bababa;
  }

  .btns {
    button {
      padding: 9px 14px;
    }
  }

  // 사진 선택 모달
  .picture_select {
    h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.04em;
      color: #374553;
      margin-bottom: 6px;
      text-align: left;
      display: block;
    }
    h6 {
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      letter-spacing: -0.04em;
      color: #646f7c;
      text-align: left;
      margin-bottom: 12px;
    }
    > div {
      text-align: left;
      .search_box {
        width: 350px;
        height: 48px;
        border: 1px solid #e9ebee;
        border-radius: 8px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        input[type="text"] {
          padding-left: 35px;
          height: 48px;
        }
        i {
          position: absolute;
          left: 14px;
          top: 16px;
          color: var(--grey4);
        }
      }
      .image_add {
        display: inline-block;
        vertical-align: middle;
        border: 1px solid var(--main);
        color: var(--main);
        border-radius: 6px;
        padding: 11px 16px;
        font-weight: 500;
        cursor: pointer;
      }
      &.picture_list {
        margin-top: 20px;
        h6 {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.04em;
          color: #28323c;
          margin-bottom: 8px;
        }
        ul {
          width: 100%;
          display: grid;
          flex-direction: column;
          gap: 8px 8px;
          grid-template-columns: repeat(auto-fill, calc(20% - 7px));
          background-color: var(--white);
          scrollbar-width: none;
          -ms-overflow-style: none;
          overflow-y: auto;
          position: relative;
          height: 292px;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #7f7f7f;
            background-color: rgba(0, 0, 0, 0.3);
            opacity: 0.6;
            border-radius: 10px;
            background-clip: padding-box;
            border: 2px solid transparent;
          }
          &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
            border-radius: 10px;
            box-shadow: inset 0px 0px 5px white;
          }
          li {
            height: 140px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      &.search_none {
        > div {
          padding: 30px 0;
          i {
            font-size: 24px;
            color: var(--grey4);
            text-align: center;
            margin-bottom: 27px;
            display: block;
          }
          h4 {
            font-weight: 500;
            font-size: 22px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.04em;
            color: #374553;
            margin-bottom: 8px;
          }
          p {
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.04em;
            color: #646f7c;
          }
        }
      }
    }
  }
`;

// Table 사용되는 스타일 컴포넌트 모음 파일입니다.
import styled from "styled-components";

//img
import Calendar from "Assets/images/icons/calendar.svg";
import Stroke1 from "Assets/images/icons/Stroke1.svg";
import Rightarrow from "Assets/images/icons/rightarrow.svg";

// RowTable
export const RowTableContainer = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;
  width: 100%;
  margin-top: 30px;
  &.half {
    width: 50%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  tr {
    td {
      width: 30%;
      padding: 10px 20px;
      vertical-align: top;
      &:nth-child(1),
      &:nth-child(3) {
        background-color: var(--grey5);
        width: 20%;
      }
      span {
        padding: 4px 10px;
        background-color: var(--grey5);
        border-radius: 4px;
      }
      ul {
        li {
          line-height: 140%;
          margin-bottom: 15px;
          span {
            border-radius: 100px;
            padding: 4px 10px;
            font-size: 12px;
            margin-left: 4px;
            color: var(--white);
            &.agree {
              background-color: #56c500;
            }
            &.disagree {
              background-color: var(--grey3);
            }
          }
        }
      }
      input {
        display: inline-block;
        margin-right: 10px;
        position: relative;
      }
      input[type="radio"],
      input[type="radio"]:checked {
        appearance: none;
        cursor: pointer;
        padding: 0;
      }
      input[type="radio"] {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        background-color: var(--white);
        border: 1px solid var(--grey4);
        color: var(--grey4);
        margin-right: 8px;
        cursor: pointer;
        border-radius: 100%;
        &:checked {
          background-color: var(--white);
          border: 6px solid var(--grey1);
          color: var(--grey4);
        }
      }
      label {
        margin-right: 20px;
        cursor: pointer;
      }
      .box {
        display: inline-block;
        vertical-align: top;
        &::after {
          display: none;
        }
        div {
          margin-top: 10px;
        }
        .grid {
          width: 74px;
          height: 93px;
        }
        .menu_bar {
          width: 116px;
          height: 42px;
        }
      }
      .icon {
        vertical-align: middle;
        width: 20px;
      }
    }
  }
`;

// FloatTable
// 차량 상세
export const FloatTable = styled.table`
  width: 100%;
  font-size: 14px;
  margin-bottom: 56px;
  tbody {
    text-align: left;
    width: 50%;
    display: inline-block;
    border-collapse: 0;
    border-spacing: 0;
    tr {
      td {
        padding: 12px 20px;
        box-sizing: border-box;
        &:nth-child(1),
        &:nth-child(3) {
          width: 140px;

          background-color: #f7f7f7;
          vertical-align: top;
        }
        .option_info {
          background: #f7f7f7;
          border-radius: 4px;
          padding: 4px 10px;
          display: inline-block;
          margin-right: 6px;
          margin-bottom: 6px;
          font-size: 12px;
          line-height: 140%;
        }
      }
    }
  }
`;

// MiddelWhiteTable
export const MiddelWhiteTable = styled.table`
  font-size: 14px;
  width: 100%;
  border-spacing: 0;
  border-collapse: 0;
  h4 {
    color: var(--text) !important;
    font-weight: 700 !important;
    font-weight: 14px !important;
    margin-bottom: 10px;
  }
  tbody {
    text-align: left;
    width: 100%;
    margin-bottom: 30px;
    display: inline-table;
    &.line_table {
      padding: 20px 0;
      margin: 20px 0;
      border-top: 1px solid var(--grey4);
      border-bottom: 1px solid var(--grey4);
    }
    &.pd {
      margin-top: 20px;
    }
    tr {
      td {
        padding: 12px 20px;
        box-sizing: border-box;
        img {
          width: 20px;
          height: 20px;
        }
        &:nth-child(1),
        &:nth-child(3) {
          width: 12%;
          vertical-align: top;
        }
        &:nth-child(2),
        &:nth-child(4) {
          width: 35%;
        }
        &.long {
          width: 228%;
          display: block;
        }
        &.long2 {
          width: 100%;
          display: block;
        }
        &.long3 {
          width: 180%;
          display: block;
          div {
            width: 100% !important;
            div {
              &:nth-child(2) {
                width: 7% !important;
              }
            }
          }
        }
        &.long_full {
          width: 100%;
          input[type="text"] {
            width: 100%;
          }
        }
        &.long_full2 {
          padding: 0;
          width: 100%;
          input[type="text"] {
            width: 100%;
          }
        }
        &.big {
          padding: 16px 20px;
        }
        input[type="text"] {
          width: 60%;
          &.mid_input {
            width: 40%;
            display: inline-block;
          }
          &.short_input {
            width: 10%;
            display: inline-block;
            margin-left: 6px;
            margin-right: 30px;
          }
        }
        input[type="number"] {
          vertical-align: middle;
          &.short_input {
            width: 10%;
            display: inline-block;
            margin-left: 6px;
            margin-right: 30px;
          }
          &.min_input {
            width: 13%;
            display: inline-block;
            margin-left: 6px;
            margin-right: 30px;
          }
        }
        .tagbox {
          margin-top: 10px;
          span {
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
            border-radius: 100px;
            background-color: var(--grey5);
            padding: 4px 10px;
            font-size: 12px;
            margin-left: 0;
            .xi-close {
              color: var(--grey3);
              margin-left: 5px;
              vertical-align: middle;
              margin-bottom: 2px;
            }
          }
        }
        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 6px;
        }
        .short_select {
          width: 30%;
          margin-right: 10px;
        }
        .long_select {
          width: 76%;
          border-radius: 4px;
        }
        ul {
          li {
            vertical-align: top;
            display: inline-block;
            margin-right: 10px;
            position: relative;
            width: 125px;
            height: 100px;
            background-color: var(--grey5);
            border: 1px solid var(--grey4);
            cursor: pointer;
            position: relative;
            &.add_image {
              margin-bottom: 10px;
            }
            label {
              position: absolute;
              left: 50%;
              width: 100%;
              height: 100%;
              -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              transform: translateX(-50%);
            }
            img {
              width: 100%;
              height: 100%;
              &.picture_icon {
                width: 24px;
                height: 24px;
                display: block;
                margin: 0 auto;
                margin-top: 26.5px;
                margin-bottom: 6px;
                object-fit: cover;
              }
            }
            span {
              width: 100% !important;
              height: 100% !important;
              display: none;
              background: var(--black);
              opacity: 0.6;
              position: absolute;
              margin: 0 !important;
              top: 0;
              left: 0;
              padding: 0;
              border-radius: 0;
              border: none;
              cursor: pointer;
              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 44px;
                height: 44px;
                margin: 0;
                z-index: 9;
              }
            }
            &:hover span {
              display: block;
            }

            p {
              font-size: 12px;
              color: var(--grey2);
              line-height: 16.8px;
              text-align: center;
            }
          }
        }
        h6 {
          color: var(--grey2);
          font-size: 12px;
          font-weight: 400;
          margin-top: 10px;
        }
        .banner_picture {
          width: 320px;
          height: 100px;
          background-color: aliceblue;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .banner_picture_add {
          width: 125px;
          height: 100px;
          margin-right: 10px;
        }
        select {
          border-radius: 4px;
          cursor: pointer;
        }
        textarea {
          height: auto;
          border: 1px solid var(--grey4);
          border-radius: 4px;
          width: 100%;
          padding: 7px 12px;
          overflow: visible;
          resize: both;
          ::placeholder {
            color: var(--grey3);
            font-size: 14px;
          }
        }
      }
    }
  }
  .btns {
    button {
      margin-right: 0;
      float: right;
    }
  }

  button {
    margin-right: 0;
    margin: 0 auto;
    display: block;
  }
`;

// MiddleTable
export const MiddleTable = styled.table`
  font-size: 14px;
  width: 100%;
  border-spacing: 0;
  border-collapse: 0;
  h4 {
    color: var(--text) !important;
    font-weight: 700 !important;
    font-weight: 14px !important;
    margin-bottom: 10px;
  }
  tbody {
    text-align: left;
    width: 100%;
    margin-bottom: 30px;
    display: inline-table;
    /* &:last-child {
      margin-bottom: 0;
    } */
    tr {
      td {
        padding: 12px 20px;
        box-sizing: border-box;
        img {
          width: 20px;
          height: 20px;
        }
        &:nth-child(1),
        &:nth-child(3) {
          width: 20%;
          background-color: #f7f7f7;
          vertical-align: middle;
        }
        &:nth-child(2),
        &:nth-child(4) {
          width: 35%;
        }
        &.long {
          width: 228%;
          display: block;
        }
        &.long2 {
          width: 100%;
          display: block;
        }
        &.big {
          padding: 16px 20px;
        }
        .long_select {
          width: 80%;
        }
        .white_tag {
          background-color: #fff;
          border: 1px solid var(--grey1);
          color: var(--grey1);
          box-sizing: border-box;
          padding: 4px 10px;
          display: inline-block;
          vertical-align: middle;
          border-radius: 4px;
          margin-left: 10px;
          cursor: pointer;
          float: right;
        }
      }
    }
  }
  .btns {
    float: right;
    button {
      height: 36px;
      line-height: inherit;
      margin-right: 0;
      box-sizing: border-box;
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
`;

// LongTable
export const LongTable = styled.table`
  font-size: 14px;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  tbody {
    text-align: left;
    width: 100%;
    display: block;
    margin-bottom: 30px;
    display: inherit;
    tr {
      display: flex;
      td {
        padding: 12px 20px;
        box-sizing: border-box;
        &:nth-child(1) {
          width: 100%;
          display: block;
          padding: 16px 20px;
          width: 140px;
          background-color: #f7f7f7;
          vertical-align: top;
        }
        &:nth-child(2) {
          width: 100%;
          padding: 8px 20px;
          padding-right: 0;
          img {
            width: 125px;
            height: 100px;
          }
        }
        /* &.big {
          height: 86px;
        } */
        img {
          margin-right: 6px;
          &.picture_icon {
            width: 20px !important;
            height: 20px !important;
            display: inline-block;
            vertical-align: middle;
          }
        }

        .white_tag {
          background-color: #fff;
          border: 1px solid var(--grey1);
          color: var(--grey1);
          box-sizing: border-box;
          padding: 4px 10px;
          display: inline-block;
          vertical-align: middle;
          border-radius: 4px;
          margin-left: 10px;
          cursor: pointer;
          float: right;
        }
        input[type="text"] {
          &.long_input {
            width: 84%;
            display: inline-block;
            margin-bottom: 10px;
          }
        }
        .tag {
          &.main_tag {
            display: inline-block;
            vertical-align: top;
            color: var(--main);
            border: 1px solid var(--main);
            border-radius: 4px;
            padding: 8px 14px;
            cursor: pointer;
            height: 36px;
            line-height: 140%;
            box-sizing: border-box;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .btns {
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    button {
      padding: 8px 14px;
      width: auto;
      &:nth-child(1) {
        margin-right: 10px !important;
      }
      &.main2 {
        float: right;
        margin-right: 0 !important;
      }
    }
  }
`;

// WhiteRowTable
export const WhiteRowTable = styled.table`
  text-align: left;
  font-size: 14px;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  tbody {
    width: 100%;
    display: block;
    margin-bottom: 30px;
    tr {
      td {
        padding: 12px 20px;
        vertical-align: top;
        &:nth-child(1) {
          width: 140px;
          display: block;
        }
        .manager_text {
          margin-top: 6px;
          color: var(--grey2);
          font-size: 12px;
        }
        &.wide {
          width: 100%;
          input[type="text"] {
            width: 100%;
          }
        }
        .brand_select {
          height: 36px;
          line-height: 140%;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 6px;
          &.tag {
            display: block;
            margin: 0 5px;
            display: inline-block;
            background-color: var(--grey5);
            color: var(--grey3);
            font-size: 12px;
            padding: 4px 10px;
            border-radius: 4px;
            margin: 0;
            line-height: 140%;
            &.main_tag {
              background-color: var(--main);
              color: var(--white);
            }
            &.pay_okay {
              background-color: var(--grey5);
              color: var(--text);
            }
            &.info_tag {
              float: right;
              background-color: transparent;
              border: 1px solid var(--grey4);
              color: var(--text);
              cursor: pointer;
            }
          }
        }
        input[type="radio"] {
          display: inline-block;
          width: 100px;
          opacity: 1;
          margin-right: 8px;
          accent-color: var(--grey1);
          position: static;
          vertical-align: middle;
        }
        input[type="radio" i] {
          width: 20px;
          height: 20px;
          margin-top: 0;
        }
        input[type="radio"]:checked {
          background-color: var(--grey1);
          color: var(--white);
        }
        label {
          cursor: pointer;
          margin-right: 20px;
          cursor: pointer;
          vertical-align: middle;
        }
        input[type="text"],
        input[type="number"] {
          width: 302px;
          height: 36px;
          font-size: 14px;
          padding: 8px 12px;
          display: inline-block;
          vertical-align: middle;
          &.mid {
            width: 50%;
            margin-left: 6px;
            input[type="text"] {
              width: 100%;
            }
          }
        }
        h6 {
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          color: var(--grey2);
          margin-top: 6px;
        }
        input[type="date"] {
          width: 330px;
          display: inline-block;
          position: relative;
          ::-webkit-datetime-edit-fields-wrapper {
            padding-left: 28px;
            position: relative;
          }
          // placeholder
          ::before {
            /* content: attr(data-placeholder); */
            content: attr(data-placeholder);
            width: 100%;
            color: var(--grey3);
            padding-left: 30px;
          }
          // Rightarrow
          ::after {
            background: url(${Rightarrow});
            content: "";
            display: block;
            z-index: 9;
            position: absolute;
            right: 12px;
            width: 20px;
            height: 20px;
            transform: rotate(90deg);
          }
          ::-webkit-calendar-picker-indicator {
            background: url(${Calendar});
            background-repeat: no-repeat;
            position: absolute;
            left: 12px;
            content: "";
            width: 100%;
            height: 20px;
            margin-top: 4px;
            display: block;
            cursor: pointer;
          }
        }
        input[type="date"]:focus:before,
        input[type="date"]:valid:before {
          display: none;
        }
        select {
          display: inline-block;
          width: 302px;
          height: 36px;
          cursor: pointer;
          padding-left: 12px;
          border-radius: 4px;
          :required:invalid {
            color: var(--grey3);
          }
          option[value=""][disabled] {
            display: none;
          }
        }

        .tagbox {
          margin-top: 18px;
          span {
            margin-right: 8px;
            img {
              display: inline-block;
              width: 16px;
              height: 16px;
              vertical-align: middle;
              margin-left: 4px;
              cursor: pointer;
            }
          }
        }
        > div {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .plus {
          display: inline-block;
          width: 36px;
          height: 36px;
          vertical-align: middle;
          margin-left: 10px;
          cursor: pointer;
        }
        .white_tag {
          background-color: #fff;
          border: 1px solid var(--grey1);
          color: var(--grey1);
          box-sizing: border-box;
          padding: 4px 10px;
          display: inline-block;
          vertical-align: middle;
          border-radius: 4px;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      textarea {
        height: auto;
        border: 1px solid var(--grey4);
        border-radius: 4px;
        width: 100%;
        padding: 7px 12px;
        overflow: visible;
        resize: both;
        ::placeholder {
          color: var(--grey3);
          font-size: 14px;
        }
      }
    }
    .btns {
      button {
        width: auto !important;
        &:nth-child(1) {
          margin-right: 10px !important;
        }
      }
    }
  }
  .btns {
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    button {
      padding: 8px 14px;
      width: auto;
      &:nth-child(1) {
        margin-right: 10px !important;
      }
    }
  }
`;

// ColumTable
export const ColumTableContainer = styled.table`
  font-size: 14px;
  line-height: 1.29;
  text-align: center;
  width: 100%;
  vertical-align: top;
  margin-bottom: 10px;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  font-size: 14px;
  &:first-child {
    margin-left: 0px;
  }
  td {
    border-bottom: solid 1px var(--grey4);
    p {
      display: inline-block !important;
      vertical-align: middle;
    }
    .btns {
      margin-top: 0 !important;
      display: inline-block;
      vertical-align: middle;
      float: right;
    }
    .rightarrow2 {
      margin-top: 5px;
    }
  }
  tr {
    border-bottom: 1px solid #e4e4e4;
  }
  thead {
    width: 100%;
    background: #f7f7f7;
    color: #7d7d7d;
    font-size: 14px;
    line-height: 140%;
    tr {
      width: 100%;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      td {
        padding: 10px 20px;
        &.min {
          width: 5%;
        }
        &.mid1 {
          width: 15.3%;
        }
        &.mid2 {
          width: 22%;
        }
        &.mid3 {
          width: 35%;
        }
        &.wide {
          width: 36%;
        }
      }
    }
  }

  tbody {
    text-align: left;
    width: 100%;
    tr {
      cursor: pointer;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      &.cursor_none {
        cursor: inherit;
      }
      td {
        padding: 10px 20px;
        span {
          display: inline-block;
          vertical-align: center;
          border-radius: 100px;
          font-size: 12px;
          line-height: 140%;
          letter-spacing: -0.04em;
          padding: 4px 10px;
          float: left;
          margin-right: 10px;
          &.maintag {
            color: var(--main);
            background: #f0ebff;
          }
          &.greytag {
            background: #f7f7f7;
            color: #2e2e2e;
          }
        }
        p {
          height: 20px;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
          margin-top: 3px;
          line-height: 140%;
          &.text_all {
            text-overflow: inherit;
            overflow: auto;
            display: block;
            height: auto;
          }
        }
        img {
          width: 14px;
          height: 14px;
          margin-top: 4px;
          transform: rotate(-90deg);
          float: right;
        }
        button {
          display: inline-block;
          padding: 5.5px 10px;
          border-radius: 4px;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          &.reject_button {
            border: 1px solid #6c38ff;
            border-radius: 4px;
            margin-right: 10px;
            color: var(--main);
          }
          &.approve_button {
            background-color: var(--main);
            color: var(--white);
          }
        }
      }
    }
  }
`;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import List from "./List/CommentList";

// logics

const Comment = ({ loading, setLoading }) => {
  const location = useLocation();
  const [state, setState] = useState({
    current: "",
    location: "",
    type: "",
    id: "",
  });

  useEffect(() => {
    setState({
      ...state,
      current: location.pathname,
      location: location.pathname.split("/comment/")[1].split("/")[0],
      type: location.pathname.split("/comment/")[1].split("/")[1],
      id: location.pathname
        .split("/comment/")[1]
        .split(`/${location.pathname.split("/comment/")[1].split("/")[1]}/`)[1]
        .split("/")[0],
    });
  }, [location.pathname]);

  return (
    <>
      {state.type === "list" ? (
        <List
          loading={loading}
          setLoading={setLoading}
          current={state.current}
          location={state.location}
          type={state.type}
          status={state.status}
          id={state.id}
        />
      ) : null}
    </>
  );
};

export default Comment;

import { useRef, useState, useEffect } from "react";

import data from "./login.json";
import { managerApi } from "Utils/api/api";
import { setFormData } from "Utils/lib/helper";

const LoginLogic = () => {
  // 기본 state 관리
  const [state, setState] = useState({
    alert: null,
  });
  const [loading, setLoading] = useState(false);
  const ref_instance = { email: useRef(null), pw: useRef(null) };
  const [userInfo, setUserInfo] = useState({
    id: "",
    pw: "",
  });
  useEffect(() => {
    // localStorage.clear();
    sessionStorage.clear();
  }, []);
  const login = async () => {
    if (!userInfo.id) {
      alert(data.alert.none_id);
      return ref_instance.id.current.focus();
    }
    if (!userInfo.pw) {
      alert(data.alert.none_pw);
      return ref_instance.pw.current.focus();
    }
    setLoading(true);
    const json = {
      login_id: userInfo.id,
      password: userInfo.pw,
    };
    try {
      const {
        data: { data },
      } = await managerApi.login(json);
      localStorage.setItem("user_token", data.token);
      window.location.href = "/";
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
      setStateValue("alert", {
        title: error.response.data.message,
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  return { state, loading, ref_instance, userInfo, setUserInfo, login };
};

export default LoginLogic;

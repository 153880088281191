import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// json
import jsonData from "Assets/contents/contents.json";
import moment from "moment/moment";

// components
import { Alert } from "Components";

// styles
import {
  BasicButton,
  NewsBuilderSave,
  NewsLetterBuilderSave,
  NewsLetterTemplate,
  AddHeader,
} from "Styles/styles";

// img

import NewsBuilderCalendar from "Assets/images/icons/newsbuilder_save_calendar.svg";
import NewsBuilderPeople from "Assets/images/icons/newsbuilder_save_people.svg";
import NewsBuilderGrade from "Assets/images/icons/newsbuilder_save_grade.svg";
import Logo from "Assets/images/icons/newsbuilder_loco.svg";
import SimpleLogo from "Assets/images/icons/simple_logo.svg";
import Mail from "Assets/images/icons/newsbuilder_mail.svg";
import NewsLetterBuilderMail from "Assets/images/icons/newsletterbuilder_mail.svg";
import NewsLetterBuilderSymbol from "Assets/images/icons/newsletterbuilder_symbol.svg";
import WebsiteIcon from "Assets/images/icons/website_icon.svg";
import InstaIcon from "Assets/images/icons/insta_icon.svg";

// logics
import ContentDetailLogic from "./ContentDetail.logic";

const ContentDetail = ({
  loading,
  setLoading,
  current,
  location,
  type,
  status,
  id,
}) => {
  const navigate = useNavigate();
  const scrollRef = useRef(null);

  const { state, data, ref, func } = ContentDetailLogic({
    loading,
    setLoading,
    navigate,
    url: {
      current,
      location,
      type,
      status,
      id,
    },
    scrollRef,
  });
  return (
    <>
      <AddHeader>
        <div>
          <button onClick={() => navigate(-1)}>뒤로가기</button>
          <h3>
            {status === "news"
              ? "학생 뉴스"
              : status === "admin"
              ? "관리자 뉴스"
              : "학생 뉴스레터"}
            상세
          </h3>
          {status === "admin" ? (
            <div className="btns">
              <BasicButton
                onClick={() =>
                  func.setStateValue("alert", {
                    title: "뉴스를 삭제 하시겠습니까?",
                    sub: "삭제하시면 뉴스를 되돌릴 수 없습니다.",
                    isOk: () => func.deleteNews(),
                    isCancel: () => func.setStateValue("alert", null),
                  })
                }
              >
                삭제하기
              </BasicButton>
            </div>
          ) : null}
        </div>
      </AddHeader>
      {state.loading ? null : (
        <>
          {/* 발간된 어드민 뉴스빌더 */}
          {status === "admin" ? (
            state.detail_info.news !== [] ? (
              <NewsBuilderSave>
                <div id="news" ref={ref.ref}>
                  <ul className="tag_list">
                    {state.detail_info.news.first_categories ? (
                      <li>
                        {state.detail_info.news.first_categories.category_name}
                      </li>
                    ) : null}
                    {state.detail_info.news.second_categories ? (
                      <li>
                        {state.detail_info.news.second_categories.category_name}
                      </li>
                    ) : null}
                  </ul>
                  <h1>{state.detail_info.news.title}</h1>
                  <ul className="info">
                    <li>
                      <img src={NewsBuilderCalendar} alt="캘린더 아이콘" />
                      <p>
                        {moment(state.detail_info.news.published_at).format(
                          "YYYY.MM.DD."
                        )}
                      </p>
                    </li>
                    <li>
                      <img src={NewsBuilderPeople} alt="사용자 아이콘" />
                      <p>
                        {state.detail_info.news.users &&
                          state.detail_info.news.users.nick_name}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="tab">
                  <span
                    className={state.grade_type === "1" ? "on" : ""}
                    onClick={() => func.setStateValue("grade_type", "1")}
                  >
                    1학년
                  </span>
                  <span
                    className={state.grade_type === "2" ? "on" : ""}
                    onClick={() => func.setStateValue("grade_type", "2")}
                  >
                    2학년
                  </span>
                  <span
                    className={state.grade_type === "3" ? "on" : ""}
                    onClick={() => func.setStateValue("grade_type", "3")}
                  >
                    3 · 4학년
                  </span>
                  <span
                    className={state.grade_type === "5" ? "on" : ""}
                    onClick={() => func.setStateValue("grade_type", "5")}
                  >
                    5 · 6학년
                  </span>
                </div>

                {state.detail_info.news.contents.filter(
                  (item) => item.grade === state.grade_type
                ).length > 0 ? (
                  state.detail_info.news.contents
                    .filter((item) => item.grade === state.grade_type)
                    .map((item) => {
                      // 이미지 방식에 따라 className 줘서 구분
                      if (item.image_location === "right") {
                        return (
                          <div key={`content_${item.id}`} className="right">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.content.replaceAll("\n", "<br/>"),
                              }}
                            ></p>
                            {item.images && (
                              <img
                                src={item.images.image_url}
                                alt={`뉴스 이미지`}
                              />
                            )}
                          </div>
                        );
                      } else if (item.image_location === "left") {
                        return (
                          <div key={`content_${item.id}`} className="left">
                            {item.images && (
                              <img
                                src={item.images.image_url}
                                alt={`뉴스 이미지`}
                              />
                            )}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.content.replaceAll("\n", "<br/>"),
                              }}
                            ></p>
                          </div>
                        );
                      } else {
                        return (
                          <div key={`content_${item.id}`} className="center">
                            {item.images && (
                              <img
                                src={item.images.image_url}
                                alt={`뉴스 이미지`}
                              />
                            )}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.content.replaceAll("\n", "<br/>"),
                              }}
                            ></p>
                          </div>
                        );
                      }
                    })
                ) : (
                  <div>해당 학년 뉴스 내용이 없습니다.</div>
                )}
              </NewsBuilderSave>
            ) : null
          ) : null}
          {/* 발간된 뉴스빌더 */}
          {status === "news" ? (
            state.detail_info.news !== [] ? (
              <NewsBuilderSave>
                <div id="news" ref={ref.ref}>
                  <ul className="tag_list">
                    {state.detail_info.news.first_categories ? (
                      <li>
                        {state.detail_info.news.first_categories.category_name}
                      </li>
                    ) : null}
                    {state.detail_info.news.second_categories ? (
                      <li>
                        {state.detail_info.news.second_categories.category_name}
                      </li>
                    ) : null}
                  </ul>
                  <h1>{state.detail_info.news.title}</h1>
                  <ul className="info">
                    <li>
                      <img src={NewsBuilderCalendar} alt="캘린더 아이콘" />
                      <p>
                        {moment(state.detail_info.news.published_at).format(
                          "YYYY.MM.DD."
                        )}
                      </p>
                    </li>
                    <li>
                      <img src={NewsBuilderPeople} alt="사용자 아이콘" />
                      <p>
                        {state.detail_info.news.users &&
                          state.detail_info.news.users.nick_name}
                      </p>
                    </li>
                    <li>
                      <img src={NewsBuilderGrade} alt="학년 아이콘" />
                      <p>
                        {state.detail_info.news.contents &&
                          state.detail_info.news.contents[0].grade}
                        학년
                      </p>
                    </li>
                  </ul>
                  {state.detail_info.news.contents &&
                    state.detail_info.news.contents.map((item) => (
                      <>
                        <img
                          src={item.images.image_url}
                          alt="뉴스빌더 업로드 이미지"
                        />
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.content.replaceAll("\n", "<br/>"),
                          }}
                        ></p>
                      </>
                    ))}
                </div>
              </NewsBuilderSave>
            ) : null
          ) : null}
          {/* 발간된 뉴스레터 빌더 */}
          {status === "letter" && state.detail_info.letter !== [] ? (
            <NewsLetterBuilderSave>
              {state.detail_info.letter ? (
                <NewsLetterTemplate id="news" ref={ref.ref}>
                  <div>
                    <img
                      src={NewsLetterBuilderSymbol}
                      alt="뉴스레터 빌더 심볼"
                      className="newsletter_builder_symbol"
                    />
                    <div className="newsletter_builder_wrap">
                      <div className="header">
                        <img src={Logo} alt="뉴스레터탬플릿 로고" />
                        <div>
                          <img src={Mail} alt="메일아이콘" />
                          <span>
                            {state.detail_info.letter.year}년{" "}
                            {state.detail_info.letter.month}월호
                          </span>
                        </div>
                      </div>
                      <div className="title">
                        <img
                          src={NewsLetterBuilderMail}
                          alt="뉴스레터 빌더 메일 아이콘"
                        />
                        <h1>{state.detail_info.letter.title}</h1>
                        <div>
                          <img src={SimpleLogo} alt="심플 로고" />
                          <p>{state.detail_info.letter.users.nick_name} 기자</p>
                        </div>
                      </div>
                      {state.detail_info.contents &&
                      state.detail_info.contents.length > 0 ? (
                        state.detail_info.contents.map((item) => {
                          if (item.contents.image_location === "left") {
                            return (
                              <div
                                key={`content_${item.contents.id}`}
                                className="newsletter_special_component"
                              >
                                <div className="picture_component">
                                  <div>
                                    <span className="special_tag">
                                      스페셜 뉴스
                                    </span>
                                    <h2>{item.contents.title}</h2>
                                  </div>
                                  <div className="narrow_component">
                                    <div className="imgbox_narrow">
                                      <div className="imgbox_upload_done">
                                        {item.contents.images && (
                                          <img
                                            src={item.contents.images.image_url}
                                            alt={`${item.contents.title} 이미지`}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="text_box">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item.contents.content.replaceAll(
                                              "\n",
                                              "<br/>"
                                            ),
                                        }}
                                      ></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (item.contents.image_location === "right") {
                            return (
                              <div
                                key={`content_${item.contents.id}`}
                                className="newsletter_special_component"
                              >
                                <div className="picture_component">
                                  <div>
                                    <span className="special_tag">
                                      스페셜 뉴스
                                    </span>
                                    <h2>{item.contents.title}</h2>
                                  </div>
                                  <div className="narrow_component">
                                    <div className="text_box">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item.contents.content.replaceAll(
                                              "\n",
                                              "<br/>"
                                            ),
                                        }}
                                      ></p>
                                    </div>
                                    <div className="imgbox_narrow">
                                      <div className="imgbox_upload_done">
                                        {item.contents.images && (
                                          <img
                                            src={item.contents.images.image_url}
                                            alt={`${item.contents.title} 이미지`}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={`content_${item.contents.id}`}
                                className="newsletter_component"
                              >
                                <div className="imgbox_upload_done">
                                  {item.contents.images && (
                                    <img
                                      src={item.contents.images.image_url}
                                      alt={`${item.contents.title} 이미지`}
                                    />
                                  )}
                                </div>
                                <div className="news_bring">
                                  <h2>{item.contents.title}</h2>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.contents.content.replaceAll(
                                        "\n",
                                        "<br/>"
                                      ),
                                    }}
                                  ></p>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div>뉴스 내용이 없습니다.</div>
                      )}
                    </div>
                    <div className="newsletter_builder_footer">
                      <div>
                        <img src={Logo} alt="뉴스레터탬플릿 로고" />
                        <ul>
                          <li>
                            <img src={WebsiteIcon} alt="웹 사이트 아이콘" />
                            <p>https://herewigg.com/</p>
                          </li>
                          <li>
                            <img src={InstaIcon} alt="인스타 아이콘" />
                            <p>https://www.instagram.com/herewigg/</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </NewsLetterTemplate>
              ) : null}
            </NewsLetterBuilderSave>
          ) : null}
        </>
      )}
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
    </>
  );
};

export default ContentDetail;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// json
import jsonData from "Assets/contents/contents.json";

// Utils
import { setImageFromFile, checkFileSize } from "Utils/lib/helper";

// components
import {
  SectionNav,
  ColumnTable,
  Pager,
  Alert,
  Modal,
  MainNav,
} from "Components";

// img
import Picture_select from "Assets/images/icons/picture_select.svg";
import TrashIcon from "Assets/images/icons/ic_trash.svg";

// styles
import { BasicButton, Container, SearchBox, Table } from "Styles/styles";

// logics
import ContentListLogic from "./ContentList.logic";

const ContentList = ({ setLoading, current, location, type, status, id }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState({ open: false, type: "", id: "" });
  const { state, data, ref, func } = ContentListLogic({
    setLoading,
    modal,
    setModal,
    navigate,
    url: { current, location, type, status, id },
  });

  return (
    <>
      <MainNav />
      <Container>
        <h2>
          {location === "news"
            ? "뉴스 활동 관리"
            : location === "builder"
            ? "뉴스 빌더 관리"
            : "이미지 관리"}
        </h2>
        <SectionNav
          list={
            location === "news" ? jsonData.nav[1].contents[0].contents : null
          }
          status={status}
          isAdd={{
            state:
              location === "images"
                ? true
                : location === "news" && status === "admin"
                ? true
                : false,
            txt:
              location === "images"
                ? "사진추가"
                : location === "news" && status === "admin"
                ? "뉴스 작성하기"
                : "",
            location:
              location === "news" ? `content/${location}/add/0/0` : null,
            // active: () => func.setModalData(true, "picture_add"),
            active:
              location === "images"
                ? () => func.setModalData(true, "picture_add")
                : null,
          }}
          isSelectList={
            location === "news"
              ? status === "admin" || status === "studentnews"
                ? [
                    {
                      active: (value) =>
                        func.setStateValue("first", value.value),
                      placeholder: "분야 1 선택",
                      options: data.filterList.first_category,
                    },
                    {
                      active: (value) =>
                        func.setStateValue("second", value.value),
                      placeholder: "분야 2 선택",
                      options: data.filterList.second_category,
                    },
                  ]
                : null
              : [
                  {
                    active: (value) => func.setStateValue("type", value.value),
                    placeholder: "이미지타입",
                    options: data.filterList.check_image_list,
                  },
                ]
          }
          isSearch={{
            state: true,
            active: (value) => func.setStateValue("search_keyword", value),
            onkeyPress: () => func.searchList(),
            placeholder:
              location === "news"
                ? "뉴스 제목"
                : location === "builder"
                ? "뉴스 제목"
                : location === "images"
                ? "이미지 태그 검색"
                : "",
          }}
        />
        <section>
          {location === "news" ? (
            <ColumnTable
              thead={
                jsonData.nav[1].contents[0].contents.filter(
                  (con) => con.type === status
                )[0].table_head
              }
              tbody={
                status === "admin"
                  ? data.dataList.admin_news_list
                  : status === "studentnews"
                  ? data.dataList.news_list
                  : data.dataList.letter_list
              }
            />
          ) : null}

          {location === "images" ? <ul>{data.dataList.images_list}</ul> : null}
          <Pager
            page={data.currentPage}
            count={12}
            total={state.total}
            paging={func.paging}
          />
        </section>
      </Container>

      {/* 이미지관리 모달 */}
      {location === "images" && state.modal.open ? (
        <Modal
          width={655}
          minHeight={456}
          maxHeight={550}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>
              <div className="picture_modal">
                <h3>{state.modal.id ? "이미지 상세" : "사진 추가"}</h3>
                {data.inputData.image_src ? (
                  <ul>
                    <li>
                      사진
                      <br />
                      {state.modal.id ? "(수정불가)" : null}
                    </li>
                    <li
                      // id가 없을 시 호버 : 휴지통 이미지
                      className={!state.modal.id ? "id_none" : ""}
                      onClick={
                        !state.modal.id
                          ? () =>
                              func.setInputData({
                                ...data.inputData,
                                image_url: null,
                                image_src: null,
                              })
                          : null
                      }
                    >
                      <div className="add_img">
                        <img src={data.inputData.image_src} alt="" />
                        <span>
                          <img src={TrashIcon} alt="휴지통모양" />
                        </span>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>사진</li>
                    <li>
                      <input
                        type="file"
                        accept="image/jpeg, image/png"
                        className="hidden"
                        id="image_add"
                        onChange={({ target: { files } }) => {
                          if (files.length) {
                            if (checkFileSize(50, files[0].size)) {
                              setImageFromFile({
                                file: files[0],
                                setImageUrl: ({ result }) =>
                                  func.setInputData({
                                    ...data.inputData,
                                    image_url: files[0],
                                    image_src: result,
                                  }),
                              });
                            } else {
                              func.setStateValue("alert", {
                                title: "50MB 이하의 파일만 업로드 가능합니다.",
                                sub: null,
                                isOk: () => func.setStateValue("alert", null),
                                isCancel: null,
                              });
                              return;
                            }
                          }
                        }}
                      />
                      <label htmlFor="image_add" className="image_add">
                        <img
                          src={Picture_select}
                          alt="사진 선택"
                          className="picture_icon"
                        />
                        <p>사진 선택</p>
                      </label>
                    </li>
                  </ul>
                )}
                <ul>
                  <li>태그</li>
                  <li>
                    <input
                      type="text"
                      placeholder="태그 입력"
                      id="tag"
                      className={state.tag_err === "" ? "" : "red"}
                      ref={ref.tagRef}
                      onChange={({ target: { value } }) =>
                        func.setStateValue("tag", value)
                      }
                      onKeyPress={(e) => {
                        if (e.key !== "Enter") return;
                        if (e.shiftKey) return;
                        e.preventDefault();
                        func.setTags("add", e.target.value);
                      }}
                    />
                    <button onClick={() => func.setTags("add", state.tag)}>
                      추가
                    </button>
                    <div className="tagbox">
                      {data.inputData.tags.map((i, idx) => (
                        <span key={`keyword_${idx}`}>
                          {i}
                          <i
                            className="xi-close"
                            onClick={() => func.setTags("remove", i)}
                          />
                        </span>
                      ))}
                    </div>
                    <p>
                      (권장 5개 제한) 입력란에 원하는 태그를 입력 후
                      &#60;추가&#62; 버튼을 누르거나 엔터로 추가할 수 있어요.
                      ex) 과학, 시사, 주제를 나타내는 단어 등
                    </p>
                    <p className="tag_err">{state.tag_err}</p>
                  </li>
                </ul>
                <ul>
                  <li>사용범위</li>
                  <li>
                    <input
                      type="checkbox"
                      id="type_admin"
                      defaultChecked={data.inputData.type}
                      onChange={() =>
                        func.setInputStateValue("type", !data.inputData.type)
                      }
                    />
                    <label htmlFor="type_admin" className="admin_check">
                      관리자용
                    </label>
                  </li>
                </ul>
                <div className="btn">
                  {state.modal.type === "picture_detail" ? (
                    <BasicButton
                      className="grey1"
                      onClick={() => func.setData("image_delete")}
                    >
                      삭제
                    </BasicButton>
                  ) : null}
                  <BasicButton
                    className="main2"
                    onClick={() =>
                      func.setData(
                        state.modal.type === "picture_detail"
                          ? "image_edit"
                          : "image_add"
                      )
                    }
                  >
                    {state.modal.type === "picture_detail" ? "저장" : "생성"}
                  </BasicButton>
                </div>
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}

      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
    </>
  );
};

export default ContentList;

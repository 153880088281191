import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import List from "./List/ContentList";
import DetailData from "./Detail/ContentDetail";
import AddData from "./Add/ContentAdd";

// logics

const Content = ({ loading, setLoading }) => {
  const location = useLocation();
  const [state, setState] = useState({
    current: "",
    type: "",
  });
  useEffect(() => {
    setState({
      ...state,
      current: location.pathname,
      location: location.pathname.split("/content/")[1].split("/")[0],
      type: location.pathname.split("/content/")[1].split("/")[1],
      status: location.pathname
        .split("/content/")[1]
        .split(`/${location.pathname.split("/content/")[1].split("/")[1]}/`)[1]
        .split("/")[0],
      id: location.pathname
        .split("/content/")[1]
        .split(`/${location.pathname.split("/content/")[1].split("/")[1]}/`)[1]
        .split("/")[1],
    });
  }, [location.pathname]);
  return (
    <>
      {state.type === "list" ? (
        <List
          current={state.current}
          location={state.location}
          type={state.type}
          status={state.status}
          id={state.id}
          setLoading={setLoading}
        />
      ) : null}
      {state.type === "detail" ? (
        <DetailData
          current={state.current}
          location={state.location}
          type={state.type}
          status={state.status}
          id={state.id}
          setLoading={setLoading}
        />
      ) : null}
      {state.type === "add" || state.type === "edit" ? (
        <AddData
          current={state.current}
          location={state.location}
          type={state.type}
          status={state.status}
          id={state.id}
          setLoading={setLoading}
        />
      ) : null}
    </>
  );
};

export default Content;

import React, { useState } from "react";
import styled from "styled-components";
import { ListTable } from "Components";
import { BasicButton } from "Styles/styles";

const Section = ({ data, add, active, check, check_all, active_data }) => {
  // const [selected, setSelected] = useState(false);
  return (
    <Container>
      <h2>{data.type} 목록</h2>
      <List>
        <h2>{data.total} 개</h2>
        {data.filter ? data.filter : null}
        {add ? (
          <BasicButton className="add" onClick={add}>
            + {data.type} 추가
          </BasicButton>
        ) : null}

        <ListTable
          data={data}
          check={check}
          check_all={check_all}
          active={active}
          active_data={active_data}
        />
      </List>
    </Container>
  );
};
const Container = styled.div`
  > h2 {
    margin-top: 30px;
  }
`;
const List = styled.div`
  margin-top: 10px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  padding: 27px 29px;
  position: relative;
  > button {
    position: absolute;
    top: 24px;
    right: 29px;
  }
  table {
    margin-top: 18px;
    td:first-child {
      /* width: 65px; */
    }
  }
  .filter {
    position: absolute;
    top: 20px;
    right: 30px;
    vertical-align: top;
    > div {
      height: 35px;
      margin-left: 8px;
      vertical-align: top;
      /* {
        font-size: 14px;
        height: 35px;
        border-color: #e0e0e0 !important;
      } */
      .css-g1d714-ValueContainer,
      .css-tlfecz-indicatorContainer,
      .css-1gtu0rj-indicatorContainer,
      .css-1HB7ZXY-IndicatorContainer {
        padding: 5px 6px;
        font-size: 14px;
      }
      .css-yk16xz-control,
      .css-1pahdxg-control {
        min-height: 35px;
        border: solid 1px #e0e0e0;
        border-color: #e0e0e0 !important;
        border-radius: 0;
        &:hover {
          border-color: #afafaf;
        }
      }
      .css-1pahdxg-control {
      }
      div {
        border-radius: 0;
      }
      width: 135px;
      display: inline-block;
      border-radius: 0;
      &.count {
        width: 100px;
      }
      input {
        width: 100%;
        padding: 5px;
      }
    }
    > span {
      margin-left: 10px;
    }
    > button {
      margin-left: 10px;
    }
  }
`;

export default Section;

// 기본 적용 스타일 코드입니다.
import { createGlobalStyle } from "styled-components";
// icon
import arrowDown from "Assets/images/icons/arrow_down_black.png";

// 사용되는 폰트입니다.
import SpoqaHanSansNeoBold from "Assets/fonts/SpoqaHanSansNeo-Bold.ttf";
import SpoqaHanSansNeoMedium from "Assets/fonts/SpoqaHanSansNeo-Medium.ttf";
import SpoqaHanSansNeoRegular from "Assets/fonts/SpoqaHanSansNeo-Regular.ttf";
import SpoqaHanSansNeoLight from "Assets/fonts/SpoqaHanSansNeo-Light.ttf";
import SpoqaHanSansNeoThin from "Assets/fonts/SpoqaHanSansNeo-Thin.ttf";

const globalStyle = createGlobalStyle`
   :root {
      --main: #6C38FF;
      --text: #2E2E2E;
      --black: #000000;
      --grey1: #3B3B3B;
      --grey2: #7D7D7D;
      --grey3:#bababa;
      --grey4: #E4E4E4;
      --grey5:#f6f6f6;
      --grey6:#FCFCFC;
      --white: #ffffff;
      --red: #F9665C ;
   }

   /* SpoqaHanSansNeo font */
   @font-face {
     font-family: 'Spoqa Han Sans Neo', 'sans-serif';
      src: url(${SpoqaHanSansNeoBold}) format('opentype');
      font-weight: 600;
      font-style: bold;
   }
   @font-face {
     font-family: 'Spoqa Han Sans Neo', 'sans-serif';
      src: url(${SpoqaHanSansNeoMedium}) format('opentype');
      font-weight: 500;
      font-style: normal;
   }
   @font-face {
      font-family: 'Spoqa Han Sans Neo', 'sans-serif';
       src: url(${SpoqaHanSansNeoRegular}) format('opentype');
       font-weight: 400;
       font-style: normal;
    }
   @font-face {
     font-family: 'Spoqa Han Sans Neo', 'sans-serif';
      src: url(${SpoqaHanSansNeoLight}) format('opentype');
      font-weight: 300;
      font-style: normal;
   }
   @font-face {
     font-family: 'Spoqa Han Sans Neo', 'sans-serif';
      src: url(${SpoqaHanSansNeoThin}) format('opentype');
      font-weight: 200;
      font-style: normal;
   }

   * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      line-height: 1;
   }
   html {
      font-size: 16px;
   }
   body {
      font-family: 'Spoqa Han Sans Neo', sans-serif;
      -webkit-touch-callout: none;
      -webkit-user-select: text;
      -khtml-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
      letter-spacing: -0.04em;
   }
   #root {
      position: relative;
      width: 100%;
      /* height: 100vh; */
      background: var(--background);
      /* overflow: hidden; */
   }
   // 기본 셋팅 부분
   a {
      text-decoration: none;
      color: inherit;
   }
   img {
      width: 100%;
   }
   ul, ol {
      list-style-type: none;
   }
   input,
   select,
   textarea {
      font-family: inherit;
      color: inherit;
      font-size: inherit;
      outline: none;
      resize: none;
      background-color: transparent;
      border-color:transparent;
      &::placeholder {
         color:#bababa;
      }
   }
  
   input[type="number"] {
      -moz-appearance: textfield;
   }
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
   input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      :checked ~ .check {
         background-color: var(--main);
         &::after {
            display: block;
         }
      }
   }
   select {
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      border: 1px solid #e0e0e0;
      width: 300px;
      height: 38px;
      padding: 0 5px;
      background: url(${arrowDown}) calc(100% - 5px) center no-repeat;
      background-size: 20px;
   
   }
   // 기본 버튼 설정
   button {
      font-family: inherit;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      /* &:active,&:hover{
         opacity: 0.8;
      }        */

   }
   i {
      color: var(--black);
      }
   // 글씨 크기
   h1{
      font-size: 28px;
   }
   h2{
      font-size: 20px;
   }
   h3{
      font-size: 16px;
   }
   h4{
      font-size: 14px;
      display: inline-block;
      vertical-align: middle;
   }
   h5{
      font-size: 12px;
   }
   p{
      font-size: 14px;
   }
   input{
      width: 100%;
      display: block;
   }
   textarea {
      line-height: 140%;
   }

   // 자주 쓰는 class
   .cursor {
      cursor: pointer;
   }
   .hidden {
      width:0px!important;
      height: 0px!important;
      overflow: hidden!important;
      opacity:0!important;
      visibility:hidden!important;
      display:none!important;
  }
   table {
      white-space:pre;
      tr,td,th{
         line-height: 140%;
         white-space: pre-line;
         word-break: keep-all;
      }
   }
   .cursor{
      cursor: pointer;
   }
   
      // CKEditor
      .ck-editor__editable { 
      min-height: 100px; 
      max-height: 1000px;
      border:none;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #7f7f7f;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0.6;
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: #f3f3f3;
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
      }
   }
   .ck .ck-placeholder {
      margin-top:30px !important;
   }
   .ck .ck-placeholder:before {
      color:var(--grey3);
      font-size: 15px;
   }
   .ck.ck-toolbar{
      border: 1px solid #E4E4E4;
      border-left: none;
      border-right: none;
      background-color: var(--white);
   }
   .ckck-content{
      border: 1px solid #E4E4E4;
      border-left: none;
      border-right: none;
   }
   .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
      border: 1px solid #E4E4E4;
      border-left: none;
      border-right: none;

   }
   .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable){
      border: 1px solid #E4E4E4;
      border-left: none;
      border-right: none;

      box-shadow: none;
   }

   
`;

export default globalStyle;

import React from "react";
import { View, Main } from "./Modal.style";

const Modal = ({
  title,
  setmodal,
  data,
  width,
  borderRadius,
  sub,
  height,
  minHeight,
  maxHeight,
}) => {
  return (
    <View>
      <Main
        modalWidth={width}
        modalHeight={height}
        modalBorderRadius={borderRadius}
        modalMinheight={minHeight}
        modalMaxheight={maxHeight}
      >
        <h4>{title}</h4>
        {/* <h5>{sub}</h5> */}
        <button className="closebtn" onClick={() => setmodal()}>
          <i className="xi-close"></i>
        </button>
        {data}
      </Main>
    </View>
  );
};

export default Modal;

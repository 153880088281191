import React from "react";

import LoginLogic from "./Login.logic";
import LoginSt from "./Login.style";
import data from "./login.json";
import Loading from "Components/Loading/Loading";
import Logo from "Assets/images/icons/logo.svg";
import { Alert } from "Components";

const Login = ({ loading, setLoading }) => {
  const { state, func, ref_instance, userInfo, setUserInfo, login } =
    LoginLogic();
  return (
    <>
      {loading && <Loading loading={loading} />}
      <LoginSt>
        <div className="form">
          <h1>
            <img src={Logo} alt={data.title.main} />
            {data.title.main}
          </h1>
          <div>
            <h3>{data.sub.id}</h3>
            <input
              type="text"
              ref={ref_instance.id}
              placeholder={data.placeholder.id}
              onChange={({ target: { value: id } }) =>
                setUserInfo({ ...userInfo, id })
              }
              onKeyPress={({ key }) => key === "Enter" && login()}
            />
          </div>
          <div>
            <h3>{data.sub.pw}</h3>
            <input
              type="password"
              ref={ref_instance.pw}
              placeholder={data.placeholder.pw}
              onChange={({ target: { value: pw } }) =>
                setUserInfo({ ...userInfo, pw })
              }
              onKeyPress={({ key }) => key === "Enter" && login()}
            />
          </div>

          <button onClick={() => login()}>로그인_</button>
        </div>
      </LoginSt>
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
    </>
  );
};

export default Login;

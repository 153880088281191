import styled from "styled-components";

export const NavContainer = styled.div`
  width: 100%;
  position: relative;
  > div {
    margin-top: 18px;
  }
`;
export const Nav = styled.ul`
  width: 100%;
  position: relative;
  line-height: 140%;
  display: block;
  li {
    vertical-align: top;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    padding: 10px;
    color: var(--grey3);
    margin-right: 16px;
    margin-bottom: 18px;
    line-height: 140%;
    &.on,
    &:hover {
      font-weight: 700;
      color: var(--text);
      border-bottom: solid 2px var(--text);
    }
    > button {
      position: absolute;
      right: 10px;
      top: 40%;
      transform: translateY(-50%);
    }
    div {
      display: flex;
    }
  }

  &.selected {
    width: calc(100% - 603px);
  }

  // 등록
  .nav_list {
    button {
      float: right;
      padding: 8px 14px;
      background-color: var(--main);
      color: var(--white);
    }
    > div {
      display: inline-block;
      vertical-align: top;
      &.checkbox_filter {
        vertical-align: sub;
        margin-top: 7px;
        input[type="checkbox"] {
          vertical-align: sub;
        }
      }
    }
  }

  // 체크선택 필터
  .checkbox_filter {
    div {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .searchbox_blank {
    margin-top: 10px;
  }
`;

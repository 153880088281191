import React, { useState, useEffect, useRef } from "react";
import moment from "moment/moment";

// api
import { contentApi, utilApi } from "Utils/api/api";

//lib
import { scrollTop, setFormData } from "Utils/lib/helper";

// img
import ArrowSelect from "Assets/images/icons/arrow_select.svg";

const ContentListLogic = ({ loading, setLoading, modal, navigate, url }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    data_list: [],
    first: null,
    second: null,
    is_main: null,
    grade: [],
    search_keyword: "",
    modal: { open: false, type: "", id: "" },
    search_date: null,
    alert: null,
    type: false,
    tag: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const dateRef = useRef(null);
  const tagRef = useRef(null);

  const [inputData, setInputData] = useState({
    image_url: null,
    image_src: null,
    tags: [],
    type: false,
  });
  const [filterList, setFilterList] = useState({
    first_category: [],
    second_category: [],
    check_image_list: [
      {
        value: "all",
        label: "전체",
      },
      {
        value: "admin",
        label: "관리자용",
      },
    ],
    check_admin_list: [
      {
        active: () => setStateValue("is_main", true),
        label: "메인",
        state: state.is_main ? true : false,
      },
      {
        active: () => setStateValue("is_main", false),
        label: "연관",
        state: state.is_main ? false : true,
      },
    ],
  });
  useEffect(() => {
    getList(0);
  }, [
    setLoading,
    url.status,
    state.modal,
    state.first,
    state.second,
    state.is_main,
    state.type,
  ]);
  useEffect(() => {
    getList(currentPage - 1);
  }, [currentPage]);

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    if (state.search_keyword === "") {
      getList(0);
      scrollTop();
    }
  }, [state.search_keyword, currentPage]);
  useEffect(() => {
    if (state.search_keyword === "") {
      getList(0);
      scrollTop();
    }
  }, [state.modal]);

  // 데이터 list 관리
  const dataList = {
    images_list:
      state.data_list.length > 0 && url.location === "images" ? (
        state.data_list.map((item, idx) => {
          return (
            <li key={`images_${idx}`}>
              <img
                src={item.image_url}
                alt="이미지관리 이미지"
                onClick={() => setModalData(true, "picture_detail", item.id)}
              />
              <span className="number">{(currentPage - 1) * 12 + idx + 1}</span>
              {item.type === "admin" ? (
                <span className="admin_tag">관리자용</span>
              ) : null}

              <div className="tagbox">
                {item.image_keywords &&
                  item.image_keywords.map((i) => (
                    <span key={i.id}>{i.keywords.keyword}</span>
                  ))}
              </div>
            </li>
          );
        })
      ) : (
        <li>이미지가 없습니다.</li>
      ),
    admin_news_list:
      state.data_list.length > 0 && url.location === "news" ? (
        state.data_list.map((item, idx) => {
          return (
            <tr
              key={`news_${item.id}`}
              onClick={() => navigate(`/content/news/detail/admin/${item.id}`)}
            >
              <td>
                {item.is_main ? (
                  <span className="maintag">메인</span>
                ) : (
                  <span className="greytag">연관</span>
                )}
                <p>{item.title}</p>
              </td>
              <td>
                {item.first_categories
                  ? `${item.first_categories.category_name}, `
                  : ""}
                {item.second_categories
                  ? `${item.second_categories.category_name}`
                  : ""}
              </td>
              <td>{moment(item.created_at).format("YYYY.MM.DD")}</td>
              <td>{item.like_count}</td>
              <td>
                {item.view_count} <img src={ArrowSelect} alt="오른쪽화살표" />
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={5}>뉴스가 없습니다.</td>
        </tr>
      ),
    news_list:
      state.data_list.length > 0 && url.location === "news" ? (
        state.data_list.map((item, idx) => {
          return (
            <tr
              key={`news_${item.id}`}
              onClick={() => navigate(`/content/news/detail/news/${item.id}`)}
            >
              <td className="mid3">
                <p>{item.title}</p>
              </td>
              <td className="mid1">
                {item.first_categories
                  ? `${item.first_categories.category_name}, `
                  : ""}
                {item.second_categories
                  ? `${item.second_categories.category_name}`
                  : ""}
              </td>
              <td className="mid1">{item.users ? item.users.name : ""}</td>
              <td className="mid1">
                {item.contents ? `${item.contents[0].grade}학년` : ""}
              </td>
              <td className="mid1">
                {moment(item.created_at).format("YYYY.MM.DD")}{" "}
                <img src={ArrowSelect} alt="오른쪽화살표" />
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={5}>뉴스가 없습니다.</td>
        </tr>
      ),
    letter_list:
      state.data_list.length > 0 && url.location === "news" ? (
        state.data_list.map((item, idx) => {
          return (
            <tr
              key={`news_${item.id}`}
              onClick={() => navigate(`/content/news/detail/letter/${item.id}`)}
            >
              <td className="mid3">
                <p>{item.title}</p>
              </td>
              <td className="mid1">{item.users ? item.users.name : ""}</td>
              <td className="mid1">{item.grade}학년</td>
              <td className="mid1">
                {moment(item.created_at).format("YYYY.MM.DD")}
                <img src={ArrowSelect} alt="오른쪽화살표" />
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={4}>뉴스가 없습니다.</td>
        </tr>
      ),
  };

  ////////////////  API  ////////////////
  // 카테고리 조회
  const getCategoryList = async () => {
    try {
      const {
        data: { data: first },
      } = await utilApi.getNewsSupportList({
        type: "first",
      });
      const {
        data: { data: second },
      } = await utilApi.getNewsSupportList({
        type: "second",
      });
      const firstArr = first.first_category.map((i) => {
        return {
          value: i.id,
          label: i.category_name,
        };
      });
      firstArr.unshift({ value: null, label: "분야 1 선택" });
      const secondArr = second.second_category.map((i) => {
        return {
          value: i.id,
          label: i.category_name,
        };
      });
      secondArr.unshift({ value: null, label: "분야 2 선택" });
      setFilterList({
        ...filterList,
        first_category: firstArr,
        second_category: secondArr,
      });
    } catch (error) {
      console.log(error);
      // alert(res.data.message);
    } finally {
      setLoading(false);
    }
  };
  // 목록 조회
  const getList = async (page) => {
    if (url.location === "news") {
      let type =
        url.status === "studentnews"
          ? "news"
          : url.status === "studentletter"
          ? "letters"
          : "admin";
      setLoading(true);
      try {
        const { data } = await contentApi.getNewsList({
          type: type,
          first: state.first,
          second: state.second,
          search: state.search_keyword,
          is_main: state.is_main,
          grade: state.grade.toString(),
          page: page,
        });
        setState({
          ...state,
          data_list: data.data[type === "letters" ? "letters" : "news"],
          total: data.data.total,
          alert: null,
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else if (url.location === "images") {
      setLoading(true);
      try {
        const { data } = await contentApi.getImageList({
          search: state.search_keyword,
          type: state.type,
          num: 12,
          page: currentPage - 1,
        });
        setState({
          ...state,
          data_list: data.data.image_list,
          total: data.data.total,
          alert: null,
        });
        console.log(data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  // 상세 조회
  const getDetail = async (id) => {};

  // 이미지 풀 데이터 등록
  const setData = async (type) => {
    if (type === "image_add") {
      const form = setFormData({
        image: inputData.image_url,
        tags: inputData.tags.toString(),
        type: inputData.type ? "admin" : "all",
      });
      try {
        const { data } = await contentApi.addImage(form);
        setModalData(false, null, null);
      } catch (error) {
        console.log(error);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else if (type === "image_edit") {
      const json = {
        tags: inputData.tags.toString(),
        type: inputData.type ? "admin" : "all",
      };
      console.log(type, state.modal.id, inputData);
      try {
        const { data } = await contentApi.editImage(
          { id: state.modal.id },
          json
        );
        console.log(json);
        setModalData(false, null, null);
      } catch (error) {
        console.log(error);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    } else if (type === "image_delete") {
      try {
        const { data } = await contentApi.deleteImage({ id: state.modal.id });
        setModalData(false, null, null);
      } catch (error) {
        console.log(error);
        setStateValue("alert", {
          title: error.response.data.message,
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // keyword로 검색 결과 셋팅
  const searchList = () => {
    if (state.search_keyword === "" || state.search_keyword.length < 2) {
      alert("키워드는 두글자 이상으로 검색 바랍니다.");
      return;
    } else {
      setCurrentPage(1);
      getList();
    }
  };

  // state 셋팅
  const setStateValue = (key, value) => {
    if (key === "tags") {
      setState({ ...state, tag: value, tag_err: "" });
    } else if (key === "grade") {
      if (state.grade.filter((i) => i === value).length > 0) {
        setState({
          ...state,
          grade: state.grade.filter((i) => i !== value),
        });
      } else {
        state.grade.push(value);
      }
      getList(0);
    } else {
      setState({ ...state, [key]: value });
    }
    console.log(state.type, key, value);
  };
  // state input 셋팅
  const setInputStateValue = (key, value) => {
    setInputData({ ...inputData, [key]: value });
  };
  // 태그 추가 삭제 기능
  const setTags = (type, value) => {
    if (type === "add") {
      if (value !== "") {
        if (inputData.tags.length > 4) {
          setStateValue(
            "tag_err",
            "태그는 5개 이상 등록 하실 수 없습니다. 등록된 태그를 삭제 후 추가 바랍니다."
          );
        } else {
          if (inputData.tags.filter((i) => i === value).length > 0) {
            //  중복된 태그시
            setStateValue("tag_err", "중복된 태그는 추가할 수 없습니다.");
          } else {
            setInputData({
              ...inputData,
              tags: inputData.tags.concat(value),
            });
            setState({ ...state, tag: "" });
          }
          tagRef.current.focus();
          document.querySelector("#tag").value = "";
        }
      }
    } else {
      setInputData({
        ...inputData,
        tags: inputData.tags.filter((i) => i !== value),
      });
    }
  };
  // state input reset 셋팅
  const setInputReset = () => {
    setInputData({
      ...inputData,
      image_url: null,
      image_src: null,
      tags: [],
      type: false,
    });
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    if (!open) {
      setInputReset();
    }
    if (id) {
      setInputData({
        ...inputData,
        image_url: state.data_list.filter((i) => i.id === id)[0].image_url,
        image_src: state.data_list.filter((i) => i.id === id)[0].image_url,
        tags: state.data_list
          .filter((i) => i.id === id)[0]
          .image_keywords.map((i) => {
            return i.keywords.keyword;
          }),
        type:
          state.data_list.filter((i) => i.id === id)[0].type === "admin"
            ? true
            : false,
      });
    }
    setState({ ...state, modal: { open: open, type: type, id: id } });
    // type : info /cancel
  };

  // 페이징처리
  const paging = ({ target: { value } }) => {
    setCurrentPage(value);
  };
  return {
    state,
    data: { dataList, inputData, currentPage, filterList },
    ref: { dateRef, tagRef },
    func: {
      setStateValue,
      setModalData,
      paging,
      searchList,
      setTags,
      setInputStateValue,
      setInputData,
      setData,
    },
  };
};

export default ContentListLogic;

import styled from "styled-components";
import People from "Assets/images/icons/people.svg";
import Stroke1 from "Assets/images/icons/Stroke1.svg";

export const Top = styled.header`
  z-index: 9;
  width: 100%;
  height: 40px;
  background-color: #000;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  > ul {
    /* padding: 15px 25px; */
    position: relative;
    li {
      margin-left: 20px;
      line-height: 40px;
      &.hidden {
        display: none;
      }
      img {
        width: 48px;
        height: 20px;
        vertical-align: middle;
        cursor: pointer;
      }
      h3 {
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
        color: var(--sub);
        color: #fff;
        font-size: 13px;
      }
    }
  }
  > div {
    position: absolute;
    right: 22.67px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    .people {
      background: url(${People});
      width: 24px;
      height: 24px;
      display: inline-block;
      background-size: cover;
      vertical-align: middle;
      margin-right: 6px;
    }
    .bar {
      background: url(${Stroke1}) no-repeat;
      width: 16px;
      height: 16px;
      display: inline-block;
      background-size: cover;
      vertical-align: middle;
    }
    > div {
      background-repeat: no-repeat;
      position: absolute;
      top: 36px;
      right: 0px;
      width: 110px;
      z-index: 3;
      background-color: var(--white);
      border: 1px solid var(--grey4);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      box-sizing: border-box;
      height: 36px;
      ul {
        height: 36px;
        li {
          cursor: pointer;
          height: 36px;
          font-size: 14px;
          line-height: 36px;
          padding-left: 13px;
          color: var(--text);
        }
      }
      &.hidden {
        display: none;
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Header } from "./MainNavStyle";
import contents from "Assets/contents/contents.json";
import arrowBtn from "Assets/images/icons/arrow_down.png";

const MainNav = ({ setOnoff }) => {
  const [state, setState] = useState({
    current: "",
    menu_open: null,
  });
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setState({
      ...state,
      current: location.pathname,
    });
  }, [location.pathname]);
  const setMenuOnOff = (idx) => {
    if (state.menu_open === idx) {
      setState({ ...state, menu_open: null });
    } else {
      setState({ ...state, menu_open: idx });
    }
  };
  return (
    <>
      {window.location.pathname.includes("news/detail/") ||
      window.location.pathname.includes("news/add/") ? null : (
        <Header>
          <ul>
            {contents.nav.map((item, index) => {
              let IconIdx = `nav_0${index + 1}.svg`;
              return (
                <li
                  key={`nav_${index}`}
                  className={state.current.includes(item.path_name) ? "on" : ""}
                >
                  <img src={require(`../../Assets/images/icons/${IconIdx}`)} />
                  <h3
                    onClick={
                      item.type === "on/off"
                        ? () => setOnoff()
                        : item.contents_depth > 1
                        ? () => setMenuOnOff(index)
                        : () => {
                            navigate(item.path);
                          }
                    }
                  >
                    {item.title}
                  </h3>
                  {item.contents_depth > 1 ? (
                    <button className={state.menu_open === index ? "on" : ""}>
                      <img src={arrowBtn} alt="" />
                    </button>
                  ) : null}
                  {item.contents_depth > 1 ? (
                    <ul className={state.menu_open === index ? "on" : ""}>
                      {item.contents.map((con, idx) => (
                        <li
                          key={`nav_content_${idx}`}
                          className={
                            state.current.includes(con.type) ? "on" : ""
                          }
                          onClick={() => {
                            navigate(con.path);
                          }}
                        >
                          {con.title}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </Header>
      )}
    </>
  );
};

export default MainNav;

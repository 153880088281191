import axios from "axios";
import jsonData from "Assets/contents/contents.json";

const api = axios.create({
  baseURL: jsonData.api_url,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.getItem("user_token")}`,
  },
});

// 로그인
export const managerApi = {
  // 로그인
  login: (json) => api.post("/user/login", json),
};

// 콘텐츠관리
export const contentApi = {
  //////////////////// 댓글 관리 ////////////////////
  // 댓글 관리 status: request, fail, success
  getCommentList: ({ status, search, page }) =>
    api.get(`/admin/comments`, {
      params: {
        status,
        search,
        page,
      },
    }),
  // 댓글 관리 status: request, fail, success
  setCommentStatus: ({ status, id }) =>
    api.patch(`/admin/comments/${status}/${id}`),
  //////////////////// 뉴스 활동 관리 ////////////////////
  // 뉴스 조회
  getNewsList: ({ type, first, second, search, is_main, grade, page }) =>
    api.get(`/admin/contents/`, {
      params: {
        type, //type: admin, news, letters
        first,
        second,
        search,
        is_main,
        grade, // 1,2,3,5
        page,
      },
    }),
  // 연관뉴스 조회
  getSupportsNewsList: ({ first, second, search, grade, page }) =>
    api.get(`/admin/contents/supports/news`, {
      params: {
        first,
        second,
        search,
        num: 10,
        page,
      },
    }),
  // 뉴스 상세 조회
  getAdminNewsDetail: ({ id, grade }) =>
    api.get(`/admin/contents/builder/${id}`, {
      params: {
        grade,
      },
    }),
  getNewsDetail: ({ id }) => api.get(`/lms/news/builder/${id}`),

  // 뉴스 레터 상세 조회
  getNewsLetterDetail: ({ id }) => api.get(`/lms/news/letter/builder/${id}`),
  // 관리자 뉴스 등록
  addAdminNews: (json) => api.post(`/admin/contents/builder`, json),
  // 관리자 뉴스 삭제
  deleteAdminNews: ({ id }) => api.delete(`/admin/contents/${id}`),

  //////////////////// 이미지 관리 ////////////////////
  // 이미지 목록조회  type - admin /all
  getImageList: ({ search, type, num, page }) =>
    api.get(`/admin/images`, {
      params: {
        search,
        type,
        num,
        page,
      },
    }),
  // 상세조회
  getImageDetail: ({ car_id }) => api.get(`/admin/car/list/${car_id}`),
  // 이미지 등록
  addImage: (form) =>
    api.post("/admin/images/create", form, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  
  
  addImagePixabay: (form) =>
    api.post("/admin/images/create-image-pixabay", form ),
  
  
  // 이미지 수정
  editImage: ({ id }, json) => api.patch(`/admin/images/${id}`, json),
  // 이미지 삭제
  deleteImage: ({ id }) => api.delete(`/admin/images/${id}`),
};

// 회원
export const memberApi = {};

// 구독
export const subscribeApi = {};

// 유틸
export const utilApi = {
  // 카테고리 불러오기, 이미지풀 목록 불러오기 type = image, first(첫번째 카테고리), second(두번째 카테고리)
  getNewsSupportList: ({ type, search, page }) =>
    api.get(`/lms/news/builder/supports/${type}`, {
      params: {
        search,
        page,
      },
    }),
};

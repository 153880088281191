import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";

// components
import { Alert, Modal, Pager } from "Components";

// select
import { selectStyles, DropdownIndicator } from "Styles/select.style";

// img
import Plus from "Assets/images/icons/plus_icon.svg";
import Picture_select from "Assets/images/icons/picture_select_grey.svg";
import Trash from "Assets/images/icons/trash_grey.svg";
import Close from "Assets/images/icons/xicon.svg";
import NewsBuilderChange from "Assets/images/icons/newsbuilder_picture_change_icon.svg";

// styles
import { AdminNews, BasicButton, AddHeader } from "Styles/styles";

// logics
import ContentAddLogic from "./ContentAdd.logic";

const ContentAdd = ({ loading, setLoading, current, location, type, id }) => {
  const navigate = useNavigate();
  const { state, contentOpen, data, func, ref } = ContentAddLogic({
    loading,
    setLoading,
    navigate,
    url: {
      current,
      location,
      type,
      id,
    },
  });
  return (
    <>
      <AddHeader>
        <div>
          <button onClick={() => navigate(-1)}>뒤로가기</button>
          <h3>관리자 뉴스 작성</h3>
          <div>
            <input
              type="checkbox"
              id="main_news"
              defaultChecked={data.inputData.is_main}
              onClick={() =>
                func.setInputStateValue("is_main", !data.inputData.is_main)
              }
            />
            <label htmlFor="main_news">메인 뉴스</label>
            <button
              onClick={() =>
                func.setStateValue("alert", {
                  title: "관리자 뉴스를 등록 하시겠습니까?",
                  sub: "등록 이후 수정이 불가합니다.",
                  isOk: () => func.setData(),
                  isCancel: null,
                })
              }
            >
              게시하기
            </button>
          </div>
        </div>
      </AddHeader>
      <AdminNews>
        <div>
          <ul>
            <li>뉴스 제목</li>
            <li>
              <input
                type="text"
                placeholder="뉴스 제목 입력 (최대 40자)"
                onChange={({ target: { value } }) =>
                  func.setInputStateValue("title", value)
                }
                maxLength={40}
              />
            </li>
          </ul>
          <ul>
            <li>분야1</li>
            <li>
              <div className="select">
                <Select
                  placeholder="분야1 선택"
                  components={{ DropdownIndicator }}
                  styles={selectStyles}
                  options={data.filterList.first_category}
                  width={210}
                  height={34}
                  onChange={(value) =>
                    func.setInputStateValue("first_category", value.value)
                  }
                />
              </div>
            </li>
          </ul>
          <ul>
            <li>분야2</li>
            <li>
              <div className="select">
                <Select
                  placeholder="분야2 선택"
                  components={{ DropdownIndicator }}
                  styles={selectStyles}
                  options={data.filterList.second_category}
                  width={210}
                  height={34}
                  onChange={(value) =>
                    func.setInputStateValue("second_category", value.value)
                  }
                />
              </div>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li>학년 선택</li>
            <li>
              <div className="grade">
                <span
                  className={state.grade_type === "1" ? "on" : ""}
                  onClick={() => func.setStateValue("grade_type", "1")}
                >
                  <p>1학년</p>
                </span>
                <span
                  className={state.grade_type === "2" ? "on" : ""}
                  onClick={() => func.setStateValue("grade_type", "2")}
                >
                  <p>2학년</p>
                </span>
                <span
                  className={state.grade_type === "3" ? "on" : ""}
                  onClick={() => func.setStateValue("grade_type", "3")}
                >
                  <p>3 · 4학년</p>
                </span>
                <span
                  className={state.grade_type === "5" ? "on" : ""}
                  onClick={() => func.setStateValue("grade_type", "5")}
                >
                  <p>5 · 6학년</p>
                </span>
              </div>
            </li>
          </ul>
          {/* 본문 삭제 본문 추가 */}
          {data.inputData[`content_0${state.grade_type}`].length === 0 ? (
            <ul>
              <li>본문 </li>
              <li>
                <button
                  onClick={() => func.setModalData(true, "content_add", 0)}
                >
                  <img src={Plus} alt="플러스 아이콘" />
                  <p>본문 추가</p>
                </button>
              </li>
            </ul>
          ) : null}
          {/* 레이아웃 */}
          {data.inputData[`content_0${state.grade_type}`].map((item, idx) => {
            if (item.image_location === "right") {
              return (
                <div className="right_layout">
                  <textarea
                    cols="30"
                    rows="3"
                    placeholder="본문 입력"
                    onChange={({ target: { value } }) =>
                      func.setContentsDetail("content", idx, value)
                    }
                  ></textarea>
                  {item.image_id ? (
                    <div
                      className="imgbox_upload"
                      onClick={() =>
                        func.setModalData(true, "picture_select", idx)
                      }
                    >
                      <img src={item.image_url} alt="뉴스빌더 업로드 이미지" />
                      <span className="picture_reselect_box">
                        <div>
                          <img
                            src={NewsBuilderChange}
                            alt="사진 선택"
                            className="newsbuilder_picture_icon"
                          />
                          <p>사진재선택</p>
                        </div>
                      </span>
                    </div>
                  ) : (
                    <div
                      id="right_layout_add"
                      onClick={() =>
                        func.setModalData(true, "picture_select", idx)
                      }
                      className="right_layout_add"
                    >
                      <div className="picture_select_box">
                        <img
                          src={Picture_select}
                          alt="사진 선택"
                          className="picture_icon"
                        />
                        <p>사진 선택</p>
                      </div>
                    </div>
                  )}

                  <div className="btns">
                    <button onClick={() => func.setContents("delete", idx)}>
                      <img src={Trash} alt="휴지통 아이콘" />
                      <p>본문 삭제</p>
                    </button>
                    {data.inputData[`content_0${state.grade_type}`].length ===
                    idx + 1 ? (
                      <button
                        onClick={() =>
                          func.setModalData(true, "content_add", idx + 1)
                        }
                      >
                        <img src={Plus} alt="플러스 아이콘" />
                        <p>본문 추가</p>
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            } else if (item.image_location === "left") {
              return (
                <div className="left_layout">
                  <div className="left_layout_add">
                    {item.image_id ? (
                      <div
                        className="imgbox_upload"
                        onClick={() =>
                          func.setModalData(true, "picture_select", idx)
                        }
                      >
                        <img
                          src={item.image_url}
                          alt="뉴스빌더 업로드 이미지"
                        />
                        <span className="picture_reselect_box">
                          <div>
                            <img
                              src={NewsBuilderChange}
                              alt="사진 선택"
                              className="newsbuilder_picture_icon"
                            />
                            <p>사진재선택</p>
                          </div>
                        </span>
                      </div>
                    ) : (
                      <div
                        id="left_layout_add"
                        onClick={() =>
                          func.setModalData(true, "picture_select", idx)
                        }
                      >
                        <div className="picture_select_box">
                          <img
                            src={Picture_select}
                            alt="사진 선택"
                            className="picture_icon"
                          />
                          <p>사진 선택</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <textarea
                    cols="30"
                    rows="3"
                    placeholder="본문 입력"
                    onChange={({ target: { value } }) =>
                      func.setContentsDetail("content", idx, value)
                    }
                  ></textarea>
                  <div className="btns">
                    <button onClick={() => func.setContents("delete", idx)}>
                      <img src={Trash} alt="휴지통 아이콘" />
                      <p>본문 삭제</p>
                    </button>
                    {data.inputData[`content_0${state.grade_type}`].length ===
                    idx + 1 ? (
                      <button
                        onClick={() =>
                          func.setModalData(true, "content_add", idx + 1)
                        }
                      >
                        <img src={Plus} alt="플러스 아이콘" />
                        <p>본문 추가</p>
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            } else {
              return (
                <div className="full_layout">
                  <div className="full_layout_add">
                    {item.image_id ? (
                      <div
                        className="imgbox_upload"
                        onClick={() =>
                          func.setModalData(true, "picture_select", idx)
                        }
                      >
                        <img
                          src={item.image_url}
                          alt="뉴스빌더 업로드 이미지"
                        />
                        <span className="picture_reselect_box">
                          <div>
                            <img
                              src={NewsBuilderChange}
                              alt="사진 선택"
                              className="newsbuilder_picture_icon"
                            />
                            <p>사진재선택</p>
                          </div>
                        </span>
                      </div>
                    ) : (
                      <div
                        id="left_layout_add"
                        onClick={() =>
                          func.setModalData(true, "picture_select", idx)
                        }
                      >
                        <div className="picture_select_box">
                          <img
                            src={Picture_select}
                            alt="사진 선택"
                            className="picture_icon"
                          />
                          <p>사진 선택</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <textarea
                    cols="30"
                    rows="10"
                    placeholder="본문 입력"
                    onChange={({ target: { value } }) =>
                      func.setContentsDetail("content", idx, value)
                    }
                  ></textarea>
                  <div className="btns">
                    <button onClick={() => func.setContents("delete", idx)}>
                      <img src={Trash} alt="휴지통 아이콘" />
                      <p>본문 삭제</p>
                    </button>
                    {data.inputData[`content_0${state.grade_type}`].length ===
                    idx + 1 ? (
                      <button
                        onClick={() =>
                          func.setModalData(true, "content_add", idx + 1)
                        }
                      >
                        <img src={Plus} alt="플러스 아이콘" />
                        <p>본문 추가</p>
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            }
          })}
        </div>
        {/* 연관뉴스 */}
        <div>
          <ul>
            <li>연관 뉴스</li>
            <li>
              <button onClick={() => func.setModalData(true, "add_news")}>
                추가하기
              </button>
            </li>
          </ul>
          <ul>
            {data.inputData.related_news.map((item) => (
              <li>
                <img
                  src={
                    item.images ? (
                      <img src={item.images.image_url} alt="이미지" />
                    ) : null
                  }
                  alt={`${item.title} 뉴스 이미지`}
                />
                <div>
                  <h5>{item.title}</h5>
                  <p>
                    {item.first_categories
                      ? `${item.first_categories.category_name} · `
                      : ""}
                    {item.second_categories
                      ? `${item.second_categories.category_name}`
                      : ""}
                  </p>
                </div>

                <img
                  onClick={() => func.setRelatedNews("delete", item)}
                  src={Close}
                  alt="닫기이미지"
                  className="close_icon"
                />
              </li>
            ))}
          </ul>
        </div>
        <div>
          <ul>
            <li>리드 댓글</li>
            <li>
              <textarea
                cols="30"
                rows="3"
                placeholder="리드 댓글 입력"
                onChange={({ target: { value } }) =>
                  func.setInputStateValue("lead_comment", value)
                }
              ></textarea>
            </li>
          </ul>
        </div>
      </AdminNews>
      {state.alert ? (
        <Alert
          title={state.alert.title}
          sub={state.alert.sub}
          isOk={state.alert.isOk}
          isCancel={state.alert.isCancel}
        />
      ) : null}
      {/* 본문 레이아웃 선택 모달*/}
      {state.modal.open && state.modal.type === "content_add" ? (
        <Modal
          width={414}
          maxHeight={181}
          borderRadius={12}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>
              <div className="modal_style">
                <h3>본문 레이아웃 선택</h3>
                <ul>
                  <li onClick={() => func.setContents("add", "full")}>
                    <div></div>
                    <span></span>
                    <span></span>
                    <span></span>
                  </li>
                  <li onClick={() => func.setContents("add", "left")}>
                    <div></div>
                    <div>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </li>
                  <li onClick={() => func.setContents("add", "right")}>
                    <div>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    <div></div>
                  </li>
                </ul>
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}

      {/* 연관 뉴스 추가모달*/}
      {state.modal.open && state.modal.type === "add_news" ? (
        <Modal
          width={655}
          maxHeight={670}
          minHeight={500}
          borderRadius={12}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>
              <div className="add_news">
                <h3>연관 뉴스 추가</h3>
                <div className="inputbox">
                  <input
                    type="text"
                    placeholder="뉴스 제목 검색"
                    onChange={({ target: { value } }) =>
                      func.setStateValue("related_search_keyword", value)
                    }
                    onKeyPress={(e) => {
                      if (e.key !== "Enter") return;
                      if (e.shiftKey) return;
                      e.preventDefault();
                      func.searchList();
                    }}
                  />
                  <i className="xi-search"></i>
                </div>
                <div className="selectbox">
                  <Select
                    placeholder="분야1"
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={200}
                    height={34}
                    options={data.filterList.first_category}
                    onChange={(value) =>
                      func.setStateValue("related_first_category", value.value)
                    }
                  />

                  <Select
                    placeholder="분야2"
                    components={{ DropdownIndicator }}
                    styles={selectStyles}
                    width={200}
                    height={34}
                    options={data.filterList.second_category}
                    onChange={(value) =>
                      func.setStateValue("related_second_category", value.value)
                    }
                  />
                </div>
                {data.filterList.news_list.length > 0 ? (
                  <ul>
                    {data.filterList.news_list.map((item) => (
                      <li key={`news_${item.id}`}>
    {/* 
                        <pre>
                          {JSON.stringify(item, null, 2)}
                        </pre> */}
                      
                        <img
                          src={item.images ? item.images.image_url : null}
                          alt={`${item.title}`}
                        />
                        <div>
                          <h5>{item.title}</h5>
                          <p>
                            {item.first_categories
                              ? `${item.first_categories.category_name} · `
                              : ""}
                            {item.second_categories
                              ? `${item.second_categories.category_name}`
                              : ""}
                             /
                             {
                              item.contents[0].grade == 1
                                ? '1학년'
                                : item.contents[0].grade == 2
                                ? '2학년'
                                : item.contents[0].grade == 3
                                ? '3,4학년'
                                : item.contents[0].grade == 4
                                ? '5,6학년'
                                : '알 수 없음'  // grade 값이 예상 범위를 벗어났을 때
                            }

                          </p>
                        </div>
                        <BasicButton
                          className="white"
                          onClick={() => func.setRelatedNews("add", item)}
                        >
                          추가
                        </BasicButton>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="search_none">
                    검색 조건에 맞는 뉴스가 없습니다.
                  </div>
                )}
              </div>
            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}

      {/* 사진선택 모달*/}
      {state.modal.open && state.modal.type === "picture_select" ? (
        <Modal
          width={1100}
          maxHeight={800}
          borderRadius={12}
          setmodal={() => func.setModalData(!state.modal.open, null, null)}
          data={
            <>

              <div className="picture_select">
                <h5>사진 선택</h5>
                <div>
                  <div className="search_box">
                    <i className="xi-search"></i>
                    <input
                      type="text"
                      placeholder="찾고 있는 사진이 있나요?"
                      onChange={({ target: { value } }) =>
                        func.setStateValue("search_keyword", value)
                      }
                      onKeyPress={(e) => {
                        if (e.key !== "Enter") return;
                        if (e.shiftKey) return;
                        e.preventDefault();
                        func.searchList();
                      }}
                    />
                  </div>
                </div>

                {data.filterList.image_list.length > 0 ? (
                  <div className="picture_list">
                    <h6>모든 사진</h6>
                    <ul
                        style={{height:'250px'}}
                    >
                      {data.filterList.image_list.map((item) => (
                        <li
                        style={{height:'120px'}}
                          key={`image_${item.id}`}
                          onClick={() =>
                            func.setContentsDetail(
                              "image_add",
                              state.modal.id,
                              item.id,
                              item.image_url
                            )
                          }
                        >
                          <img src={item.image_url} alt="사진 업로드 이미지" />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="search_none">
                    <div>
                      <i className="xi-search"></i>
                      <h4>검색 결과가 없어요</h4>
                      <p>
                        검색어를 다시 한번 확인하거나 다른 단어로 검색해보세요!
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <Pager
                page={data.currentPage}
                count={10}
                total={data.filterList.image_total}
                paging={func.paging}
              />

              <br></br>
              <div class="picture_select">
                <div class="search_box">
                  <input
                    style={{ border: '1px solid grey',padding:'10px' }}
                      type="text"
                    placeholder="찾고 있는 사진이 있나요?(pixbay)"

                    onChange={({ target: { value } }) =>
                      func.setStateValue("pixabay_search_keyword", value)
                    }

                    onKeyPress={(e) => {
                      if (e.key !== "Enter") return;
                      if (e.shiftKey) return;
                      e.preventDefault();

                      func.searchPixabay();

                    
                    }}
                  />
                </div>
                

                {data.filterList.pixabay_image_list.length > 0 ? (
                  <div className="picture_list">
                    <h6>모든 사진</h6>
                    <ul style={{height:'240px'}}>
                      {data.filterList.pixabay_image_list.map((item) => (
                        <li
                          style={{height:'120px'}}
                          key={`image_${item.id}`}
                          onClick={() =>
                            func.setContentsDetailPixabay(
                              "image_add",
                              state.modal.id,
                              item.id,
                              item.imageURL
                            )
                          }
                        >
                          <img src={item.previewURL} alt="사진 업로드 이미지" />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="search_none">
                    <div>
                      <i className="xi-search"></i>
                      <h4>검색 결과가 없어요</h4>
                      <p>
                        검색어를 다시 한번 확인하거나 다른 단어로 검색해보세요!
                      </p>
                    </div>
                  </div>
                )}


              </div>

            </>
          }
          deleteActive={null}
        ></Modal>
      ) : null}
    </>
  );
};

export default ContentAdd;

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select, { components } from "react-select";

import { Nav } from "./SectionNavStyle";
import {
  BasicButton,
  SearchBoxContainer,
  CalendarBox,
  SelectBox,
} from "Styles/styles";
// select style
import { selectStyles, DropdownIndicator } from "Styles/select.style";

import moment from "moment";
const SectionNav = ({
  list, // nav 의 종류
  status, // 현재 nav의 위치
  isAdd, // 데이터 등록 여부 : state/txt(버튼 텍스트)/location(등록 눌러서 넘어가는 위치)
  isSearch, // 검색 여부 : state/ active /onkeyPress /placeholder
  isCalendar, // 달력 필터 여부 :
  isSelectList, // 선택 필터 여부
  isCheckList, // 체크 필터 여부
}) => {
  const navigate = useNavigate();
  return (
    <Nav>
      {list !== null
        ? list.map((item, index) => (
            <li
              key={`section_nav_${index}`}
              className={status === item.type ? "on" : ""}
              onClick={() => {
                navigate(item.path);
              }}
            >
              {item.title}
            </li>
          ))
        : null}
      <div className="nav_list">
        {isAdd && isAdd.state ? (
          <BasicButton
            onClick={
              isAdd.location !== null
                ? () => {
                    navigate(`/${isAdd.location}`);
                  }
                : () => isAdd.active()
            }
            className="add"
          >
            {isAdd.txt}
          </BasicButton>
        ) : null}

        {/* 날짜 필터 */}
        <div>
          {isCalendar && isCalendar.state ? (
            <CalendarBox>
              <input
                id="is_calender"
                type="date"
                data-placeholder={isCalendar.placeholder}
                required
                aria-required="true"
                onChange={({ target: { value } }) =>
                  isCalendar.active(moment(value).format("YYYY-MM-DD"))
                }
                ref={isCalendar.ref}
              />
            </CalendarBox>
          ) : null}
        </div>

        {/* 선택 필터 */}
        <div>
          {isSelectList && isSelectList.length > 0
            ? isSelectList.map((select, idx) => {
                if (select !== null) {
                  if (select.value) {
                    return (
                      <Select
                        key={`select_filter_${idx}`}
                        placeholder={select.placeholder}
                        options={select.options}
                        onChange={(value) => select.active(value)}
                        components={{ DropdownIndicator }}
                        styles={selectStyles}
                        width={166}
                        defaultValue={select.options[0]}
                        value={select.value}
                      />
                    );
                  } else {
                    return (
                      <Select
                        key={`select_filter_${idx}`}
                        placeholder={select.placeholder}
                        options={select.options}
                        onChange={(value) => select.active(value)}
                        components={{ DropdownIndicator }}
                        styles={selectStyles}
                        width={166}
                        defaultValue={select.options[0]}
                      />
                    );
                  }
                }
              })
            : null}
        </div>

        {/* 검색 */}
        <div className="mt10">
          {isSearch && isSearch.state ? (
            <SearchBoxContainer>
              <input
                type="text"
                placeholder={isSearch.placeholder}
                onChange={({ target: { value } }) => isSearch.active(value)}
                onKeyPress={(e) => {
                  if (e.key !== "Enter") return;
                  if (e.shiftKey) return;
                  e.preventDefault();
                  isSearch.onkeyPress();
                }}
                value={isSearch.value}
              />
              <i className="xi-search" onClick={() => isSearch.onkeyPress()} />
            </SearchBoxContainer>
          ) : null}
        </div>

        {/* 체크 선택 필터 */}
        <div className="checkbox_filter">
          {isCheckList && isCheckList.length > 0
            ? isCheckList.map((check, idx) => {
                if (check !== null) {
                  return (
                    <div onClick={() => check.active()}>{check.label}</div>
                  );
                }
              })
            : null}
        </div>
      </div>
      {/* 등록 */}
    </Nav>
  );
};

export default SectionNav;
